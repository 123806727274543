import store from "@/store/store";

export default {
    "HomePage-TopName": "E-MART MALL",
  "HomePage-Swiper-Top": "日常任務",
  "HomePage-Swiper-Hint": "緊急任務佣金較高",
  "HonePage_Btn": "開始搶單",
  "HomePage_Btn_Swiper1": "恭喜！用戶",
  "HomePage_Btn_Swiper2": "已升級到",
  "HomePage_Centre_Text1": "合作品牌",
  "HomePage_Centre_Text2": "品牌友好合作夥伴",
  "HomePage_Btm_text1": "服務中心",
  "HomePage_Btm_text2": "如果您有任何其他問題，請前往客服中心",
  "HomePage_Btm_text3": "邀請好友",
  "HomePage_Btm_text4": "常見問題",
  "HomePage_Btm_text5": "條款與協議",
  "HomePage_Btm_text6": "關於 E-mart mall",
  "HomePage_Hao_Btn1": "首頁",
  "HomePage_Hao_Btn2": "訂單",
  "HomePage_Hao_Btn3": "任務",
  "HomePage_Hao_Btn4": "服務",
  "HomePage_Hao_Btn5": "我的",

  "HomePage_Pop_Top1": "歡迎來到 E-MART MALL",
  "HomePage_Pop_Top2": "邀請好友賺取佣金",
  "HomePage_Pop_Top3": "促銷代碼",
  "HomePage_Pop_Btn": "複製邀請鏈接",
  "HomePage_Pop_Msg": "複製成功",
  "HomePage_Pop_Name": "選擇語言",

  "Tasks_Top_Name": "任務",
  "Tasks_Card_Order": "日常訂單",
  "Tasks_Card_Ration": "佣金比例",
  "Unit": "Rp",
  "Tasks_Gain_Money": "賺取的佣金",
  "Tasks_Freeze_Moneny": "凍結金額",
  "Tasks_Order": "已完成訂單",
  "Tasks_Money": "可用餘額",
  "Tasks_Top_Text1": "訂單描述",
  "Tasks_Text1":
    "每個帳戶每天最多可匹配" +
    store.getters.USER_DATA.member_level_max_order_quantity +
    "個訂單",
  "Tasks_Text2":
    "下單佣金統一為訂單總金額的" +
    store.getters.USER_DATA.number_direct_referrals +
    "%",
  "Tasks_Text3": "系統基於 LBS 技術，通過雲計算自動匹配商品",
  "Tasks_Text4":
    "為防止平台監管，若訂單在 30 分鐘內未確認提交，系統將凍結訂單金額",
  "Tasks_Text5":
    "您不能取消任務。您必須先完成任何未完成的任務，然後才能接受新的任務。",
  "Tasks_Top_Text2": "注意",
  "Tasks_Text6":
    "當平台為用戶匹配訂單時，會將訂單信息提交到商家後台。如果用戶在 30 分鐘內未提交訂單，系統將凍結訂單金額。為避免監管系統，將對用戶帳戶進行評估，這將直接影響用戶的下一次匹配時間和聲譽！請及時點擊停止接單，避免超時凍結。",
  "Tasks_Pop_TopName": "訂單任務",
  "Tasks_Pop_title1": "單價",
  "Tasks_Pop_title2": "訂單數量",
  "Tasks_Pop_title3": "總計",
  "Tasks_Pop_title4": "訂單佣金",
  "Tasks_Pop_title5": "訂單評價",
  "Tasks_Pop_title6": "訂單評估",
  "Tasks_Pop_Op1": "非常好的產品",
  "Tasks_Pop_Op2": "收到最終產品",
  "Tasks_Pop_Op3": "非常好的產品",
  "Tasks_Pop_Op4": "我非常滿意這款產品，並推薦購買",
  "Tasks_Pop_Op5": "我非常開心和滿意。價格合理",
  "Tasks_Pop_Op6": "生產非常好，我非常喜歡",
  "Tasks_Pop_Op7": "看起來很好，我的朋友已經買了第二次",
  "Tasks_Pop_Colse_Text1": "您確定要退出任務接受嗎？",
  "Tasks_Pop_Colse_Text2": "取消的任務將在任務列表中自動凍結",
  "Tasks_Pop_Colse_Btn1": "繼續",
  "Tasks_Pop_Colse_Btn2": "確認退出",
  "Tasks_Pop_Msg": "操作成功",
  "Tasks_Pop_Btn": "確認",

   "TasksIndex_Top_Name": "會員中心",
  "TasksIndex_MB": "會員福利",
  "TasksIndex_Supassed": "當前等級",
  "TasksIndex_Supassed_not": "待升級",
  "TasksIndex_Supassed_yi": "已升級",
  "TasksIndex_CR": "佣金比例",
  "TasksIndex_OQ": "訂單數量",
  "TasksIndex_Deposit": "充值金額",
  "TasksIndex_Btn": "充值金額",
  "TasksMsg_Pop": "搶單成功",
  "TasksMsg_Sub": "提交成功",

  "Service_Top_Name": "服務",
  "Service_Top_Text1": "您好，",
  "Service_Top_Text2":
    "歡迎使用！如果在使用過程中遇到存款和取款問題，請聯繫我們的客服團隊。",
  "Service_Top_Btn": "聯繫客服",
  "Service_Top_Date": "營業時間：09:00AM-16:00PM 17:30PM-23:00PM",
  "Service_Submit": "確認",

  "FAQ_Top_Name": "常見問題",
  "FAQ_Center_Text1": "常見問題",
  "FAQ_Center_Text2": "通過常見問題了解更多資訊",
  "FAQ_Bm_Text1": "聯繫我們",
  "FAQ_Bm_Text2":
    "歡迎使用！如果在使用過程中遇到存款和取款問題，請聯繫我們的客服團隊。",
  "FAQ_Bm_Text3": "聯繫客服",
  "FAQ_Bm_Text4": "營業時間: 0:00AM-24:00PM",
  "FAQ_Bm_Text5": "平台提現時間: 12:00AM-23:00PM",
  "FAQ_Bm_Text6": "搶單時間: 0:00AM-24:00PM",

  "Terms_Top_Name": "條款與協議",
  "Terms_Center_Text1": "免責聲明",
  "Terms_Center_Text2": `
        <h1 style="padding-bottom:10px;">推薦會員賺取的佣金和獎勳將在客服人員審核通過24小時內發放到推薦會員帳戶。</h1>
        <h1 style="padding-bottom:10px;">此處包含的條款和條件可能隨時更改或修改。</h1>
        <h1 style="padding-bottom:10px;">平台規則由E-mart mall運營，最終解釋權歸E-mart mall。</h1>
  `,

  "About_Top_Name": "關於",
  "About_Center_Text1": "公司簡介",
  "About_Center_P1": "成立於2018年07月12日",
  "About_Center_P2": "公司註冊資本：1251萬AED",
  "About_Center_P3": "企業資質",
  "About_Center_P4": "中東區最大的電子商務服務公司之一",
  "About_Center_P5": "1. Souq.com",
  "About_Center_P6": "2. Ounass.ae ",
  "About_Center_P7": "3. 亞馬遜",
  "About_Center_P8": "4. Groupon.ae ",
  "About_Center_P9": "5. Basharacare.com ",
  "About_Center_P10": "6. LetsTango.com ",
  "About_Center_P11": "7. Sharafdg.com",

  "About_Center_P12":
    "2020年度、2021年度、2023年度中東最佳合作企業受疫情影響和大數據時代的變遷，在網上購物已成為當下工薪族階層必不可少的技能，APP平台也如雨後春筍般發酵而生，大眾化較知名的如(Souq.com)  (Ounass.ae)    (亞馬遜)   (Groupon.ae)   (Basharacare.com)  等等平台。太多網店也頗為無奈跟隨時代的趨勢入住APP平台，眾多商家入住，競爭也日漸趨於白熱化。那么在網購平愛這個大家庭裡面想要獲取利益與口碑，電商的銷售和產品的質量是不可或缺的要點，產品的質量大家都有除個別黑心商家以外，但有句話說得好，現代的商場猶如古時的戰場，用氣手段和謀略的人比比皆是，那怎麼辦呢？這麼大的市場，成千上萬的商家都想分一杯羹，於是乎就衍生出了本平台，這裡就要說一下網購電商平台的一些不為人知的東西了，也是趨於時代的變遷，眾多賣家與買家都混居於網購平台買賣商品，也是相同的銷售模式，但不變的是買家買這個商品的時候肯定會看這個商品的知名度和銷量的口碑等等一系列東西再確定買不買，那么網購平台的商家就想到一個辦法，那就是給予一定的報酬線下找人幫助商品虛擬發貨從而提高商品的知名度和銷量，剩下的就是周而復始的虛擬發貨提高銷量等等，銷量口碑提高後，那就不用說，大家自己想想也知道。",
  
  "About_Center_P13":
    "本平台也是發現了這種模式，於是就衍生出了這個拼單平台，拼單平台的運營模式是通過雲計算法，把網購平台的商品購買鏈接通過大數據庫傳遞到拼單平台的雲計算庫，再通過拼單形式把商品店購資訊通過大數據庫傳遞到 E-mart mall，商家收到虛擬下單後虛擬發貨送單，從而提高掛在電商平台商品的口碑星際點銷量。模式就是這樣，完善得很完美。",
  
  "About_Center_P14": "平台簡介",
  "About_Center_P15": "尊敬的用戶",
  "About_Center_P16":
    "本平台是一個第三方智能雲控主機匹配訂單平台，平台與1. Souq.com 2. Ounass.ae 3. 亞馬遜 4. Groupon.ae  5. Basharacare.com  6. LetsTango.com 7. Sharafdg.com等網購平台商家合作。" +
    "通過智能雲系統將商家訂單匹配給平台用戶，以此提高商家在各大平台" +
    "的成交量和信譽度。改進了傳統網購提交訂單繁瑣的模式，由遠程智能" +
    "雲控制遠程電腦機器人自動模擬人工提交訂單操作流程，讓網購訂單匹配" +
    "變得更加高效簡單！平台每天都會將需要提高曝光度的商家產品放到" +
    "平台上，提供給平台用戶進行拼單。為了規避網購平台的檢測，提高訂" +
    "單的真實性，新用戶在拼單前必須先完善個人真實資訊，並填寫真實的" +
    "收貨地址。為了有效地幫助商家提升訂單成功率，規避商家被檢查到虛" +
    "假訂單的風險，平台將會根據您當前操作的IP，設備型號對當天的訂單" +
    "進行優化匹配。所有訂單匹配均需要通過智能雲算法實現。" +
    "平台充值拼單時間為上午10:00至晚上21:00。（如有問題請點擊客服進" +
    "行諮詢！）",

  "Order_TopName": "訂單",
  "Order_Tab_Text1": "所有訂單",
  "Order_Tab_Text2": "待處理",
  "Order_Tab_Text3": "凍結",
  "Order_Tab_Text4": "已完成",
  "Order_Tab_NullMsg": "加載完成",
  "Order_Tabs_Time": "完成時間",
  "Order_Tabs_NotTime": "尚未完成",
  "Order_Tabs_Re": "待處理",
  "Order_Tabs_Com": "已完成",
  "Order_Tabs_Text1": "單價",
  "Order_Tabs_Text2": "訂單數量",
  "Order_Tabs_Text3": "總計",
  "Order_Tabs_Text4": "訂單佣金",
  "Order_Tabs_Btn": "提交訂單",
  "Order_Tabs_Sun": "提交成功",

  "My_Top_Name": "我的",
  "My_Deposit": "充值金額",
  "My_Code": "邀請碼",
  "My_Score": "信用評分",
  "My_Text1": "帳戶餘額",
  "My_Text2": "凍結餘額",
  "My_Tabs_Text1": "我的帳戶",
  "My_Tabs_Text2": "賬單明細",
  "My_Tabs_Text3": "會員中心",
  "My_Tabs_Text4": "團隊報告",
  "My_Tabs_Text5": "邀請好友",
  "My_Tabs_Text6": "選擇語言",

  "MyAccount_Top_Name": "我的帳戶",
  "MyAccount_Text1": "帳戶餘額",
  "MyAccount_Tx": "提現",
  "MyAccount_Task": "任務收入",
  "MyAccount_Team": "團隊收入",
  "MyAccount_Task_Money": "通過完成任務獲得收入",
  "MyAccount_Com": "邀請好友賺取佣金",
  "MyAccount_Btn1": "去完成",
  "MyAccount_Btn2": "去邀請",
  "MyAccount_FAQ": "常見問題",

  "SetTkPW_Top_Name": "設置提現密碼",
  "SetTkPW_Name_Text": "設置提現密碼",
  "SetTkPW_NewPassWord": "新提現密碼*",
  "SetTkPW_OdlPassWord": "確認新提現密碼*",
  "SetTkPW_Btn": "確認",
  "SetTkPW_New_P": "請輸入提現密碼",
  "SetTkPW_New_O": "請輸入確認提現密碼",

  "Tx_Top_Name": "提現",
  "Tx_TxMoney": "提現金額",
  "Tx_BalMoney": "帳戶餘額",
  "Tx_Input_P": "請輸入提現金額",
  "Tx_Money_Text": "到賬金額",
  "Tx_Bank_Card": "提現銀行卡",
  "Tx_Bank_New": "新增",
  "Tx_Bank_Btn": "更換",
  "Tx_Bank_PassWord": "提現密碼",
  "Tx_Money_Btn": "確認提現",

  "AddBank_Top_Name": "綁定銀行卡",
  "AddBank_Pop_Cancel": "取消",
  "AddBank_Pop_Confirm": "確認",
  "AddBank_tab_Name1": "銀行",
  "AddBank_tab_Name2": "持卡人姓名",
  "AddBank_tab_P2": "請輸入持卡人姓名",
  "AddBank_tab_Name3": "銀行卡號",
  "AddBank_tab_P3": "請輸入銀行卡號",
  "AddBank_tab_Name4": "提現密碼",
  "AddBank_tab_P4": "請輸入提現密碼",
  "AddBank_add_Btn": "確認添加",

  "Bill_Top_Name": "賬單明細",
  "Bill_Tab_Text1": "任務佣金",
  "Bill_Tab_Text2": "本金退還",
  "Bill_Tab_Text3": "預留訂單",
  "Bill_Tab_Text4": "任務佣金",
  "Bill_prev": "上一頁",
  "Bill_next": "下一頁",

  "Team_Top_Name": "團隊報告",
  "Team_Tab_Text1": "團隊收入",
  "Team_Tab_text2": "邀請人數",
  "Smg_Top_Name": "消息",
  "Smg_Null_Text": "加載完成",

  "Set_Top_Name": "設置",
  "Set_Tab_Text1": "登錄密碼",
  "Set_Tab_Text2": "提現密碼",
  "Set_Tab_Text3": "個人照片",
  "Set_Tab_Text4": "用戶昵稱",
  "Set_Btn": "退出登錄",

  "SetLogin_LoginPS_Name": "更改登錄密碼",
  "SetLogin_Tab_TextName": "更改登錄密碼",
  "SetLogin_Tab_Text1": "舊密碼*",
  "SetLogin_Tab_P1": "請輸入舊密碼",
  "SetLogin_Tab_Text2": "新密碼*",
  "SetLogin_Tab_P2": "請輸入新密碼",
  "SetLogin_Tab_Text3": "確認密碼*",
  "SetLogin_Tab_P3": "請輸入確認密碼",
  "SetLogin_Btn": "確認更改",

  "SetTxPS_Top_Name": "更改提現密碼",
  "SetTx_Tab_TextName": "更改提現密碼",
  "SetTx_Tab_Text1": "提現密碼*",
  "SetTx_Tab_P1": "請輸入您的提現密碼",
  "SetTx_Tab_Text2": "新提現密碼*",
  "SetTx_Tab_P2": "請輸入新提現密碼",
  "SetTx_Tab_Text3": "確認新提現密碼*",
  "SetTx_Tab_P3": "請輸入確認提現密碼",
  "SetTx_Btn": "確認",

  "SetName_Top": "請輸入用戶昵稱",
  "SetName_Btn": "確認更改",
  "SetOut_Text1": "提示",
  "setOut_Text2": "確認退出登錄",
  "setOut_btn1": "取消",
  "setOut_btn2": "確認",

  "LoginIndex_CutLanuage": "選擇語言",
  "LoginIndex_Tab_Top": "請登錄或創建您的賬戶",
  "LoginIndex_Btn1": "登錄",
  "LoginIndex_Btn2": "創建賬戶",
  "LoginIndex_Tab_Btt": "關於 E-mart mall",

  "Longin_Top_Name": "登錄",
  "Longin_Tab_Name": "請登錄到您的賬戶",
  "Longin_Tab_Text1": "手機號碼*",
  "Longin_Tab_P1": "請輸入手機號碼",
  "Longin_Tab_Text2": "輸入密碼*",
  "Longin_Tab_P2": "請輸入您的密碼",
  "Longin_Tab_Btm": "忘記密碼？",
  "Longin_Btn": "登錄",

  "SigninPage_Top_Name": "創建賬戶",
  "SigninPage_Tab_Name": "創建賬戶",
  "SigninPage_Tab_Text1": "手機號碼*",
  "SigninPage_Tab_P1": "請輸入手機號碼",
  "SigninPage_Tab_Text2": "登錄密碼*",
  "SigninPage_Tab_P2": "請輸入登錄密碼",
  "SigninPage_Tab_Text3": "確認密碼*",
  "SigninPage_Tab_P3": "請輸入確認密碼",
  "SigninPage_Tab_Text4": "邀請碼（可選）",
  "SigninPage_Tab_P4": "請輸入邀請碼",
  "SigninPage_Tab_Text5": "註冊協議",
  "SigninPage_Btn": "確認註冊",

  "MyPage_Msg_Copy": "複製成功",
  "TxPage_Msg_Msg1": "請輸入提現金額",
  "TxPage_Msg_Msg2": "請選擇銀行卡",
  "TxPage_Msg_Msg3": "請輸入提現密碼",
  "TxPage_Msg_Msg4": "提現金額不能大於帳戶餘額",

  "SetTx_Msg1": "密碼不能為空",
  "SetTx_Msg2": "提現密碼必須包含至少 6 個字符",
  "SetTx_Msg3": "新提現密碼必須包含至少 6 個字符",
  "SetTx_Msg4": "新提現密碼與確認提現不相同",
  "SetTx_Msg5": "修改成功",

  "SetTk_Msg1": "密碼不能為空",
  "SetTk_Msg2": "確認密碼必須與新密碼相同",
  "SetTk_Msg3": "密碼必須包含 6 個字符",
  "SetTk_Msg4": "密碼與登錄密碼不相同",
  "SetTk_Msg5": "設置提現密碼成功",

  "SetPage_Msg1": "請輸入用戶名",
  "SetPage_Msg2": "修改成功",
  "SetPage_Msg3": "退出成功",

  "SetLoginPassWord_Msg1": "密碼不能為空",
  "SetLoginPassWord_Msg2": "密碼輸入錯誤",
  "SetLoginPassWord_Msg3": "新密碼與確認密碼不同",
  "SetLoginPassWord_Msg4": "密碼必須包含至少 6 個字符",
  "SetLoginPassWord_Msg5": "修改成功",

  "MyAccount_Msg1": "複製成功",

  "AddBank_Msg1": "持卡人姓名不能為空",
  "AddBank_Msg2": "銀行卡號不能為空",
  "AddBank_Msg3": "提現密碼不能為空",
  "AddBank_Msg4": "提現密碼不正確",
  "AddBank_Msg5": "添加成功",

  "SigninPage_Msg1": "登錄賬戶不能為空",
  "SigninPage_Msg2": "登錄密碼不能為空",
  "SigninPage_Msg3": "密碼與確認密碼不同",
  "SigninPage_Msg4": "密碼必須大於 6 位",
  "SigninPage_Msg5": "請勾選註冊協議",
  "SigninPage_Msg6": "註冊成功",
  "SigninPage_Msg7": "手機號碼輸入有誤，請重新輸入",

  "LoginPage_Msg1": "登錄賬戶不能為空",
  "LoginPage_Msg2": "登錄密碼不能為空",
  "LoginPage_Msg3": "登錄成功",

  "Bill_date": "篩選",
  "TxPage_Msg_Msg5": "提現成功",

  "Bill_Tab_Msg1": "已完成",
  "Bill_Tab_Msg2": "審核中",
  "Bill_Tab_Msg3": "提現失敗",

  "SelTxWay_Top_Name": "提現方式",
  "SelTxWay_Sel1": "銀行卡",
  "SelTxWay_Sel2": "數字貨幣",

  "TxSGX_Top_Name": "提現",
  "TxSGX_Input1": "真實姓名",
  "TxSGX_Input2": "交易所地址",

  "TxSGX_Smg1": "請輸入姓名",
  "TxSGX_Smg2": "請輸入地址",
  "TxSGX_Smg3": "請輸入姓名",
  "TxSGX_Smg4": "請輸入地址",

  "CzPage_TopName": "充值",
  "CzPage_I1": "請輸入充值地址",
  "CzPage_Msg1": "充值前請詢問客服提供充值地址",
  "CzPage_I2": "請輸入充值金額",
  "CzPage_Btn": "提交充值",
  "Terms_center_Text3": "代理合作",
  "Terms_center_Text4": "平台代理模式",
  "Terms_center_Text5": "平台用戶可以通過推薦邀請新人成為代理人。代理人可以獲得額外的動態分潤獎勳，每一筆分潤將會轉帳到您的帳戶，可在帳戶明細中查看！一級用戶獎勳為一級用戶每次拼單金額佣金的33%，二級用戶獎勳為二級用戶每次拼單金額佣金的16%，三級用戶獎勳為三級用戶每次拼單金額佣金的8%。",
  "Terms_center_Text6": "溫馨提示：所有代理下線用戶和廠商的佣金獎勳均由本平台提供，不會影響所有代理及其下線的佣金！",
  "Terms_center_Text7": "規則說明",
  "Terms_center_Text8": "佣金分配平台會員所得佣金為訂單全額的0.32%-1.88%。",
  "Terms_center_Text9": "尊敬的用戶您好，",
  "Terms_center_Text10": "為了防止有人惡意進行洗黑錢或套現等不法行為，帳戶資金滿200會員需完成60單方可全額提現，福利單無法取消或更換。提現審核成功後，轉帳到您的帳戶時間為（10:00-21:00），（其餘時間提現次日24點前到賬），具體到賬時間以銀行為準！為了保證商家成交量的真實性，所有任務訂單都是垫付立返，搶到任務訂單後需及時提交訂單，避免延時未提交訂單導致訂單異常降低信用分。",
  "Terms_center_Text11": "平台使用一人銀行卡信息請勿重複註冊搶單，同一手機上最多可使用2個帳戶，超過2個帳戶一經發現將會凍結帳戶。系統隨機匹配高佣福利單，福利單無法取消或更換，個人多帳戶搶單會導致商家分店，影響商家的信用度以及銷售訂單作廢，本平台禁止一個銀行卡綁定多個帳戶，各位兼職會員請勿使用個人多帳戶，綁定多帳戶的全部資金將凍結90天，帳號永久封號處理。"
};
