import { createStore } from "vuex";
import UserStore from "./modules/UserStore";
import LangStore from "./modules/Language.js";
import OrderStore from "./modules/OrderStore.js";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  modules: {
    UserStore,
    LangStore,
    OrderStore,
  },
  state: {
    LoadingFlag: false,
  },
  getters: {
    GetLoadingFlag: (state) => state.LoadingFlag,
  },
  mutations: {
    SetLoadingFlag: (state, LoadingFlag) => {
      state.LoadingFlag = LoadingFlag;
    },
  },
  plugins: [createPersistedState()],
});

export default store;