<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("CzPage_TopName") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
                <router-link to="/ServerPage">
                    <img src="@/assets/Img/Server.png" style="height: 20px;width: 16px;background: red;">
                </router-link>
            </div>
        </div>


        <div class="mt-[65px] text-sm">
            <div class="px-5 mt-4">
                <div class="px-4 py-3 p-4 border-[1px] border-slate-200 bg-white rounded-md">
                    <input v-model="CzPath" :placeholder="$t('CzPage_I1')" style="width: 100%;outline:none"
                        @input="setMath">
                </div>
                <div class="mt-2 text-xs text-[#666]" style="display: flex;align-items: center;">
                    <el-icon style="margin-right: 3px;"><WarningFilled /></el-icon>{{ $t('CzPage_Msg1') }}
                </div>
            </div>

            <div class="px-5 mt-4">
                <div class="px-4 py-3 p-4 border-[1px] border-slate-200 bg-white rounded-md">
                    <input v-model="CzMoney" :placeholder="$t('CzPage_I2')" style="width: 100%;outline:none"
                        @input="setMath" type="number">
                </div>
            </div>

            <div class="text-white p-5">
                <div class="bg-theme p-3 text-center rounded-[50px]" @click="submit">{{ $t('CzPage_Btn') }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ArrowLeftBold, WarningFilled } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const CzPath = ref();
const CzMoney = ref();
const { t } = useI18n();
const store = useStore();

function getGo() {
    router.push('/MyPage')
}

async function submit(){
    if(!CzPath.value){
        ElMessage.error(t('CzPage_I1'))
    }else if(!CzMoney.value){
        ElMessage.error(t('CzPage_I2'))
    }else{
        
        await store.dispatch("CzMoney",{ data:{
            amount:CzMoney.value,
            address:CzPath.value
        } })
        ElMessage.success(t('TasksMsg_Sub'))
    }
}
</script>