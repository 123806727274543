import store from "@/store/store";

export default {
  "HomePage-TopName": "E-MART MALL",
  "HomePage-Swiper-Top": "日常任务",
  "HomePage-Swiper-Hint": "紧急任务佣金较高",
  HonePage_Btn: "开始抢单",
  HomePage_Btn_Swiper1: "恭喜！用户",
  HomePage_Btn_Swiper2: "已升级到",
  HomePage_Centre_Text1: "合作品牌",
  HomePage_Centre_Text2: "品牌友好合作伙伴",
  HomePage_Btm_text1: "服务中心",
  HomePage_Btm_text2: "如果您有任何其他问题，请前往客服中心",
  HomePage_Btm_text3: "邀请好友",
  HomePage_Btm_text4: "常见问题",
  HomePage_Btm_text5: "条款与协议",
  HomePage_Btm_text6: "关于 E-mart mall",
  HomePage_Hao_Btn1: "首页",
  HomePage_Hao_Btn2: "订单",
  HomePage_Hao_Btn3: "任务",
  HomePage_Hao_Btn4: "服务",
  HomePage_Hao_Btn5: "我的",

  HomePage_Pop_Top1: "欢迎来到 E-MART MALL",
  HomePage_Pop_Top2: "邀请好友赚取佣金",
  HomePage_Pop_Top3: "促销代码",
  HomePage_Pop_Btn: "复制邀请链接",
  HomePage_Pop_Msg: "复制成功",
  HomePage_Pop_Name: "选择语言",

  Tasks_Top_Name: "任务",
  Tasks_Card_Order: "日常订单",
  Tasks_Card_Ration: "佣金比例",
  Unit: "Rp",
  Tasks_Gain_Money: "赚取的佣金",
  Tasks_Freeze_Moneny: "冻结金额",
  Tasks_Order: "已完成订单",
  Tasks_Money: "可用余额",
  Tasks_Top_Text1: "订单描述",
  Tasks_Text1:
    "每个账户每天最多可匹配" +
    store.getters.USER_DATA.member_level_max_order_quantity +
    "个订单",
  Tasks_Text2:
    "下单佣金统一为订单总金额的" +
    store.getters.USER_DATA.number_direct_referrals +
    "%",
  Tasks_Text3: "系统基于 LBS 技术，通过云计算自动匹配商品",
  Tasks_Text4:
    "为防止平台监管，若订单在 30 分钟内未确认提交，系统将冻结订单金额",
  Tasks_Text5:
    "您不能取消任务。您必须先完成任何未完成的任务，然后才能接收新的任务。",
  Tasks_Top_Text2: "注意",
  Tasks_Text6:
    "当平台为用户匹配订单时，会将订单信息提交到商家后台。如果用户在 30 分钟内未提交订单，系统将冻结订单金额。为避免监管系统，将对用户账户进行评估，这将直接影响用户的下一次匹配时间和信誉！请及时点击停止接单，避免超时冻结。",
  Tasks_Pop_TopName: "订单任务",
  Tasks_Pop_title1: "单价",
  Tasks_Pop_title2: "订单数量",
  Tasks_Pop_title3: "总计",
  Tasks_Pop_title4: "订单佣金",
  Tasks_Pop_title5: "订单评价",
  Tasks_Pop_title6: "订单评估",
  Tasks_Pop_Op1: "非常好的产品",
  Tasks_Pop_Op2: "收到最终产品",
  Tasks_Pop_Op3: "非常好的产品",
  Tasks_Pop_Op4: "我非常满意这款产品，并推荐购买",
  Tasks_Pop_Op5: "我非常开心和满意。价格合理",
  Tasks_Pop_Op6: "生产非常好，我非常喜欢",
  Tasks_Pop_Op7: "看起来很好，我的朋友已经买了第二次",
  Tasks_Pop_Colse_Text1: "您确定要退出任务接受吗？",
  Tasks_Pop_Colse_Text2: "取消的任务将在任务列表中自动冻结",
  Tasks_Pop_Colse_Btn1: "继续",
  Tasks_Pop_Colse_Btn2: "确认退出",
  Tasks_Pop_Msg: "操作成功",
  Tasks_Pop_Btn: "确认",

  TasksIndex_Top_Name: "会员中心",
  TasksIndex_MB: "会员福利",
  TasksIndex_Supassed: "当前等级",
  TasksIndex_Supassed_not: "待升级",
  TasksIndex_Supassed_yi: "已升级",
  TasksIndex_CR: "佣金比例",
  TasksIndex_OQ: "订单数量",
  TasksIndex_Deposit: "充值金额",
  TasksIndex_Btn: "充值金额",
  TasksMsg_Pop: "抢单成功",
  TasksMsg_Sub: "提交成功",

  Service_Top_Name: "服务",
  Service_Top_Text1: "您好，",
  Service_Top_Text2:
    "欢迎使用！如果在使用过程中遇到存款和取款问题，请联系我们的客服团队。",
  Service_Top_Btn: "联系客服",
  Service_Top_Date: "营业时间：09:00AM-16:00PM 17:30PM-23:00PM",
  Service_Submit: "确认",

  FAQ_Top_Name: "常见问题",
  FAQ_Center_Text1: "常见问题",
  FAQ_Center_Text2: "通过常见问题了解更多信息",
  FAQ_Bm_Text1: "联系我们",
  FAQ_Bm_Text2:
    "欢迎使用！如果在使用过程中遇到存款和取款问题，请联系我们的客服团队。",
  FAQ_Bm_Text3: "联系客服",
  FAQ_Bm_Text4: "营业时间: 0:00AM-24:00PM",
  FAQ_Bm_Text5: "平台提现时间: 12:00AM-23:00PM",
  FAQ_Bm_Text6: "抢单时间: 0:00AM-24:00PM",

  Terms_Top_Name: "条款与协议",
  Terms_Center_Text1: "免责声明",
  Terms_Center_Text2: `
        <h1 style="padding-bottom:10px;">推荐会员赚取的佣金和奖励将在客服人员审核通过24小时内发放到推荐会员账户。</h1>
        <h1 style="padding-bottom:10px;">此处包含的条款和条件可能随时更改或修改。</h1>
        <h1 style="padding-bottom:10px;">平台规则由E-mart mall运营，最终解释权归E-mart mall。</h1>
  `,

  About_Top_Name: "关于",
  About_Center_Text1: "公司简介",
  About_Center_P1: "成立于2018年07月12日",
  About_Center_P2: "公司注册资本：1251万AED",
  About_Center_P3: "企业资质",
  About_Center_P4: "中东区最大的电子商务服务公司之一",
  About_Center_P5: "1. Souq.com",
  About_Center_P6: "2. Ounass.ae ",
  About_Center_P7: "3.  亚马逊",
  About_Center_P8: "4. Groupon.ae ",
  About_Center_P9: "5. Basharacare.com ",
  About_Center_P10: "6. LetsTango.com ",
  About_Center_P11: "7. Sharafdg.com",
  About_Center_P12:
    "2020年度、2021年度、2023年度中东最佳合作企业受疫情影响和大数据时代的变迁，在网上购物已成为当下工薪族阶层必不可少的技能，APP平台也如雨后春笋般发酵而生，大众化较知名的如(Souq.com)  (Ounass.ae)    (亚马逊)   (Groupon.ae)   (Basharacare.com)  等等平台。太多网店也颇为无奈跟随时代的趋势入住APP平台，众多商家入住，竞争也日渐趋于白热化。那么在网购平爱这个大家庭里面想要获取利益鱼口碑，电商的销售和产品的质量是不可或缺的要点，产品的质量大家都有除个别黑心商家以外，但有句话说得好，现代的商场犹如古时的战场，用气手段和谋略的人比比皆是，那怎么办呢？这么大的市场，成千上万的商家都想分一杯羹，于是乎就衍生出了本平台，这里就要说一下网购电商平台的一些不为人知的东西了，也是趋于时代的变迁，众多卖家与买家都混居与网购平台买卖商品，也是一样的销售模式，但不变的是买家买这个商品的时候肯定会看这个商品的知名度和销量的口碑等等一系列东西再确定买不买，那么网购平台的商家就想到一个办法，那就是给予一定的报酬线下找人帮助商品虚拟发货从而提高商品的知名度和销量，剩下就是周而复始的虚拟发货提高销售量等等，销量口碑提高后，那就不用说，大家自己想想也知道。",
  About_Center_P13:
    "本平台也是发现了这种模式，于是就衍生出了这个拼单平台，拼单平台的运营模式是通过云计算法，把网购平台的商品购买链接通过大数据库传递到拼单平台的云计算库，再通过拼单形势把商品店购信息通过大数据库传递到 E-mart mall，商家收到虚拟下单后虚拟发货送单，从而提高挂在电商平台商品的口碑星际点销量。模式就是这样，完善得很完美。",

  About_Center_P14: "平台简介",
  About_Center_P15: "尊救的用户",
  About_Center_P16:
    "本平台是一个第三方智能云控主机匹配订单平台，平台与1. Souq.com 2. Ounass.ae 3.  亚马逊 4. Groupon.ae  5. Basharacare.com  6. LetsTango.com 7. Sharafdg.com等网购平台商家合作。" +
    "通过智能云系统将商家订单匹配给平台用户，惜此提高商家在各大平台" +
    "的成交量和信訾度。改进了传统网购提交订单繁琐的模式，由远程智能" +
    "云控制远程电脑机器人自动模拟人工提交订单操作流程，让网购订单匹" +
    "配变得更加高效简单!平台每天都会将需要提高曝光度的商家产品放到" +
    "平台上，提供给平台用户进行拼单。为了规避网购平台的检测，提高订" +
    "单的真实性，新用户在拼单前必须先完善个人真实信息，并填写真实的" +
    "收货地址。为了有效地帮助商家提升订单成功率，规避商家被检查到虚" +
    "假订单的风险，平台将会根据您当前操作的ip，设备型号对当天的订单" +
    "进行优化匹配。所有订单匹配均需要通过智能云算法实现。" +
    "平台充值拼单时间为上午10:00至晚上21:00。(如有问题请点击客服进" +
    "行咨询!)",

  Order_TopName: "订单",
  Order_Tab_Text1: "所有订单",
  Order_Tab_Text2: "待处理",
  Order_Tab_Text3: "冻结",
  Order_Tab_Text4: "已完成",
  Order_Tab_NullMsg: "加载完成",
  Order_Tabs_Time: "完成时间",
  Order_Tabs_NotTime: "尚未完成",
  Order_Tabs_Re: "待处理",
  Order_Tabs_Com: "已完成",
  Order_Tabs_Text1: "单价",
  Order_Tabs_Text2: "订单数量",
  Order_Tabs_Text3: "总计",
  Order_Tabs_Text4: "订单佣金",
  Order_Tabs_Btn: "提交订单",
  Order_Tabs_Sun: "提交成功",

  My_Top_Name: "我的",
  My_Deposit: "充值金额",
  My_Code: "邀请码",
  My_Score: "信用评分",
  My_Text1: "账户余额",
  My_Text2: "冻结余额",
  My_Tabs_Text1: "我的账户",
  My_Tabs_Text2: "账单明细",
  My_Tabs_Text3: "会员中心",
  My_Tabs_Text4: "团队报告",
  My_Tabs_Text5: "邀请好友",
  My_Tabs_Text6: "选择语言",

  MyAccount_Top_Name: "我的账户",
  MyAccount_Text1: "账户余额",
  MyAccount_Tx: "提现",
  MyAccount_Task: "任务收入",
  MyAccount_Team: "团队收入",
  MyAccount_Task_Money: "通过完成任务获得收入",
  MyAccount_Com: "邀请好友赚取佣金",
  MyAccount_Btn1: "去完成",
  MyAccount_Btn2: "去邀请",
  MyAccount_FAQ: "常见问题",

  SetTkPW_Top_Name: "设置提现密码",
  SetTkPW_Name_Text: "设置提现密码",
  SetTkPW_NewPassWord: "新提现密码*",
  SetTkPW_OdlPassWord: "确认新提现密码*",
  SetTkPW_Btn: "确认",
  SetTkPW_New_P: "请输入提现密码",
  SetTkPW_New_O: "请输入确认提现密码",

  Tx_Top_Name: "提现",
  Tx_TxMoney: "提现金额",
  Tx_BalMoney: "账户余额",
  Tx_Input_P: "请输入提现金额",
  Tx_Money_Text: "到账金额",
  Tx_Bank_Card: "提现银行卡",
  Tx_Bank_New: "新增",
  Tx_Bank_Btn: "更换",
  Tx_Bank_PassWord: "提现密码",
  Tx_Money_Btn: "确认提现",

  AddBank_Top_Name: "绑定银行卡",
  AddBank_Pop_Cancel: "取消",
  AddBank_Pop_Confirm: "确认",
  AddBank_tab_Name1: "银行",
  AddBank_tab_Name2: "持卡人姓名",
  AddBank_tab_P2: "请输入持卡人姓名",
  AddBank_tab_Name3: "银行卡号",
  AddBank_tab_P3: "请输入银行卡号",
  AddBank_tab_Name4: "提现密码",
  AddBank_tab_P4: "请输入提现密码",
  AddBank_add_Btn: "确认添加",

  Bill_Top_Name: "账单明细",
  Bill_Tab_Text1: "任务佣金",
  Bill_Tab_Text2: "本金退还",
  Bill_Tab_Text3: "预留订单",
  Bill_Tab_Text4: "任务佣金",
  Bill_prev: "上一页",
  Bill_next: "下一页",

  Team_Top_Name: "团队报告",
  Team_Tab_Text1: "团队收入",
  Team_Tab_text2: "邀请人数",
  Smg_Top_Name: "消息",
  Smg_Null_Text: "加载完成",

  Set_Top_Name: "设置",
  Set_Tab_Text1: "登录密码",
  Set_Tab_Text2: "提现密码",
  Set_Tab_Text3: "个人照片",
  Set_Tab_Text4: "用户昵称",
  Set_Btn: "退出登录",

  SetLogin_LoginPS_Name: "更改登录密码",
  SetLogin_Tab_TextName: "更改登录密码",
  SetLogin_Tab_Text1: "旧密码*",
  SetLogin_Tab_P1: "请输入旧密码",
  SetLogin_Tab_Text2: "新密码*",
  SetLogin_Tab_P2: "请输入新密码",
  SetLogin_Tab_Text3: "确认密码*",
  SetLogin_Tab_P3: "请输入确认密码",
  SetLogin_Btn: "确认更改",

  SetTxPS_Top_Name: "更改提现密码",
  SetTx_Tab_TextName: "更改提现密码",
  SetTx_Tab_Text1: "提现密码*",
  SetTx_Tab_P1: "请输入您的提现密码",
  SetTx_Tab_Text2: "新提现密码*",
  SetTx_Tab_P2: "请输入新提现密码",
  SetTx_Tab_Text3: "确认新提现密码*",
  SetTx_Tab_P3: "请输入确认提现密码",
  SetTx_Btn: "确认",

  SetName_Top: "请输入用户昵称",
  SetName_Btn: "确认更改",
  SetOut_Text1: "提示",
  setOut_Text2: "确认退出登录",
  setOut_btn1: "取消",
  setOut_btn2: "确认",

  LoginIndex_CutLanuage: "选择语言",
  LoginIndex_Tab_Top: "请登录或创建您的账户",
  LoginIndex_Btn1: "登录",
  LoginIndex_Btn2: "创建账户",
  LoginIndex_Tab_Btt: "关于 E-mart mall",

  Longin_Top_Name: "登录",
  Longin_Tab_Name: "请登录到您的账户",
  Longin_Tab_Text1: "手机号码*",
  Longin_Tab_P1: "请输入手机号码",
  Longin_Tab_Text2: "输入密码*",
  Longin_Tab_P2: "请输入您的密码",
  Longin_Tab_Btm: "忘记密码？",
  Longin_Btn: "登录",

  SigninPage_Top_Name: "创建账户",
  SigninPage_Tab_Name: "创建账户",
  SigninPage_Tab_Text1: "手机号码*",
  SigninPage_Tab_P1: "请输入手机号码",
  SigninPage_Tab_Text2: "登录密码*",
  SigninPage_Tab_P2: "请输入登录密码",
  SigninPage_Tab_Text3: "确认密码*",
  SigninPage_Tab_P3: "请输入确认密码",
  SigninPage_Tab_Text4: "邀请码（可选）",
  SigninPage_Tab_P4: "请输入邀请码",
  SigninPage_Tab_Text5: "注册协议",
  SigninPage_Btn: "确认注册",

  MyPage_Msg_Copy: "复制成功",
  TxPage_Msg_Msg1: "请输入提现金额",
  TxPage_Msg_Msg2: "请选择银行卡",
  TxPage_Msg_Msg3: "请输入提现密码",
  TxPage_Msg_Msg4: "提现金额不能大于账户余额",

  SetTx_Msg1: "密码不能为空",
  SetTx_Msg2: "提现密码必须包含至少 6 个字符",
  SetTx_Msg3: "新提现密码必须包含至少 6 个字符",
  SetTx_Msg4: "新提现密码与确认提现不相同",
  SetTx_Msg5: "修改成功",

  SetTk_Msg1: "密码不能为空",
  SetTk_Msg2: "确认密码必须与新密码相同",
  SetTk_Msg3: "密码必须包含 6 个字符",
  SetTk_Msg4: "密码与登录密码不相同",
  SetTk_Msg5: "设置提现密码成功",

  SetPage_Msg1: "请输入用户名",
  SetPage_Msg2: "修改成功",
  SetPage_Msg3: "退出成功",

  SetLoginPassWord_Msg1: "密码不能为空",
  SetLoginPassWord_Msg2: "密码输入错误",
  SetLoginPassWord_Msg3: "新密码与确认密码不同",
  SetLoginPassWord_Msg4: "密码必须包含至少 6 个字符",
  SetLoginPassWord_Msg5: "修改成功",

  MyAccount_Msg1: "复制成功",

  AddBank_Msg1: "持卡人姓名不能为空",
  AddBank_Msg2: "银行卡号不能为空",
  AddBank_Msg3: "提现密码不能为空",
  AddBank_Msg4: "提现密码不正确",
  AddBank_Msg5: "添加成功",

  SigninPage_Msg1: "登录账户不能为空",
  SigninPage_Msg2: "登录密码不能为空",
  SigninPage_Msg3: "密码与确认密码不同",
  SigninPage_Msg4: "密码必须大于 6 位",
  SigninPage_Msg5: "请勾选注册协议",
  SigninPage_Msg6: "注册成功",
  SigninPage_Msg7: "手机号码输入有误,请重新输入",

  LoginPage_Msg1: "登录账户不能为空",
  LoginPage_Msg2: "登录密码不能为空",
  LoginPage_Msg3: "登录成功",

  Bill_date: "筛选",
  TxPage_Msg_Msg5: "提现成功",

  Bill_Tab_Msg1: "已完成",
  Bill_Tab_Msg2: "审核中",
  Bill_Tab_Msg3: "提现失败",

  SelTxWay_Top_Name: "提现方式",
  SelTxWay_Sel1: "银行卡",
  SelTxWay_Sel2: "数字货币",

  TxSGX_Top_Name: "提现",
  TxSGX_Input1: "真实姓名",
  TxSGX_Input2: "交易所地址",

  TxSGX_Smg1: "请输入姓名",
  TxSGX_Smg2: "请输入地址",
  TxSGX_Smg3: "请输入姓名",
  TxSGX_Smg4: "请输入地址",

  CzPage_TopName: "充值",
  CzPage_I1: "请输入充值地址",
  CzPage_Msg1: "充值前请询问客服提供充值地址",
  CzPage_I2: "请输入充值金额",
  CzPage_Btn: "提交充值",

  Terms_center_Text3: "代理合作",
  Terms_center_Text4: "平台代理模式",
  Terms_center_Text5: "平台用户可以通过推荐邀清新人成为平台代理，代理可以获得额外的动态分润奖励，每一笔分润将会转账到您的账户，可在账户明细中查看!一级用户奖励为一级用户每次拼单金额佣金的33%，二级用户奖励为二级用户每次拼单金额佣金的16%，三级用户奖励为三级用户每次拼单金额佣金的8%",
  Terms_center_Text6: "温馨提示:所有代理下线用户,厂商的佣金奖励均为本平台所出，不影响所有代理以及下线的佣金!",
  Terms_center_Text7: "规则说明",
  Terms_center_Text8: "佣金分配平台会员所得佣金为订单全额的0.32%-1.88%",
  Terms_center_Text9: "尊敬的用户你好",
  Terms_center_Text10: "平台为了防止有人恶意进行洗黑找或者套现一系列不法行为，账户资金满200会员需完成60单方可全额提现，福利单是无法取消或更换的。提现审核成功后，转账到你的户口时间为(10:00-21:00)，(其余时间提现次日24点前到账)具体到账时间以银行为准!为了保证商家成交量的真实性，所有任务订单都是垫付立返抢到任务订单后清及时提交订单，避免延时未提交订单导致订单异常降低信用分",
  Terms_center_Text11: "平台用一人银行卡信息请勿重复注册抢单，同一手机上最多可使用2个账户，超过2个账户一经发现将会冻结账户。系统随机匹配高佣福利单，福利单无法取消或更换个人多账户抢单会导致商家分店，影响商家的信管度以及销售单作废，本平台禁止一个银行卡绑定多账户，各位兼职会员请勿使用个人多账户,绑定做帐户的全部始予资金冻结90天账号永久封号处理",
};
