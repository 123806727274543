<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0" style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon><ArrowLeftBold /></el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("Smg_Top_Name") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
            </div>
        </div>
        <div>
            <div v-if="DataArr.length == 0" class="mt-[65px] text-[#af9799] text-center">
                {{ $t('Smg_Null_Text') }}
            </div>

            <div class="demo-collapse mt-[65px]">
                <el-collapse accordion>
                    <el-collapse-item v-for="(data,index) in DataArr" 
                    :title="data.title +'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+data.create_time" 
                    :name="index" :key="index" class="px-3 bg-white mt-3">
                        <div>
                            <div class="font-bold">{{ data.type }}</div>
                            <div class="text-slate-400">{{ data.create_time }}</div>
                        </div>
                        <div>
                            {{ data.message }}
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();
const DataArr = computed(() => store.getters.USER_DATA.notifications);

onMounted(async() => {
    // DataArr.value = await store.dispatch('GetUserMsgData');
})

function getGo() {
    router.go(-1)
}
</script>