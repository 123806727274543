<template>
    <div class="AddBank left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0" style="position: fixed;z-index: 9999;">
            <div class="basis-1/6 place-content-center" @click="getGo()">
                <el-icon><ArrowLeftBold /></el-icon>
            </div>
            <div class="basis-4/5 flex items-center justify-center">{{ $t("SetLogin_LoginPS_Name") }}</div>
            <div class="basis-1/6 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[65px] px-3 text-lg ">
            <div class="text-2xl px-1 pb-5 border-b border-[#00000014]">
                {{ $t('SetLogin_Tab_TextName') }}
            </div>
            <div class="rounded-md overflow-hidden p-4 mx-4 bg-white !mx-0 mt-5 py-5">
                <div class="mt-4 text-xs">{{ $t('SetLogin_Tab_Text1') }}</div>

                <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                    <input type="password" v-model="OdlPassWord" :placeholder="$t('SetLogin_Tab_P1')" style="width: 100%;outline:none">
                </div>

                <div class="mt-4 text-xs">{{ $t('SetLogin_Tab_Text2') }}</div>

                <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                    <input type="password" v-model="NewPassWord1" :placeholder="$t('SetLogin_Tab_P2')" style="width: 100%;outline:none">
                </div>

                <div class="mt-4 text-xs">{{ $t('SetLogin_Tab_Text3') }}</div>

                <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                    <input type="password" v-model="NewPassWord2" :placeholder="$t('SetLogin_Tab_P3')" style="width: 100%;outline:none">
                </div>

                <div class="text-white py-3">
                    <div class="bg-theme p-3 text-center " @click="submit">{{ $t('SetLogin_Btn') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { ArrowRight,Close } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();
const OdlPassWord = ref('');
const NewPassWord1 = ref('');
const NewPassWord2 = ref('');
const { t } = useI18n();

function getGo() {
    router.go(-1)
}

async function submit(){
    if(OdlPassWord.value == '' || OdlPassWord.value == null && NewPassWord1.value == '' || NewPassWord1.value == null && NewPassWord2.value == '' || NewPassWord2.value == null){
        ElMessage.error(t('SetLoginPassWord_Msg1'))
    }else if(NewPassWord1.value.length < 6){
        ElMessage.error(t('SetLoginPassWord_Msg4'))
    }else if(NewPassWord1.value != NewPassWord2.value){
        ElMessage.error(t('SetLoginPassWord_Msg3'))
    }else{

        try{
            await store.dispatch('SetPassWord', { data: { "old_password": OdlPassWord.value, "new_password": NewPassWord1.value } })
            ElMessage({
                message: t('SetLoginPassWord_Msg5'),
                type: 'success',
                duration: 2000,
            })

            store.commit("UserStore_SETDATA_IS_NULL");
            store.commit("OrderStore_SETDATA_IS_NULL");
            localStorage.clear()
            router.push("/Loginindex");
        }catch(e){
            console.log(e)
        }
    }

}
</script>
