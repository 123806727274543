import store from "@/store/store";

export default {
  "HomePage-TopName": "E-MART MALL",
  "HomePage-Swiper-Top": "المهام اليومية",
  "HomePage-Swiper-Hint": "المهام العاجلة ذات عمولة أعلى",
  "HonePage_Btn": "ابدأ في طلب المهام",
  "HomePage-Btn_Swiper1": "تهانينا! المستخدم",
  "HomePage-Btn_Swiper2": "تم ترقيته إلى",
  "HomePage-Centre_Text1": "الشركاء التجاريون",
  "HomePage-Centre_Text2": "شركاء العلامة التجارية المتعاونين",
  "HomePage-Btm_text1": "مركز الخدمة",
  "HomePage-Btm_text2": "إذا كان لديك أي أسئلة أخرى، يرجى زيارة مركز الدعم",
  "HomePage-Btm_text3": "دعوة الأصدقاء",
  "HomePage-Btm_text4": "الأسئلة الشائعة",
  "HomePage-Btm_text5": "الشروط والأحكام",
  "HomePage-Btm_text6": "حول E-mart mall",
  "HomePage-Hao_Btn1": "الصفحة الرئيسية",
  "HomePage-Hao_Btn2": "الطلبات",
  "HomePage-Hao_Btn3": "المهام",
  "HomePage-Hao_Btn4": "الخدمات",
  "HomePage-Hao_Btn5": "ملفي",

  "HomePage-Pop_Top1": "مرحبا بك في E-MART MALL",
  "HomePage-Pop_Top2": "دعوة الأصدقاء لكسب العمولات",
  "HomePage-Pop_Top3": "رمز الترويج",
  "HomePage-Pop_Btn": "نسخ رابط الدعوة",
  "HomePage-Pop_Msg": "تم النسخ بنجاح",
  "HomePage-Pop_Name": "اختر اللغة",

  "Tasks_Top_Name": "المهام",
  "Tasks_Card_Order": "الطلبات اليومية",
  "Tasks_Card_Ration": "نسبة العمولة",
  "Unit": "Rp",
  "Tasks_Gain_Money": "العمولة المكتسبة",
  "Tasks_Freeze_Moneny": "المبلغ المجمد",
  "Tasks_Order": "الطلبات المنجزة",
  "Tasks_Money": "الرصيد المتاح",
  "Tasks_Top_Text1": "وصف الطلب",
  "Tasks_Text1": "يمكن لكل حساب مطابقة ما يصل إلى " +
    store.getters.USER_DATA.member_level_max_order_quantity +
    " طلبات يوميًا",
  "Tasks_Text2": "نسبة العمولة الموحدة هي " +
    store.getters.USER_DATA.number_direct_referrals +
    "% من إجمالي مبلغ الطلب",
  "Tasks_Text3": "يستخدم النظام تقنية LBS لتطابق المنتجات تلقائيًا عبر الحوسبة السحابية",
  "Tasks_Text4": "لتجنب الرقابة من المنصة، سيتم تجميد مبلغ الطلب إذا لم يتم تأكيده خلال 30 دقيقة",
  "Tasks_Text5": "لا يمكنك إلغاء المهمة. يجب عليك أولاً إتمام أي مهام غير مكتملة قبل أن تتمكن من قبول مهام جديدة.",
  "Tasks_Top_Text2": "تنبيه",
  "Tasks_Text6": "عند تطابق النظام للطلبات للمستخدمين، سيتم إرسال معلومات الطلب إلى خلفية التاجر. إذا لم يقم المستخدم بتقديم الطلب خلال 30 دقيقة، سيتم تجميد مبلغ الطلب. لتجنب نظام الرقابة، سيتم تقييم حساب المستخدم، مما سيؤثر مباشرة على وقت المطابقة التالي وسمعة المستخدم! يرجى النقر على إيقاف قبول الطلبات في الوقت المناسب لتجنب التجميد بسبب تجاوز الوقت.",
  "Tasks_Pop_TopName": "مهمة الطلب",
  "Tasks_Pop_title1": "سعر الوحدة",
  "Tasks_Pop_title2": "عدد الطلبات",
  "Tasks_Pop_title3": "الإجمالي",
  "Tasks_Pop_title4": "عمولة الطلب",
  "Tasks_Pop_title5": "تقييم الطلب",
  "Tasks_Pop_title6": "تقييم الطلب",
  "Tasks_Pop_Op1": "منتج ممتاز",
  "Tasks_Pop_Op2": "تلقيت المنتج النهائي",
  "Tasks_Pop_Op3": "منتج ممتاز",
  "Tasks_Pop_Op4": "أنا راضٍ تمامًا عن هذا المنتج وأوصي بشرائه",
  "Tasks_Pop_Op5": "أنا سعيد جدًا وراضٍ. السعر معقول",
  "Tasks_Pop_Op6": "الإنتاج ممتاز، أنا أحب ذلك كثيرًا",
  "Tasks_Pop_Op7": "يبدو جيدًا، وقد اشترى صديقي المنتج للمرة الثانية",
  "Tasks_Pop_Colse_Text1": "هل أنت متأكد أنك تريد إلغاء قبول المهمة؟",
  "Tasks_Pop_Colse_Text2": "المهام الملغاة ستجمد تلقائيًا في قائمة المهام",
  "Tasks_Pop_Colse_Btn1": "تابع",
  "Tasks_Pop_Colse_Btn2": "تأكيد الإلغاء",
  "Tasks_Pop_Msg": "العملية ناجحة",
  "Tasks_Pop_Btn": "تأكيد",

  "TasksIndex_Top_Name": "مركز الأعضاء",
  "TasksIndex_MB": "فوائد الأعضاء",
  "TasksIndex_Supassed": "الدرجة الحالية",
  "TasksIndex_Supassed_not": "في انتظار الترقية",
  "TasksIndex_Supassed_yi": "تمت الترقية",
  "TasksIndex_CR": "نسبة العمولة",
  "TasksIndex_OQ": "عدد الطلبات",
  "TasksIndex_Deposit": "مبلغ الشحن",
  "TasksIndex_Btn": "شحن المبلغ",
  "TasksMsg_Pop": "تم طلب المهمة بنجاح",
  "TasksMsg_Sub": "تم الإرسال بنجاح",

  "Service_Top_Name": "الخدمات",
  "Service_Top_Text1": "مرحبًا،",
  "Service_Top_Text2": "أهلاً وسهلاً! إذا واجهت أي مشاكل في الإيداع والسحب، يرجى التواصل مع فريق الدعم لدينا.",
  "Service_Top_Btn": "تواصل مع الدعم",
  "Service_Top_Date": "ساعات العمل: 09:00ص - 16:00م 17:30م - 23:00م",
  "Service_Submit": "تأكيد",

  "FAQ_Top_Name": "الأسئلة الشائعة",
  "FAQ_Center_Text1": "الأسئلة الشائعة",
  "FAQ_Center_Text2": "تعرف على المزيد من خلال الأسئلة الشائعة",
  "FAQ_Bm_Text1": "تواصل معنا",
  "FAQ_Bm_Text2": "أهلاً وسهلاً! إذا واجهت أي مشاكل في الإيداع والسحب، يرجى التواصل مع فريق الدعم لدينا.",
  "FAQ_Bm_Text3": "تواصل مع الدعم",
  "FAQ_Bm_Text4": "ساعات العمل: 0:00ص - 24:00م",
  "FAQ_Bm_Text5": "وقت سحب المنصة: 12:00ص - 23:00م",
  "FAQ_Bm_Text6": "وقت الطلبات: 0:00ص - 24:00م",

  "Terms_Top_Name": "الشروط والأحكام",
  "Terms_Center_Text1": "تنصل المسؤولية",
  "Terms_Center_Text2": `
        <h1 style="padding-bottom:10px;">ستُصرف العمولة والمكافآت التي يكسبها الأعضاء الموصون خلال 24 ساعة بعد مراجعتها من قبل موظفي الدعم.</h1>
        <h1 style="padding-bottom:10px;">قد تتغير أو تعدل الشروط والأحكام المذكورة هنا في أي وقت.</h1>
        <h1 style="padding-bottom:10px;">تُدار قواعد المنصة بواسطة E-mart mall، والحقوق النهائية للتفسير تعود لـ E-mart mall.</h1>
  `,

  "About_Top_Name": "حول",
  "About_Center_Text1": "نبذة عن الشركة",
  "About_Center_P1": "تأسست في 12 يوليو 2018",
  "About_Center_P2": "رأس المال المسجل للشركة: 12.51 مليون AED",
  "About_Center_P3": "مؤهلات الشركة",
  "About_Center_P4": "واحدة من أكبر شركات خدمات التجارة الإلكترونية في منطقة الشرق الأوسط",
  "About_Center_P5": "1. Souq.com   7. Sharafdg.com",
  "About_Center_P6": "2. Ounass.ae",
  "About_Center_P7": "3. أمازون",
  "About_Center_P8": "4. Groupon.ae",
  "About_Center_P9": "5. Basharacare.com",
  "About_Center_P10": "6. LetsTango.com",
  "About_Center_P11": "7. Sharafdg.com",
  "About_Center_P12":
    "في عام 2020، 2021، و2023، حصلت على جائزة أفضل شركة شراكة في الشرق الأوسط. بسبب تأثير الجائحة وتغيرات عصر البيانات الكبيرة، أصبح التسوق عبر الإنترنت مهارة أساسية للعمال اليوميين، وظهرت تطبيقات التجارة الإلكترونية مثل (Souq.com)، (Ounass.ae)، (أمازون)، (Groupon.ae)، (Basharacare.com)، وغيرها. أصبح التنافس شديدًا مع دخول العديد من المتاجر الإلكترونية إلى منصات التطبيقات. لتحقيق الفوائد والسمعة الجيدة في هذا المجال، تعتبر جودة المنتج والمبيعات أمرًا ضروريًا. لكن كما يقول المثل، الأسواق الحديثة تشبه ساحات القتال القديمة، مليئة بالاستراتيجيات والحيل. ومع ذلك، تحاول العديد من المتاجر الكبرى زيادة مبيعاتها من خلال هذا المنبر، مما يؤدي إلى تطور نماذج غير مرئية مثل الطلبات الافتراضية لتحسين الشهرة والمبيعات.",
  "About_Center_P13":
    "لقد اكتشف هذا المنبر هذا النموذج، لذلك تم تطوير منصة طلبات المجموعة هذه. نموذج التشغيل للمنصة هو من خلال الحوسبة السحابية، حيث يتم نقل روابط شراء المنتجات من المنصات التجارية إلى قاعدة بيانات السحابة، ثم يتم نقل معلومات الشراء إلى E-mart mall عبر قاعدة البيانات، حيث يقوم التاجر بإرسال الطلبات الافتراضية لزيادة تقييم المنتجات. هذه الطريقة مثالية جدًا.",
  "About_Center_P14": "نبذة عن المنصة",
  "About_Center_P15": "المستخدمين المحترمين",
  "About_Center_P16":
    "المنصة هي منصة تطابق الطلبات عبر السحابة الذكية من طرف ثالث. المنصة تتعاون مع المتاجر على المنصات التجارية مثل Souq.com، Ounass.ae، أمازون، Groupon.ae، Basharacare.com، LetsTango.com، Sharafdg.com. من خلال النظام السحابي الذكي، يتم تطابق طلبات التجار مع مستخدمي المنصة لزيادة حجم المعاملات وسمعة التجار على المنصات. وقد حسنت هذه المنصة الطريقة التقليدية المعقدة لتقديم الطلبات عبر الإنترنت، حيث يستخدم الروبوتات الذكية من بعد لمحاكاة عملية تقديم الطلبات اليدوية، مما يجعل المطابقة أكثر كفاءة وسهولة! تقوم المنصة يوميًا بنشر منتجات التجار التي تحتاج إلى زيادة الظهور، وتوفرها للمستخدمين للطلب الجماعي. لتجنب اكتشاف الطلبات الوهمية وتحسين معدل نجاح الطلبات، يجب على المستخدمين الجدد تقديم معلوماتهم الشخصية الحقيقية وعنوان التسليم الحقيقي قبل الطلب. ولتحقيق ذلك، ستقوم المنصة بتحسين المطابقة بناءً على عنوان IP الخاص بك ونموذج الجهاز. جميع المطابقات تتم عبر الخوارزميات السحابية الذكية. أوقات شحن الطلبات من الساعة 10:00 صباحًا حتى 9:00 مساءً. (إذا كان لديك أي استفسار، يرجى النقر على دعم العملاء للحصول على المساعدة!)",

  "Order_TopName": "الطلبات",
  "Order_Tab_Text1": "جميع الطلبات",
  "Order_Tab_Text2": "قيد المعالجة",
  "Order_Tab_Text3": "مجمد",
  "Order_Tab_Text4": "مكتمل",
  "Order_Tab_NullMsg": "تم التحميل",
  "Order_Tabs_Time": "وقت الإكمال",
  "Order_Tabs_NotTime": "لم يكتمل بعد",
  "Order_Tabs_Re": "قيد المعالجة",
  "Order_Tabs_Com": "مكتمل",
  "Order_Tabs_Text1": "سعر الوحدة",
  "Order_Tabs_Text2": "عدد الطلبات",
  "Order_Tabs_Text3": "الإجمالي",
  "Order_Tabs_Text4": "عمولة الطلب",
  "Order_Tabs_Btn": "تقديم الطلب",
  "Order_Tabs_Sun": "تم التقديم بنجاح",

    "My_Top_Name": "حسابي",
  "My_Deposit": "مبلغ الإيداع",
  "My_Code": "رمز الدعوة",
  "My_Score": "تقييم الائتمان",
  "My_Text1": "رصيد الحساب",
  "My_Text2": "الرصيد المجمد",
  "My_Tabs_Text1": "حسابي",
  "My_Tabs_Text2": "تفاصيل الفواتير",
  "My_Tabs_Text3": "مركز الأعضاء",
  "My_Tabs_Text4": "تقرير الفريق",
  "My_Tabs_Text5": "دعوة الأصدقاء",
  "My_Tabs_Text6": "اختيار اللغة",

  "MyAccount_Top_Name": "حسابي",
  "MyAccount_Text1": "رصيد الحساب",
  "MyAccount_Tx": "سحب",
  "MyAccount_Task": "دخل المهام",
  "MyAccount_Team": "دخل الفريق",
  "MyAccount_Task_Money": "الدخل من إتمام المهام",
  "MyAccount_Com": "دعوة الأصدقاء لكسب العمولات",
  "MyAccount_Btn1": "إتمام المهام",
  "MyAccount_Btn2": "دعوة الأصدقاء",
  "MyAccount_FAQ": "الأسئلة الشائعة",

  "SetTkPW_Top_Name": "تعيين كلمة مرور السحب",
  "SetTkPW_Name_Text": "تعيين كلمة مرور السحب",
  "SetTkPW_NewPassWord": "كلمة مرور السحب الجديدة*",
  "SetTkPW_OdlPassWord": "تأكيد كلمة مرور السحب الجديدة*",
  "SetTkPW_Btn": "تأكيد",
  "SetTkPW_New_P": "يرجى إدخال كلمة مرور السحب",
  "SetTkPW_New_O": "يرجى إدخال تأكيد كلمة مرور السحب",

  "Tx_Top_Name": "سحب",
  "Tx_TxMoney": "مبلغ السحب",
  "Tx_BalMoney": "رصيد الحساب",
  "Tx_Input_P": "يرجى إدخال مبلغ السحب",
  "Tx_Money_Text": "المبلغ المستلم",
  "Tx_Bank_Card": "بطاقة السحب",
  "Tx_Bank_New": "إضافة جديد",
  "Tx_Bank_Btn": "تغيير",
  "Tx_Bank_PassWord": "كلمة مرور السحب",
  "Tx_Money_Btn": "تأكيد السحب",

  "AddBank_Top_Name": "ربط بطاقة بنكية",
  "AddBank_Pop_Cancel": "إلغاء",
  "AddBank_Pop_Confirm": "تأكيد",
  "AddBank_tab_Name1": "البنك",
  "AddBank_tab_Name2": "اسم حامل البطاقة",
  "AddBank_tab_P2": "يرجى إدخال اسم حامل البطاقة",
  "AddBank_tab_Name3": "رقم بطاقة البنك",
  "AddBank_tab_P3": "يرجى إدخال رقم بطاقة البنك",
  "AddBank_tab_Name4": "كلمة مرور السحب",
  "AddBank_tab_P4": "يرجى إدخال كلمة مرور السحب",
  "AddBank_add_Btn": "تأكيد الإضافة",

  "Bill_Top_Name": "تفاصيل الفواتير",
  "Bill_Tab_Text1": "عمولات المهام",
  "Bill_Tab_Text2": "استرداد رأس المال",
  "Bill_Tab_Text3": "طلبات معلقة",
  "Bill_Tab_Text4": "عمولات المهام",
  "Bill_prev": "الصفحة السابقة",
  "Bill_next": "الصفحة التالية",

  "Team_Top_Name": "تقرير الفريق",
  "Team_Tab_Text1": "دخل الفريق",
  "Team_Tab_text2": "عدد المدعوين",
  "Smg_Top_Name": "رسائل",
  "Smg_Null_Text": "تم التحميل",

  "Set_Top_Name": "الإعدادات",
  "Set_Tab_Text1": "كلمة مرور تسجيل الدخول",
  "Set_Tab_Text2": "كلمة مرور السحب",
  "Set_Tab_Text3": "الصورة الشخصية",
  "Set_Tab_Text4": "اللقب",
  "Set_Btn": "تسجيل الخروج",

  "SetLogin_LoginPS_Name": "تغيير كلمة مرور تسجيل الدخول",
  "SetLogin_Tab_TextName": "تغيير كلمة مرور تسجيل الدخول",
  "SetLogin_Tab_Text1": "كلمة المرور القديمة*",
  "SetLogin_Tab_P1": "يرجى إدخال كلمة المرور القديمة",
  "SetLogin_Tab_Text2": "كلمة المرور الجديدة*",
  "SetLogin_Tab_P2": "يرجى إدخال كلمة المرور الجديدة",
  "SetLogin_Tab_Text3": "تأكيد كلمة المرور*",
  "SetLogin_Tab_P3": "يرجى إدخال تأكيد كلمة المرور",
  "SetLogin_Btn": "تأكيد التغيير",

  "SetTxPS_Top_Name": "تغيير كلمة مرور السحب",
  "SetTx_Tab_TextName": "تغيير كلمة مرور السحب",
  "SetTx_Tab_Text1": "كلمة مرور السحب*",
  "SetTx_Tab_P1": "يرجى إدخال كلمة مرور السحب الخاصة بك",
  "SetTx_Tab_Text2": "كلمة مرور السحب الجديدة*",
  "SetTx_Tab_P2": "يرجى إدخال كلمة مرور السحب الجديدة",
  "SetTx_Tab_Text3": "تأكيد كلمة مرور السحب الجديدة*",
  "SetTx_Tab_P3": "يرجى إدخال تأكيد كلمة مرور السحب",
  "SetTx_Btn": "تأكيد",

  "SetName_Top": "يرجى إدخال اللقب",
  "SetName_Btn": "تأكيد التغيير",
  "SetOut_Text1": "تنبيه",
  "setOut_Text2": "تأكيد تسجيل الخروج",
  "setOut_btn1": "إلغاء",
  "setOut_btn2": "تأكيد",

  "LoginIndex_CutLanuage": "اختيار اللغة",
  "LoginIndex_Tab_Top": "يرجى تسجيل الدخول أو إنشاء حسابك",
  "LoginIndex_Btn1": "تسجيل الدخول",
  "LoginIndex_Btn2": "إنشاء حساب",
  "LoginIndex_Tab_Btt": "حول E-mart mall",

  "Longin_Top_Name": "تسجيل الدخول",
  "Longin_Tab_Name": "يرجى تسجيل الدخول إلى حسابك",
  "Longin_Tab_Text1": "رقم الهاتف*",
  "Longin_Tab_P1": "يرجى إدخال رقم الهاتف",
  "Longin_Tab_Text2": "أدخل كلمة المرور*",
  "Longin_Tab_P2": "يرجى إدخال كلمة المرور الخاصة بك",
  "Longin_Tab_Btm": "نسيت كلمة المرور؟",
  "Longin_Btn": "تسجيل الدخول",

  "SigninPage_Top_Name": "إنشاء حساب",
  "SigninPage_Tab_Name": "إنشاء حساب",
  "SigninPage_Tab_Text1": "رقم الهاتف*",
  "SigninPage_Tab_P1": "يرجى إدخال رقم الهاتف",
  "SigninPage_Tab_Text2": "كلمة مرور تسجيل الدخول*",
  "SigninPage_Tab_P2": "يرجى إدخال كلمة مرور تسجيل الدخول",
  "SigninPage_Tab_Text3": "تأكيد كلمة المرور*",
  "SigninPage_Tab_P3": "يرجى إدخال تأكيد كلمة المرور",
  "SigninPage_Tab_Text4": "رمز الدعوة (اختياري)",
  "SigninPage_Tab_P4": "يرجى إدخال رمز الدعوة",
  "SigninPage_Tab_Text5": "اتفاقية التسجيل",
  "SigninPage_Btn": "تأكيد التسجيل",

  "MyPage_Msg_Copy": "تم النسخ بنجاح",
  "TxPage_Msg_Msg1": "يرجى إدخال مبلغ السحب",
  "TxPage_Msg_Msg2": "يرجى اختيار بطاقة بنكية",
  "TxPage_Msg_Msg3": "يرجى إدخال كلمة مرور السحب",
  "TxPage_Msg_Msg4": "مبلغ السحب لا يمكن أن يتجاوز رصيد الحساب",

  "SetTx_Msg1": "كلمة المرور لا يمكن أن تكون فارغة",
  "SetTx_Msg2": "كلمة مرور السحب يجب أن تحتوي على 6 أحرف على الأقل",
  "SetTx_Msg3": "كلمة مرور السحب الجديدة يجب أن تحتوي على 6 أحرف على الأقل",
  "SetTx_Msg4": "كلمة مرور السحب الجديدة وتأكيد كلمة مرور السحب غير متطابقتين",
  "SetTx_Msg5": "تم التغيير بنجاح",

  "SetTk_Msg1": "كلمة المرور لا يمكن أن تكون فارغة",
  "SetTk_Msg2": "تأكيد كلمة المرور يجب أن يكون مطابقًا لكلمة المرور الجديدة",
  "SetTk_Msg3": "كلمة المرور يجب أن تحتوي على 6 أحرف",
  "SetTk_Msg4": "كلمة المرور لا تتطابق مع كلمة مرور تسجيل الدخول",
  "SetTk_Msg5": "تم تعيين كلمة مرور السحب بنجاح",

  "SetPage_Msg1": "يرجى إدخال اسم المستخدم",
  "SetPage_Msg2": "تم التغيير بنجاح",
  "SetPage_Msg3": "تم تسجيل الخروج بنجاح",

  "SetLoginPassWord_Msg1": "كلمة المرور لا يمكن أن تكون فارغة",
  "SetLoginPassWord_Msg2": "كلمة المرور المدخلة غير صحيحة",
  "SetLoginPassWord_Msg3": "كلمة المرور الجديدة وتأكيد كلمة المرور غير متطابقتين",
  "SetLoginPassWord_Msg4": "كلمة المرور يجب أن تحتوي على 6 أحرف على الأقل",
  "SetLoginPassWord_Msg5": "تم التغيير بنجاح",

  "MyAccount_Msg1": "تم النسخ بنجاح",

  "AddBank_Msg1": "اسم حامل البطاقة لا يمكن أن يكون فارغًا",
  "AddBank_Msg2": "رقم بطاقة البنك لا يمكن أن يكون فارغًا",
  "AddBank_Msg3": "كلمة مرور السحب لا يمكن أن تكون فارغة",
  "AddBank_Msg4": "كلمة مرور السحب غير صحيحة",
  "AddBank_Msg5": "تمت الإضافة بنجاح",

  "SigninPage_Msg1": "رقم الحساب لا يمكن أن يكون فارغًا",
  "SigninPage_Msg2": "كلمة مرور تسجيل الدخول لا يمكن أن تكون فارغة",
  "SigninPage_Msg3": "كلمة المرور وتأكيد كلمة المرور غير متطابقتين",
  "SigninPage_Msg4": "كلمة المرور يجب أن تكون أكبر من 6 أحرف",
  "SigninPage_Msg5": "يرجى قبول اتفاقية التسجيل",
  "SigninPage_Msg6": "تم التسجيل بنجاح",
  "SigninPage_Msg7": "رقم الهاتف غير صحيح، يرجى إدخاله مرة أخرى",

  "LoginPage_Msg1": "رقم الحساب لا يمكن أن يكون فارغًا",
  "LoginPage_Msg2": "كلمة مرور تسجيل الدخول لا يمكن أن تكون فارغة",
  "LoginPage_Msg3": "تم تسجيل الدخول بنجاح",

  "Bill_date": "تصفية",
  "TxPage_Msg_Msg5": "تم السحب بنجاح",

  "Bill_Tab_Msg1": "مكتمل",
  "Bill_Tab_Msg2": "تحت المراجعة",
  "Bill_Tab_Msg3": "فشل السحب",

  "SelTxWay_Top_Name": "طريقة السحب",
  "SelTxWay_Sel1": "بطاقة بنكية",
  "SelTxWay_Sel2": "عملة رقمية",

  "TxSGX_Top_Name": "سحب",
  "TxSGX_Input1": "الاسم الحقيقي",
  "TxSGX_Input2": "عنوان البورصة",

  "TxSGX_Smg1": "يرجى إدخال الاسم",
  "TxSGX_Smg2": "يرجى إدخال العنوان",
  "TxSGX_Smg3": "يرجى إدخال الاسم",
  "TxSGX_Smg4": "يرجى إدخال العنوان",

  "CzPage_TopName": "إيداع",
  "CzPage_I1": "يرجى إدخال عنوان الإيداع",
  "CzPage_Msg1": "يرجى استشارة خدمة العملاء للحصول على عنوان الإيداع",
  "CzPage_I2": "يرجى إدخال مبلغ الإيداع",
  "CzPage_Btn": "تقديم الإيداع",

  "Terms_center_Text3": "التعاون مع الوكلاء",
  "Terms_center_Text4": "نموذج وكيل المنصة",
  "Terms_center_Text5": "يمكن لمستخدمي المنصة أن يصبحوا وكلاء من خلال دعوة الأشخاص الجدد. يمكن للوكلاء الحصول على مكافآت إضافية من الأرباح الديناميكية، وسيتم تحويل كل دفعة من الأرباح إلى حسابك ويمكنك عرضها في تفاصيل الحساب! المكافأة للمستوى الأول هي 33% من عمولة مبلغ كل طلب من المستخدمين في المستوى الأول، والمكافأة للمستوى الثاني هي 16% من عمولة مبلغ كل طلب من المستخدمين في المستوى الثاني، والمكافأة للمستوى الثالث هي 8% من عمولة مبلغ كل طلب من المستخدمين في المستوى الثالث.",
  "Terms_center_Text6": "تنبيه: جميع المكافآت للوكيل ولأفراد الشبكة يتم دفعها من قبل المنصة، ولا تؤثر على عمولات الوكلاء أو الشبكة!",
  "Terms_center_Text7": "توضيح القواعد",
  "Terms_center_Text8": "توزيع العمولة: العمولة التي يحصل عليها أعضاء المنصة تتراوح بين 0.32% إلى 1.88% من إجمالي مبلغ الطلب.",
  "Terms_center_Text9": "عزيزي المستخدم،",
  "Terms_center_Text10": "من أجل منع الأفراد من القيام بأنشطة غير قانونية مثل غسل الأموال أو سحب الأموال، يجب على الأعضاء الذين لديهم رصيد يتجاوز 200 إتمام 60 طلبًا قبل أن يتمكنوا من سحب المبلغ بالكامل. الطلبات الترويجية لا يمكن إلغاؤها أو استبدالها. بعد الموافقة على طلب السحب، سيتم تحويل الأموال إلى حسابك بين (10:00-21:00)، (وفي أوقات أخرى قبل منتصف الليل في اليوم التالي)، ويعتمد الوقت المحدد على البنك! لضمان مصداقية حجم المعاملات التجارية، جميع الطلبات هي مدفوعة مسبقًا، وعند الحصول على الطلبات يجب تقديمها في الوقت المناسب لتجنب تأخير الطلبات الذي قد يؤدي إلى انخفاض نقاط الائتمان.",
  "Terms_center_Text11": "يرجى عدم التسجيل المتكرر باستخدام معلومات بطاقة البنك الخاصة بشخص واحد. يمكن استخدام حسابين فقط على نفس الهاتف. إذا تم اكتشاف أكثر من حسابين، سيتم تجميد الحسابات. يتم مطابقة الطلبات ذات العمولات العالية عشوائيًا، ولا يمكن إلغاء أو استبدال الطلبات الترويجية. استخدام حسابات متعددة سيتسبب في فقدان سمعة المتاجر وإلغاء الطلبات. تمنع المنصة ربط بطاقة بنك واحدة بعدد من الحسابات. يجب على الأعضاء غير الدائمين عدم استخدام حسابات متعددة. سيتم تجميد جميع الأموال المرتبطة بحسابات متعددة لمدة 90 يومًا، وسيفرض حظر دائم على الحسابات."
};
