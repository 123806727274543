import UserPort from '@/Port/UserPort'
import { ElMessage } from 'element-plus';

const state = {
  USER_TOKEN: "",
  ERRORMSG: "",
  USER_DATA: "",
  VIP_DATA: "",
  ImgData: [],
  FAQData: [],
  RuleData: [],
  IntroData:[],
};

const getters = {
  USER_TOKEN: (state) => state.USER_TOKEN,
  USER_DATA: (state) => state.USER_DATA,
  VIP_DATA: (state) => state.VIP_DATA,
  ImgData: (state) => state.ImgData,
  GetFAQData: (state) => state.FAQData,
  GetRuleData: (state) => state.RuleData,
  GetIntroData: (state) => state.IntroData,
};

const mutations = {
  SETUSER_TOKEN: (state, USER_TOKEN) => {
    state.USER_TOKEN = USER_TOKEN;
  },
  UserStore_SETDATA_IS_NULL: (state) => {
    state.USER_TOKEN = "";
    state.USER_DATA = "";
  },
  SET_USER_DATA: (state, data) => {
    state.USER_DATA = data;
  },
  SET_VIP_DATA: (state, data) => (state.VIP_DATA = data),
  SETImgData: (state, data) => {state.ImgData = data},
  SetFAQData: (state,data) => {state.FAQData = data},
  SetRuleData: (state,data) => {state.RuleData = data},
  SetIntroData: (state,data) => {state.IntroData = data;},
};

const actions = {
  // 登入验证
  async LoginFalg({ commit }, { phone_code, phone, PassWord }) {
    commit("SetLoadingFlag", true);
    const token = await UserPort.LoginFalg(phone_code, phone, PassWord);
    localStorage.setItem("token", token.data.data.access_token);
    commit("SETUSER_TOKEN", token.data.data.access_token);
  },

  // 注册
  async RegUser({ commit }, { data }) {
    commit("SetLoadingFlag", true);
    await UserPort.RegUser(data).then((res) => {});
  },

  // 获取轮播图图片
  async GetSwiperImg({ commit }) {
    commit("SETImgData", (await UserPort.GetSwiperImg()).data.data);
  },

  // 退出登录
  async OutLogin({ commit }) {
    await UserPort.OutLogin();
  },

  // 获取用户信息
  async GetUserData({ commit }) {
    commit("SET_USER_DATA", (await UserPort.GetUserData()).data.data);
  },

  // 修改登录密码
  async SetPassWord({ commit }, { data }) {
    await UserPort.SetPassWord(data);
  },

  // 设置提现密码
  async SetTxPassWord({ commit, dispatch }, { data }) {
    await UserPort.SetTxPassWord(data);
    await dispatch("GetUserData");
  },

  // 修改提现密码
  async UpTxPassWord({ commit }, { data }) {
    await UserPort.UpTxPassWord(data);
  },

  // 获取VIP等级信息
  async GetVipData({ commit, state, dispatch }) {
    commit("SET_VIP_DATA", (await UserPort.GetVipData()).data.data);
    await dispatch("GetUserData");
  },

  // // 获取用户公告
  // async GetUserMsgData({ commit }){
  //     return (await UserPort.GetUserMsgData()).data.data;
  // },

  // 更改用户名
  async UpUserName({ commit, dispatch }, { data }) {
    await UserPort.UpUserName(data);
    await dispatch("GetUserData");
  },

  // 获取用户账单
  async GetiUserBill({ commit }, { data }) {
    return (await UserPort.GetiUserBill(data)).data.data;
  },

  // 获取用户银行卡
  async GetUserBank_Card() {
    let arr = [];
    let CardArr = (await UserPort.GetUserBank_Card()).data.data;

    if (CardArr) {
      for (var i = 0; i < CardArr.length; i++) {
        arr.push({ text: CardArr[i].text, value: CardArr[i].value });
      }
    }
    return arr;
  },

  // 用户提现
  async UserTx({ commit, dispatch }, { data }) {
    await UserPort.UserTx(data);
    await dispatch("GetUserData");
  },

  // 获取所有银行
  async GetAllUser() {
    let arr = [];
    let CardArr = (await UserPort.GetAllUser()).data.data;

    if (CardArr) {
      for (var i = 0; i < CardArr.length; i++) {
        arr.push({ text: CardArr[i].code, value: CardArr[i].id });
      }
    }
    return arr;
  },

  // 添加银行卡
  async AddBank_Card({ commit }, { data }) {
    await UserPort.AddBank_Card(data);
  },

  //   提现方式
  async WitType() {
    return (await UserPort.WitType()).data.data;
  },

  // 数字货币提现
  async UserTxNumber({ commit, dispatch }, { data }) {
    await UserPort.UserTxNumber(data);
    await dispatch("GetUserData");
  },

  // 充值
  async CzMoney({ commit }, { data }) {
    await UserPort.CzMoney(data);
  },

//   获取公司信息
  async GetFirmData({ commit },{ data }){
    if(data == '常见问题'){
        commit("SetFAQData", await UserPort.GetFirmData(data));
    }else if (data == "规则说明") {
      commit("SetRuleData", await UserPort.GetFirmData(data));
    } else {
      const Arr = [];
      Arr.push(...(await UserPort.GetFirmData("平台")));
      Arr.push(...(await UserPort.GetFirmData("公司")));
      commit("SetIntroData", Arr);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
