<template>
    <div class="SetTkPassWord left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">

        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0" style="position: fixed;z-index: 9999;">
            <div class="basis-1/6 place-content-center" @click="getGo()">
                <el-icon><ArrowLeftBold /></el-icon>
            </div>
            <div class="basis-4/5 flex items-center justify-center">{{ $t("SetTkPW_Top_Name") }}</div>
            <div class="basis-1/6 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[65px] px-5">
            <div class="text-2xl pb-5 border-b border-[#00000014]">{{ $t('SetTkPW_Name_Text') }}</div>
        </div>

        <div class="p-5 rounded-md overflow-hidden">
            <div class="bg-white p-4">
                <div class="mt-4">{{ $t('SetTkPW_NewPassWord') }}</div>
                <div class="mt-4 px-4 py-2 p-4 border-[1px] border-slate-200">
                    <input type="password" v-model="newPassWord" :placeholder="$t('SetTkPW_New_P')" style="width: 100%;outline:none">
                </div>
                <div class="mt-4">{{ $t('SetTkPW_OdlPassWord') }}</div>
                <div class="mt-4 px-4 py-2 border-[1px] border-slate-200">
                    <input type="password" v-model="odlPassWord" :placeholder="$t('SetTkPW_New_O')" style="width: 100%;outline:none">
                </div>
            </div>
        </div>

        <div class="text-white p-5">
            <div class="bg-theme p-3 text-center rounded-md" @click="submit">{{ $t('SetTkPW_Btn') }}</div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore()
const { t } = useI18n();
const router = useRouter();
const newPassWord = ref('');
const odlPassWord = ref('');

function getGo() {
    router.go(-1)
}

async function submit(){
    if(newPassWord.value == '' || odlPassWord.value == ''){
        ElMessage({
            message: t('SetTk_Msg1'),
            type: 'warning',
            plain: true,

        })
    } else if (newPassWord.value.length < 6){
        ElMessage({
            message: t('SetTk_Msg3'),
            type: 'warning',
            plain: true,

        })
    }else if(newPassWord.value != odlPassWord.value){
        ElMessage({
            message: t('SetTk_Msg2'),
            type: 'warning',
            plain: true,
        })
    }else{
        await store.dispatch('SetTxPassWord', { data: { withdrawal_password:newPassWord.value } })
        ElMessage({
            message: t('SetTk_Msg5'),
            type: 'success',
            duration: 2000,
        })

        setTimeout(() => {
            router.push('/MyPage')
        }, 1000)
        
    }
}

</script>