<template>
    <div class="grow left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative; ">

        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/3"></div>
            <div class="basis-1/3 flex items-center justify-center">{{ $t("Service_Top_Name") }}</div>
            <div class="basis-1/3 flex items-center justify-end p-2">
                <router-link to="/MsgPage">
                    <img src="@/assets/Img/msg.svg" style="width: 20px; height: 20px;">
                </router-link>
            </div>
        </div>

        <div class="m-4 left-0 bg-[white] mt-[108px] pt-10 px-4 rounded-xl text-[#000000E5] text-sm grow pb-10">
            <div class="flex justify-start items-center mt-6">
                <div class="w-[80px]">
                    <img src="@/assets/Img/service.png">
                </div>
                <div class="place-content-center text-2xl ml-5">{{ $t('Service_Top_Text1') }}</div>
            </div>

            <div class="mt-4">{{ $t('Service_Top_Text2') }}</div>

            <div class="mt-10 flex place-content-center bg-theme p-3 text-sm text-[white]" @click="$GoServe">
                <div>{{ $t('Service_Top_Btn') }}</div>
            </div>

            <div class="mt-4 text-theme-gray-light text-center">{{ $t('FAQ_Bm_Text4') }}</div>
            <div class="mt-4 text-theme-gray-light text-center">{{ $t('FAQ_Bm_Text5') }}</div>
            <div class="mt-4 text-theme-gray-light text-center">{{ $t('FAQ_Bm_Text6') }}</div>
        </div>

        <div
            class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] bottom-0 justify-around">
            <router-link to="/">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn1') }}</div>
                    <div style="display: flex;justify-content: center;">
                    </div>
                </div>
            </router-link>

            <router-link to="/OrderPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn2') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/TasksPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn3') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/ServerPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn4') }}</div>
                    <div style="display: flex;justify-content: center;">
                        <img src="@/assets/Img/Server.png" style="height: 20px;width: 16px;">
                    </div>
                </div>
            </router-link>

            <router-link to="/MyPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn5') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>