import en from '@/assets/Js/lang/en-us.js'
import ar from "@/assets/Js/lang/ar-eg.js";
import cn from "@/assets/Js/lang/zh-cn.js";
import tc from "@/assets/Js/lang/zh-tw.js";

import { createI18n } from 'vue-i18n'

const cur_lang = localStorage.getItem('Language');

// 设置默认语言
cur_lang ? "" : localStorage.setItem("Language", "en-us");

const i18n = createI18n({
  legacy: false,
  locale: cur_lang ? cur_lang : 'en-us',
  messages: {
    "en":en,
    "ar-eg":ar,
    "zh-cn":cn,
    "zh-tw":tc
  },
});

export default i18n;