import OrderPort from "@/Port/OrderPort"

const state = {
    ORDER_DATA:'',
}

const getters = {
    GET_ORDER_ALL:(state) => state.ORDER_DATA,
    GET_ORDER_DJ:(state) => {
        var arr = [];
        state.ORDER_DATA.some((item) => {
            if (item.status_id == 10) {
                arr.push(item)
            }
        });
        return arr;
    },
    GET_ORDER_DB:(state) => {
        var arr = [];
        state.ORDER_DATA.some((item) => {
            if (item.status_id == 2) {
                arr.push(item)
            }
        });
        return arr;
    },
    GET_ORDER_WC:(state) =>{
        var arr = [];
        state.ORDER_DATA.some((item) => {
            if (item.status_id == 5) {
                arr.push(item)
            }
        });
        return arr;
    },
}

const mutations = {
  SET_ORDER_DATA: (state, data) => (state.ORDER_DATA = data),
  OrderStore_SETDATA_IS_NULL: (state) => {
    state.ORDER_DATA = "";
  },
};

const actions = {
  // 获取抢单信息
  async GetOrderData({ commit }) {
    commit("SetLoadingFlag", true);
    return (await OrderPort.GetOrderData()).data.data;
  },

  // 获取订单信息
  async GetOrderList({ commit }) {
    commit("SET_ORDER_DATA", (await OrderPort.GetOrderList()).data.data);
  },

  // 提交订单
  async SubmitOrder({ commit }, { data }) {
    await OrderPort.SubmitOrder(data);
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};