<template>
    <div class="text-[white] text-lg grow flex left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl"
        v-loading="$store.getters.GetLoadingFlag" style="height: 71.5rem;">
        <div class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] top-0"
            style="max-height: 352px;"
        >
            <div class="basis-1/3"></div>
            <div class="basis-1/3 flex items-center justify-center">{{ $t("HomePage-TopName") }}</div>
            <div class="basis-1/3 flex items-center justify-end p-2">
                <router-link to="/MsgPage">
                    <img src="@/assets/Img/msg.svg" style="width: 20px; height: 20px;">
                </router-link>
            </div>
        </div>

        <div
            class="p-3 absolute text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl pt-[4rem] bg-[white]" style="height: 428px;">
            <div>
                <div class="text-[black] text-base">{{ $t('HomePage-Swiper-Top') }}</div>
                <div class="text-[#cdcdcd] text-sm">{{ $t('HomePage-Swiper-Hint') }}</div>
            </div>

            <div style="height: 226px;width: 100%;">
                <swiper :modules="[Virtual, Autoplay]" :slides-per-view="swiperOption.slidesPerView"
                    :space-between="swiperOption.spaceBetween" :autoplay="swiperOption.autoplay"
                    :speed="swiperOption.speed" :loop="swiperOption.loop" :loopedSlides="swiperOption.loopedSlides"
                    :centered-slides="swiperOption.centeredSlides" :allow-touch-move="swiperOption.allowTouchMove"
                    :free-mode="swiperOption.freeMode" ref="swiperRef" v-if="ImgData">
                    <swiper-slide class="mt-4 pb-3 flex mx-2" v-for="(data, index) in ImgData" :key="index">
                        <div class="w-36">
                            <div class="van-image rounded-md overflow-hidden border-2 border-gray-100 overflow-hidden"
                                style="width: 142px; height: 142px;">
                                <img :src="data.logo">
                            </div>
                            <div class="text-xs text-[black] mt-3 line-clamp-1 text-center">{{ data.title }}</div>
                            <div class="text-sm text-[#878787] opacity-60 text-center">{{ data.market_price }}</div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="mt-2">
                <div class="Home-Btn">
                    <router-link to="/TasksPage">
                        <div class="bg-theme p-3 flex place-content-center">
                            <img src="@/assets/Img/Finger.svg" class="">
                            <span class="text-sm">{{ $t('HonePage_Btn') }}</span>
                        </div>
                    </router-link>
                </div>

                <div style="margin-top: 10px;width: 100%;position: relative;">

                    <div style="padding: 3px;position: absolute;">
                        <img src="@/assets/Img/Hint.jpeg" alt="" style="width: 16px;height: 16px;">
                    </div>

                    <div style="color: #808080;font-size: 12px;">
                        <el-carousel height="20px" direction="vertical" :autoplay="true">
                            <el-carousel-item v-for="(data,index) in $GetHomeHtintData" :key="index">

                                <span justify="center">{{$t('HomePage_Btn_Swiper1')}} {{ data.UserName }}
                                    {{$t('HomePage_Btn_Swiper2')}} {{ data.VIP }}。</span>

                            </el-carousel-item>

                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute pb-4 top-2/4 md:top-2/3 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl bg-slate-50">
            <div class="pl-3 pt-5 text-[black] text-base">{{ $t('HomePage_Centre_Text1') }}</div>
            <div class="pl-3 text-[#878787] text-sm">{{ $t('HomePage_Centre_Text2') }}</div>

            <div class="flex mt-4 flex-wrap gap-y-3.5">

                <div class="w-[33.33vw] md:w-[16rem] h-[calc(33.33vw*80/252)] md:h-[calc(16rem*80/252)] shrink-0 pl-3">
                    <div class="van-image" style="width: 100%; height: 100%;">
                        <img class="van-image_img" src="@/assets/Img/Centre1.jpg" lazy="loaded"
                            style="object-fit: cover;">
                    </div>
                </div>

                <div class="w-[33.33vw] md:w-[16rem] h-[calc(33.33vw*80/252)] md:h-[calc(16rem*80/252)] shrink-0 pl-3">
                    <div class="van-image" style="width: 100%; height: 100%;">
                        <img class="van-image_img" src="@/assets/Img/Centre2.jpg" lazy="loaded"
                            style="object-fit: cover;">
                    </div>
                </div>

                <div class="w-[33.33vw] md:w-[16rem] h-[calc(33.33vw*80/252)] md:h-[calc(16rem*80/252)] shrink-0 pl-3">
                    <div class="van-image" style="width: 100%; height: 100%;">
                        <img class="van-image_img" src="@/assets/Img/Centre3.jpg" lazy="loaded"
                            style="object-fit: cover;">
                    </div>
                </div>

                <div class="w-[33.33vw] md:w-[16rem] h-[calc(33.33vw*80/252)] md:h-[calc(16rem*80/252)] shrink-0 pl-3">
                    <div class="van-image" style="width: 100%; height: 100%;">
                        <img class="van-image_img" src="@/assets/Img/Centre4.jpg" lazy="loaded"
                            style="object-fit: cover;">
                    </div>
                </div>

                <div class="w-[33.33vw] md:w-[16rem] h-[calc(33.33vw*80/252)] md:h-[calc(16rem*80/252)] shrink-0 pl-3">
                    <div class="van-image" style="width: 100%; height: 100%;">
                        <img class="van-image_img" src="@/assets/Img/Centre5.jpg" lazy="loaded"
                            style="object-fit: cover;">
                    </div>
                </div>

                <div class="w-[33.33vw] md:w-[16rem] h-[calc(33.33vw*80/252)] md:h-[calc(16rem*80/252)] shrink-0 pl-3">
                    <div class="van-image" style="width: 100%; height: 100%;">
                        <img class="van-image_img" src="@/assets/Img/Centre6.jpg" lazy="loaded"
                            style="object-fit: cover;">
                    </div>
                </div>

                <div class="w-[33.33vw] md:w-[16rem] h-[calc(33.33vw*80/252)] md:h-[calc(16rem*80/252)] shrink-0 pl-3">
                    <div class="van-image" style="width: 100%; height: 100%;">
                        <img class="van-image_img" src="@/assets/Img/Centre7.jpg" lazy="loaded"
                            style="object-fit: cover;">
                    </div>
                </div>

               
            </div>
        </div>

        <div
            class="text-[black] text-sm p-3 bg-[white] pb-[80px] absolute pb-4 top-[656px] md:top-[850px] text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl">
            <div>
                <div class="text-[black] text-base">{{ $t('HomePage_Btm_text1') }}</div>
                <div class="text-[#878787] text-sm">{{ $t('HomePage_Btm_text2') }}</div>
            </div>

            <div style="display: flex;align-items: center;padding-top: 20px;justify-content: space-between"
                class="text-[black]" @click="RowPopFlag = true">
                <div>{{ $t('HomePage_Btm_text3') }}</div>
                <el-icon>
                    <ArrowRight />
                </el-icon>
            </div>

            <el-divider />

            <router-link to="/FAQ">
                <div style="display: flex;align-items: center;padding-top: 20px;justify-content: space-between"
                    class="text-[black]">
                    <div>{{ $t('HomePage_Btm_text4') }}</div>
                    <el-icon>
                        <ArrowRight />
                    </el-icon>
                </div>
            </router-link>

            <el-divider />

            <router-link to="/Terms">
                <div style="display: flex;align-items: center;padding-top: 20px;justify-content: space-between"
                    class="text-[black]">
                    <div>{{ $t('HomePage_Btm_text5') }}</div>
                    <el-icon>
                        <ArrowRight />
                    </el-icon>
                </div>
            </router-link>

            <el-divider />

            <router-link to="/About">
                <div style="display: flex;align-items: center;padding-top: 20px;justify-content: space-between"
                    class="text-[black]">
                    <div>{{ $t('HomePage_Btm_text6') }}</div>
                    <el-icon>
                        <ArrowRight />
                    </el-icon>
                </div>
            </router-link>

        </div>

        <div
            class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] bottom-0 justify-around">
            <router-link to="/">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn1') }}</div>
                    <div style="display: flex;justify-content: center;">
                        <img src="@/assets/Img/Home.png" style="height: 20px;width: 16px;">
                    </div>
                </div>
            </router-link>

            <router-link to="/OrderPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn2') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/TasksPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn3') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/ServerPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn4') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/MyPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn5') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

        </div>

        <Transition name="Pop">
            <div v-show="RowPopFlag" class="rounded-md fixed w-full md:w-[46rem] top-[15%] z-[2024] p-3">
                <div class="bg-white p-4 text-black flex flex-col rounded-[20px]" style="position: relative;">

                    <div style="text-align: right;" @click="RowPopFlag = false">
                        <el-icon>
                            <Close color="#c8c9cc" />
                        </el-icon>
                    </div>

                    <div class="text-[#FF5D02] text-xl font-bold tracking-wider">
                        {{ $t('HomePage_Pop_Top1') }}
                    </div>

                    <div class="text-2xl">
                        {{ $t('HomePage_Pop_Top2') }}
                    </div>

                    <div class="my-5 w-2/3 mx-auto">
                        <img src="@/assets/Img/share_hand.png" alt="">
                    </div>

                    <div class="text-sm text-theme-gray-light py-4 text-center text-[#666666]">
                        {{ $t('HomePage_Pop_Top3') }}:{{ Coding }}
                    </div>

                    <div class="bg-theme p-3 rounded-[50px]" @click="Copy">
                        <div class="text-white text-center">{{ $t('HomePage_Pop_Btn') }}</div>
                    </div>

                    <div style="position: absolute;top: 0;left: 0;">
                        <img src="@/assets/Img/bg_top.svg">
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 遮挡窗 -->
        <Transition name="Pop">
            <div v-show="RowPopFlag" class="overlay2">

            </div>
        </Transition>

        <!-- <Transition name="Pop">
            <div v-if="Dialog" class="Mp3Pop">
                <div style="width: 90%;background: #edf2fa;padding: 10px;border-radius: 10px;">
                    <div class="Mp3_Close">
                        <el-icon color="black" @click="CloseDialog">
                            <Close />
                        </el-icon>
                    </div>
                    <div class="Mp3_info">
                        <video class="Mp3_dom" controls autoplay>
                            <source src="@/assets/mp4/home.mp4" type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>
        </Transition>

        <transition name="Pop">
            <div v-if="Dialog" class="overlay2">

            </div>
        </transition> -->
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Virtual,Autoplay } from 'swiper/modules';
import { ArrowRight,Close } from '@element-plus/icons-vue'
import 'swiper/swiper-bundle.css';
import 'swiper/css/autoplay';
import 'swiper/css/virtual';
import { nextTick, onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';

// const Dialog = ref(sessionStorage.getItem('Dialog') ? false : true)
const store = useStore();
const RowPopFlag = ref(false);
const Coding = computed(() => store.getters.USER_DATA.invitation_code);
const { t } = useI18n();
const ImgData = computed(() => store.getters.ImgData);
const swiperRef = ref()

const swiperOption = {
    slidesPerView: 3, // 每次显示3张幻灯片
    spaceBetween: 50, // 幻灯片之间的间距
    autoplay: {
        delay: 0, // 无延迟，确保连续滑动
        disableOnInteraction: false, // 用户操作后不停止自动播放
    },
    speed: 3000, // 幻灯片切换速度，确保平滑
    loop: true, // 启用循环播放
    loopedSlides: 6, // 设置循环播放时额外复制的幻灯片数量
    centeredSlides: true, // 启用居中模式，减少闪烁
    allowTouchMove: true, // 启用手动滑动
    freeMode: false, // 禁用自由模式，避免与循环冲突
}

function Copy(){
    navigator.clipboard.writeText(Coding.value)
    ElMessage({
        message: t('HomePage_Pop_Msg'),
        type: 'success',
        plain: true,
    })
}

// function CloseDialog() {
//     console.log(1)
//     Dialog.value = false;
//     sessionStorage.setItem('Dialog', false)
// }
</script>