<template>
    <div class="p-4 text-sm left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl LoginPage" style="position: relative;"
        v-loading="$store.getters.GetLoadingFlag">
        <div class="flex px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("SigninPage_Top_Name") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="pt-[50px] text-2xl px-1 pb-5 text-white border-b border-[#00000014]">
            <div>{{ $t('SigninPage_Tab_Name') }}</div>
        </div>
        <div class="rounded-md overflow-hidden p-4 mx-4 bg-white !mx-0 mt-14 py-8 shadow-lg shadow-theme-red">
            <div>
                {{ $t('SigninPage_Tab_Text1') }}
            </div>

            <div class="mt-4 flex gap-x-4">
                <el-select v-model="PhoneCardName" size="large" style="width: 240px" :change="SetPhoneCard()"
                    :fit-input-width="true">
                    <el-option v-for="item in DataArr" :key="item.v" :label="item.name" :value="item.name" />
                </el-select>
                <div class="px-4 py-[9px] border-[1px] border-slate-200 flex-1 ml-[-20px]">
                    <input v-model="UserName" :placeholder="$t('SigninPage_Tab_P1')" style="width: 100%;outline:none">
                </div>
            </div>

            <div class="mt-4">
                {{ $t('SigninPage_Tab_Text2') }}
            </div>

            <div class="mt-4">
                <div class="mt-4 px-4 py-[9px] border-[1px] border-slate-200">
                    <input type="password" v-model="UserPassWord1" :placeholder="$t('SigninPage_Tab_P2')"
                        style="width: 100%;outline:none">
                </div>
            </div>

            <div class="mt-4">
                {{ $t('SigninPage_Tab_Text3') }}
            </div>

            <div class="mt-4">
                <div class="mt-4 px-4 py-[9px] border-[1px] border-slate-200">
                    <input type="password" v-model="UserPassWord2" :placeholder="$t('SigninPage_Tab_P3')"
                        style="width: 100%;outline:none">
                </div>
            </div>

            <div class="mt-4">
                {{ $t('SigninPage_Tab_Text4') }}
            </div>

            <div class="mt-4">
                <div class="mt-4 px-4 py-[9px] border-[1px] border-slate-200">
                    <input type="text" v-model="Code" :placeholder="$t('SigninPage_Tab_P4')"
                        style="width: 100%;outline:none">
                </div>
            </div>

            <div class="mt-5 flex">
                <div class="border-[1px] border-slate-200 w-[20px] h-[20px] rounded-[50px] mr-2 text-center"
                    @click="PopFlag = !PopFlag"
                    :style="{backgroundColor : PopFlag ? '#ed222e' : ''}">
                    <el-icon v-if="PopFlag" color="white">
                        <Check />
                    </el-icon>
                </div>
                <span @click="PopFlag ? RowPopFlag = false : RowPopFlag = true;">{{ $t('SigninPage_Tab_Text5') }}</span>
            </div>
        </div>

        <div class="bg-theme text-white text-center mt-5 rounded-md py-4" @click="submit">
            {{ $t('SigninPage_Btn') }}
        </div>

        <Transition name="Pop">
            <div v-show="RowPopFlag" class="rounded-md fixed w-11/12 md:w-[46rem] top-[15%] z-[2024] p-3">
                <div class="bg-white p-4 text-black flex flex-col rounded-[20px]" style="position: relative;">

                    <div style="text-align: right;" @click="RowPopFlag = false">
                        <el-icon>
                            <Close color="#c8c9cc" />
                        </el-icon>
                    </div>

                    <div class="p-4">
                        <div class="flex items-center justify-between mb-2">
                            <div class="text-lg">Registration Agreement</div>
                            <i
                                class="van-badge__wrapper van-icon van-icon-cross text-right cursor-pointer text-[#999]"></i>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 遮挡窗 -->
        <Transition name="Pop">
            <div v-show="RowPopFlag" class="overlay2">

            </div>
        </Transition>
    </div>
</template>

<script setup>
import { ArrowLeftBold,Check,Close } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { ref } from 'vue'
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
const store = useStore();
const router = useRouter();
const PopFlag = ref(false);
const RowPopFlag = ref(false);
const DataArr = ref([
    { "name": "🇸🇦 +966", "v": "+966" },
    { "name": "🇦🇪 +971", "v": "+971" },
    { "name": "🇪🇬 +20", "v": "+20" },
    { "name": "🇯🇴 +962", "v": "+962" },
    { "name": "🇲🇦 +212", "v": "+212" },
    { "name": "🇩🇿 +213", "v": "+213" },
    { "name": "🇮🇶 +964", "v": "+964" },
    { "name": "🇰🇼 +965", "v": "+965" },
    { "name": "🇧🇭 +973", "v": "+973" },
    { "name": "🇶🇦 +974", "v": "+974" },
    { "name": "🇴🇲 +968", "v": "+968" },
    { "name": "🇸🇾 +963", "v": "+963" },
    { "name": "🇱🇧 +961", "v": "+961" },
    { "name": "🇹🇳 +216", "v": "+216" },
    { "name": "🇱🇾 +218", "v": "+218" },
    { "name": "🇸🇩 +249", "v": "+249" },
    { "name": "🇾🇪 +967", "v": "+967" },
    { "name": "🇺🇸 +1", "v": "+1" },
    { "name": "🇨🇳 +86", "v": "+86" },
    { "name": "🇹🇼 +886", "v": "+886" }
])
const PhoneCardName = ref(DataArr.value[0].name);
const PhoneCard = ref(DataArr.value[0].v);
const UserName = ref('');
const UserPassWord1 = ref('');
const UserPassWord2 = ref('');
const Code = ref('');
const { t } = useI18n();

function getGo() {
    router.go(-1)
}

function SetPhoneCard(){
    PhoneCard.value = DataArr.value.find(time => time.name == PhoneCardName.value).v;
}

async function submit(){
    if(UserName.value == '' || UserName.value == null){
        ElMessage.error(t('SigninPage_Msg1'))
    } else if (UserName.value.length < 6){
        ElMessage.error(t('SigninPage_Msg7'))
    }else if(UserPassWord1.value == '' || UserPassWord1.value == null){
        ElMessage.error(t('SigninPage_Msg2'))
    }else if(UserPassWord1.value != UserPassWord2.value){
        ElMessage.error(t('SigninPage_Msg3'))
    }else if(UserPassWord1.value.length < 6){
        ElMessage.error(t('SigninPage_Msg4'))
    }else if(!PopFlag.value){
        ElMessage.error(t('SigninPage_Msg5'))
    }else{
        try{
            const data = {
                'phone_code': PhoneCard.value,
                'phone': UserName.value,
                'password': UserPassWord1.value,
                'invitation_code': Code.value
            }

            await store.dispatch('RegUser', { data: data })
            ElMessage({
                message: t('SigninPage_Msg6'),
                type: 'success',
                duration: 2000,
            })
            router.push('/');
        }catch(e){
            console.log(e)
        }
    }
}
</script>
