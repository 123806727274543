const state = {
    Language: localStorage.getItem("Language") == '' ? 'en' : localStorage.getItem("Language"),
}

const getters = {
    Language: (state) => state.Language
}

const mutations = {
    SetLanguage:(state,Language) => {
        localStorage.setItem('Language',Language);
        state.Language = Language;
    }
}


export default {
    state,getters,mutations
};