<template>
    <div class="AddBank left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/6 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-4/5 flex items-center justify-center">{{ $t("AddBank_Top_Name") }}</div>
            <div class="basis-1/6 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[65px] px-5 text-sm grow">
            <div class="mt-4 text-xs">{{ $t('AddBank_tab_Name1') }}</div>

            <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md"
                @click="drawer = true">
                <div>{{ fieldValue }}</div>
                <el-icon>
                    <ArrowRight />
                </el-icon>
            </div>

            <div class="mt-4 text-xs">{{ $t('AddBank_tab_Name2') }}</div>

            <div
                class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                <input v-model="Bank_Name" :placeholder="$t('AddBank_tab_P2')" style="width: 100%;outline:none">
            </div>

            <div class="mt-4 text-xs">{{ $t('AddBank_tab_Name3') }}</div>

            <div
                class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                <input type="number" v-model="Bank_Card" :placeholder="$t('AddBank_tab_P3')"
                    style="width: 100%;outline:none">
            </div>

            <div class="mt-4 text-xs">{{ $t('AddBank_tab_Name4') }}</div>

            <div
                class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                <input type="password" v-model="Card_PassWord" :placeholder="$t('AddBank_tab_P4')"
                    style="width: 100%;outline:none">
            </div>

            <div class="text-white p-5">
                <div class="bg-theme p-3 text-center rounded-[50px]" @click="submit">{{ $t('Tasks_Pop_Btn') }}</div>
            </div>
        </div>

        <el-drawer v-model="drawer" :with-header="false" direction="btt"
            class="custom-drawer left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl">
            <div>
                <van-picker title="" :confirm-button-text="$t('AddBank_Pop_Confirm')"
                    :cancel-button-text="$t('AddBank_Pop_Cancel')" :columns="columns" @confirm="onConfirm" />
            </div>
        </el-drawer>

    </div>


</template>

<script setup>
import { useRouter } from 'vue-router';
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { onMounted, ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { ArrowRight,Close } from '@element-plus/icons-vue';
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();
const defaultIndex = ref(0)
const router = useRouter();
const drawer = ref(false);
const columns = ref();
const fieldValue = ref();
const Bank_Name = ref('');
const Bank_Card = ref('');
const Bank_ID = ref()
const Card_PassWord = ref('');
const store = useStore();

onMounted(async() => {
    const BankAll = await store.dispatch('GetAllUser');

    if(BankAll){
        columns.value = BankAll;
        fieldValue.value = BankAll[0].text;
        Bank_ID.value = BankAll[0].value;
    }
})

function getGo() {
    router.go(-1)
}

async function submit(){
    if(Bank_Name.value == '' || Bank_Name.value == null){
        ElMessage.error(t('AddBank_Msg1'))
    }else if(Bank_Card.value == '' || Bank_Card.value == null){
        ElMessage.error(t('AddBank_Msg2'))
    }else if(Card_PassWord.value == '' || Card_PassWord.value == null){
        ElMessage.error(t('AddBank_Msg3'))
    }else{

        const data = {
            "bank_id":Bank_ID.value,
            "name":Bank_Name.value,
            "number":Bank_Card.value,
            "withdraw_password": Card_PassWord.value,
            "debit_credit":2
        }

        await store.dispatch('AddBank_Card',{data:data})

        ElMessage({
            message: t('AddBank_Msg5'),
            type: 'success',
        })

        setTimeout(() => {
            router.go(-1)
        },1000)
    }
}

function onConfirm(v){
    fieldValue.value = v.selectedOptions[0].text;
    Bank_ID.value = v.selectedOptions[0].value;
    drawer.value = false;
}

</script>
