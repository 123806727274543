<template>
    <div class="AddBank left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl"
        style="position: relative;min-height: 100vh;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/6 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-4/5 flex items-center justify-center">{{ $t("Bill_Top_Name") }}</div>
            <div class="basis-1/6 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[45px]">
            <div style="padding-top: 18px;padding-left: 10px;">
                <span style="background: #707070;color: white;padding: 10px;border-radius: 10px;" @click="SaiXuanBtn">{{
                    $t("Bill_date") }}</span>
            </div>

            <div v-for="(data, index) in DataArr.data" :key="index">
                <div class="p-4">
                    <div class="flex justify-between text-right ">
                        <div style="text-align: left;">
                            <div>{{ data.type_name }}</div>
                            <div>{{ data.update_time }}</div>
                        </div>

                        <div>
                            <div :style="{ color: data.debit_credit == '1' ? '#02b96c' : '#fd8002'}"> {{
                                data.debit_credit == '1' ? '+' : '-' }} {{ data.amount }}
                            </div>
                            <div>
                                <span style="color: #02b96c;border: 1px solid #02b96c;border-radius: 10px;padding:3px 5px;font-size: 12px;" v-if="data.withdraw_status == 1">
                                    {{ $t('Bill_Tab_Msg1') }}
                                </span>

                                <span style="color: #bd7a4a;border: 1px solid #bd7a4a;border-radius: 10px;padding:3px 5px;font-size: 12px;" v-if="data.withdraw_status == 0">
                                    {{ $t('Bill_Tab_Msg2') }}
                                </span>

                                <span style="color: #fd8002;border: 1px solid #fd8002;border-radius: 10px;padding:3px 5px;font-size: 12px;" v-if="data.withdraw_status == 2">
                                    {{ $t('Bill_Tab_Msg3') }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2">
                        <el-divider style="margin: 0 !important;" />
                    </div>
                </div>

            </div>
        </div>

        <div style="width: 100%;" class="mb-[30px] mt-[10px]">
            <van-pagination v-model="currentPage" :prev-text="$t('Bill_prev')" :next-text="$t('Bill_next')"
                :page-count="DataArr.last_page" mode="simple" />
        </div>

        <van-overlay :show="ShowFlag" @click="ShowFlag = false" />

        <Transition name="SaiXuanPop">
            <div v-show="ShowFlag" style="position: fixed;bottom: 0;width: 100%;z-index: 99999;">
                <van-date-picker v-model="currentDate" title="选择年月" :min-date="minDate" :max-date="maxDate"
                    :formatter="formatter" :columns-type="columnsType" :confirm-button-text="$t('Tasks_Pop_Btn')"
                    :cancel-button-text="$t('AddBank_Pop_Cancel')" @confirm="onConfirm" @cancel="onCancel" />
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();
const DataArr = ref({});
const currentPage = ref(1)
const columnsType = ['year', 'month'];
const minDate=new Date(1900 ,0);
const maxDate = new Date();
const ShowFlag = ref(false)
const currentDate = ref([new Date().getFullYear(), new Date().getMonth() + 1]);

function getGo(){
    router.push('/MyPage')
}

onMounted(async() => {
    DataArr.value = await store.dispatch('GetiUserBill', { data: { CurvePage: currentPage.value, ScreenDate: 1 } })
})

watch(currentPage, async () => {
    DataArr.value = await store.dispatch('GetiUserBill', { data: { CurvePage: currentPage.value, ScreenDate: 1 } })
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // 你可以使用 'auto' 代替 'smooth'，如果你不需要平滑滚动
    });
})

// 筛选按钮
function SaiXuanBtn(){
    ShowFlag.value = true;
}

// 确认
async function onConfirm(){
    // 关闭弹窗
    ShowFlag.value = false;
    // 设置查询第一页数据
    currentPage.value = 1;
    // 时间筛选
    DataArr.value = await store.dispatch('GetiUserBill', { data: { CurvePage: currentPage.value, ScreenDate: (new Date(currentDate.value[0], currentDate.value[1] - 1).getTime()) / 1000 } })
}

// 取消
async function onCancel(){
    // 关闭弹窗
    ShowFlag.value = false;
    // 设置查询第一页数据
    currentPage.value = 1;
    // 关闭时间筛选
    DataArr.value = await store.dispatch('GetiUserBill', { data: { CurvePage: currentPage.value, ScreenDate: 1 } })
}
</script>

<style>
.SaiXuanPop-enter-active,
.SaiXuanPop-leave-active {
    transition: opacity 0.5s ease;
}

.SaiXuanPop-enter,
.SaiXuanPop-leave-to {
    opacity: 0;
}

.SaiXuanPop-enter-to,
.SaiXuanPop-leave {
    opacity: 1;
}

.van-hairline-unset--top-bottom::after{
    border-color: rgb(126, 122, 122) !important;
}
</style>