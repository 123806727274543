<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("TxSGX_Top_Name") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[65px] text-sm">
            <div class="mt-4 text-xs flex items-center px-5 pt-5">
                {{ $t('Tx_TxMoney') }} <div class="text-[#666666] ml-3">{{ $t('Tx_BalMoney') }}:{{ $store.getters.USER_DATA.currency_symbol }} {{ $setMath(Money) }}
                </div>
            </div>

            <div class="px-5 mt-4">
                <div class="px-4 py-3 p-4 border-[1px] border-slate-200 bg-white rounded-md">
                    <input v-model="TxMoney" :placeholder="$t('Tx_Input_P')" style="width: 100%;outline:none"
                        @input="setMath">
                </div>
                <div class="mt-2 text-xs text-[#666]">
                    {{ $t('Tx_Money_Text') }}: {{ $store.getters.USER_DATA.currency_symbol }} {{ TxMoney == '' ? 0 : TxMoney }}
                </div>
            </div>

            <div class="mt-5 text-xs px-5">
                <div>
                    {{ $t('TxSGX_Input2') }}
                </div>
                <div class="mt-4 px-3 py-4 border-[1px] border-slate-200 bg-white rounded-md flex">
                    <input v-model="Path" :placeholder="$t('TxSGX_Smg2')"
                        style="width: 100%;outline:none">
                </div>
            </div>

            <div class="mt-5 text-xs px-5">
                <div>
                    {{ $t('Tx_Bank_PassWord') }}
                </div>
                <div class="mt-4 px-3 py-4 border-[1px] border-slate-200 bg-white rounded-md flex">
                    <input type="password" v-model="Card_PassWord" :placeholder="$t('SetTkPW_New_P')"
                        style="width: 100%;outline:none">
                </div>
            </div>

            <div class="text-white p-5">
                <div class="bg-theme p-3 text-center rounded-[50px]" @click="submit">{{ $t('Tx_Money_Btn') }}</div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();
const Money = computed(() => store.getters.USER_DATA.balance);
const TxMoney = ref('');
const columns = ref();
const Path = ref();
const Card_PassWord = ref();
const { t } = useI18n();

onMounted(async () => {
    const ArrCord = await store.dispatch('GetUserBank_Card');

    if (ArrCord.length > 0) {
        columns.value = ArrCord;
    }
})

function getGo(){
    router.push('/SelTxWay')
}

async function submit(){
    if (TxMoney.value == '' || TxMoney.value == null) {
        ElMessage.error(t('TxPage_Msg_Msg1'))
        return
    } else if (Path.value == '' || Path.value == null) {
        ElMessage.error(t('TxSGX_Smg4'))
        return
    } else if (Card_PassWord.value == '' || Card_PassWord.value == null) {
        ElMessage.error(t('TxPage_Msg_Msg3'))
    } else if (Card_PassWord.value.length < 6) {
        ElMessage.error(t('SetTx_Msg2'))
        return
    } else if (parseFloat(TxMoney.value.replace(/,/g, '')) > parseFloat(Money.value.replace(/,/g, ''))) {
        ElMessage.error(t('TxPage_Msg_Msg4'))
        return
    }

    await store.dispatch("UserTxNumber",{ data:{
        amount: parseFloat(TxMoney.value.replace(/,/g, '')),
        address:Path.value,
        withdraw_password: Card_PassWord.value
    } })
    TxMoney.value = '';
    Card_PassWord.value = '';
    ElMessage({
        message: t('TxPage_Msg_Msg5'),
        type: 'success',
    })

    // setTimeout(() => {
    //     router.go(-1)
    // }, 1000)
}

// 转换千分制
function setMath(v) {
    let formattedValue = TxMoney.value.replace(/[^\d]/g, '');

    // 添加千分位逗号
    let parts = [];
    for (let i = formattedValue.length - 1, j = 1; i >= 0; i--, j++) {
        parts.unshift(formattedValue[i]);
        if (j % 3 === 0 && i !== 0) parts.unshift(',');
    }
    TxMoney.value = parts.join('')
}
</script>