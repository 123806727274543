import store from "@/store/store";

export default {
  "HomePage-TopName": "E-MART MALL",
  "HomePage-Swiper-Top": "Daily Tasks",
  "HomePage-Swiper-Hint": "Urgent tasks have higher commissions",
  "HonePage_Btn": "Start Claiming Orders",
  "HomePage_Btn_Swiper1": "Congratulations! User",
  "HomePage_Btn_Swiper2": "has been upgraded to",
  "HomePage_Centre_Text1": "Partner Brands",
  "HomePage_Centre_Text2": "Brand Friendly Partners",
  "HomePage_Btm_text1": "Service Center",
  "HomePage_Btm_text2": "If you have any other questions, please visit the customer service center",
  "HomePage_Btm_text3": "Invite Friends",
  "HomePage_Btm_text4": "Frequently Asked Questions",
  "HomePage_Btm_text5": "Terms and Agreements",
  "HomePage_Btm_text6": "About E-mart Mall",
  "HomePage_Hao_Btn1": "Home",
  "HomePage_Hao_Btn2": "Orders",
  "HomePage_Hao_Btn3": "Tasks",
  "HomePage_Hao_Btn4": "Service",
  "HomePage_Hao_Btn5": "My",

  "HomePage_Pop_Top1": "Welcome to E-MART MALL",
  "HomePage_Pop_Top2": "Invite friends to earn commissions",
  "HomePage_Pop_Top3": "Promo Code",
  "HomePage_Pop_Btn": "Copy Invite Link",
  "HomePage_Pop_Msg": "Copy successful",
  "HomePage_Pop_Name": "Select Language",

  "Tasks_Top_Name": "Tasks",
  "Tasks_Card_Order": "Daily Orders",
  "Tasks_Card_Ration": "Commission Rate",
  "Unit": "Rp",
  "Tasks_Gain_Money": "Earned Commissions",
  "Tasks_Freeze_Moneny": "Frozen Amount",
  "Tasks_Order": "Completed Orders",
  "Tasks_Money": "Available Balance",
  "Tasks_Top_Text1": "Order Description",
  "Tasks_Text1":
    "Each account can match up to " +
    store.getters.USER_DATA.member_level_max_order_quantity +
    " orders per day",
  "Tasks_Text2":
    "Order commission is a uniform " +
    store.getters.USER_DATA.number_direct_referrals +
    "%",
  "Tasks_Text3": "The system uses LBS technology and cloud computing to automatically match products",
  "Tasks_Text4":
    "To prevent platform regulation, if an order is not confirmed within 30 minutes, the system will freeze the order amount",
  "Tasks_Text5": "You cannot cancel tasks. You must first complete any unfinished tasks before receiving new ones.",
  "Tasks_Top_Text2": "Note",
  "Tasks_Text6":
    "When the platform matches orders for users, it submits the order information to the merchant's backend. If the user does not submit the order within 30 minutes, the system will freeze the order amount. To avoid regulatory issues, the user account will be assessed, which will directly affect the user's next matching time and reputation! Please click to stop claiming orders in time to avoid timeout freezes.",
  "Tasks_Pop_TopName": "Order Task",
  "Tasks_Pop_title1": "Unit Price",
  "Tasks_Pop_title2": "Order Quantity",
  "Tasks_Pop_title3": "Total",
  "Tasks_Pop_title4": "Order Commission",
  "Tasks_Pop_title5": "Order Review",
  "Tasks_Pop_title6": "Order Evaluation",
  "Tasks_Pop_Op1": "Very good product",
  "Tasks_Pop_Op2": "Received final product",
  "Tasks_Pop_Op3": "Very good product",
  "Tasks_Pop_Op4": "I am very satisfied with this product and recommend purchasing",
  "Tasks_Pop_Op5": "I am very happy and satisfied. The price is reasonable",
  "Tasks_Pop_Op6": "The production is very good, I like it very much",
  "Tasks_Pop_Op7": "Looks great, my friend has bought it a second time",
  "Tasks_Pop_Colse_Text1": "Are you sure you want to exit task acceptance?",
  "Tasks_Pop_Colse_Text2": "Cancelled tasks will be automatically frozen in the task list",
  "Tasks_Pop_Colse_Btn1": "Continue",
  "Tasks_Pop_Colse_Btn2": "Confirm Exit",
  "Tasks_Pop_Msg": "Operation successful",
  "Tasks_Pop_Btn": "Confirm",

  "TasksIndex_Top_Name": "Member Center",
  "TasksIndex_MB": "Member Benefits",
  "TasksIndex_Supassed": "Current Level",
  "TasksIndex_Supassed_not": "To be upgraded",
  "TasksIndex_Supassed_yi": "Upgraded",
  "TasksIndex_CR": "Commission Rate",
  "TasksIndex_OQ": "Order Quantity",
  "TasksIndex_Deposit": "Deposit Amount",
  "TasksIndex_Btn": "Deposit Amount",
  "TasksMsg_Pop": "Order claimed successfully",
  "TasksMsg_Sub": "Submission successful",

  "Service_Top_Name": "Service",
  "Service_Top_Text1": "Hello,",
  "Service_Top_Text2":
    "Welcome! If you encounter any deposit and withdrawal issues during use, please contact our customer service team.",
  "Service_Top_Btn": "Contact Customer Service",
  "Service_Top_Date": "Business Hours: 09:00AM-16:00PM 17:30PM-23:00PM",
  "Service_Submit": "Confirm",

    "FAQ_Top_Name": "Frequently Asked Questions",
  "FAQ_Center_Text1": "Frequently Asked Questions",
  "FAQ_Center_Text2": "Learn more through frequently asked questions",
  "FAQ_Bm_Text1": "Contact Us",
  "FAQ_Bm_Text2": "Welcome! If you encounter deposit and withdrawal issues, please contact our customer service team.",
  "FAQ_Bm_Text3": "Contact Customer Service",
  "FAQ_Bm_Text4": "Business Hours: 0:00AM-24:00PM",
  "FAQ_Bm_Text5": "Platform Withdrawal Time: 12:00AM-23:00PM",
  "FAQ_Bm_Text6": "Order Time: 0:00AM-24:00PM",

  "Terms_Top_Name": "Terms and Agreements",
  "Terms_Center_Text1": "Disclaimer",
  "Terms_Center_Text2": `
        <h1 style="padding-bottom:10px;">Commissions and rewards earned by referring members will be credited to the referrer’s account within 24 hours after approval by customer service.</h1>
        <h1 style="padding-bottom:10px;">The terms and conditions here may be changed or modified at any time.</h1>
        <h1 style="padding-bottom:10px;">Platform rules are operated by E-mart mall, and the final interpretation rights belong to E-mart mall.</h1>
  `,

  "About_Top_Name": "About Us",
  "About_Center_Text1": "Company Overview",
  "About_Center_P1": "Established on July 12, 2018",
  "About_Center_P2": "Company Registered Capital: 12.51 million AED",
  "About_Center_P3": "Business Qualifications",
  "About_Center_P4": "One of the largest e-commerce service companies in the Middle East",
  "About_Center_P5": "1. Souq.com   7. Sharafdg.com",
  "About_Center_P6": "2. Ounass.ae ",
  "About_Center_P7": "3. Amazon",
  "About_Center_P8": "4. Groupon.ae ",
  "About_Center_P9": "5. Basharacare.com ",
  "About_Center_P10": "6. LetsTango.com ",
  "About_Center_P11": "7. Sharafdg.com",
  "About_Center_P12":
    "In 2020, 2021, and 2023, the best cooperative company in the Middle East. Affected by the pandemic and the changes of the big data era, online shopping has become an essential skill for today's working-class people. App platforms have sprung up like mushrooms, with popular and well-known ones including (Souq.com) (Ounass.ae) (Amazon) (Groupon.ae) (Basharacare.com), etc. Many online stores also reluctantly follow the trend and join app platforms, and the competition is increasingly fierce. In this big family of online shopping, gaining benefits and reputation requires quality sales and products. Quality is usually ensured, except for a few unscrupulous merchants. As the saying goes, modern markets are like ancient battlefields, where tactics and strategies abound. So how to navigate such a large market with thousands of merchants vying for a share? Thus, this platform was created. It’s worth noting some lesser-known aspects of e-commerce platforms and the trend towards virtual orders to increase product visibility and sales. After achieving high sales and good reputation, the process continues with virtual orders to improve sales further.",
  "About_Center_P13":
    "Our platform has identified this model and developed a group-buying platform. The operation mode of the group-buying platform uses cloud computing to transmit product purchase links from e-commerce platforms to the cloud computing database, which then sends product information to E-mart mall. Merchants receive virtual orders and process virtual shipments, thus improving the reputation and sales of their products on e-commerce platforms. The mode is well perfected. The platform is a third-party intelligent cloud control host matching order platform collaborating with 1. Souq.com 2. Ounass.ae 3. Amazon 4. Groupon.ae 5. Basharacare.com 6. LetsTango.com 7. Sharafdg.com and other online shopping platforms. Through intelligent cloud systems, merchant orders are matched with platform users, improving the merchant's transaction volume and reputation on various platforms. This improves the traditional complex order submission model, automating the process via remote cloud-controlled robots, making order matching more efficient and straightforward! The platform lists products needing increased exposure for group-buying. To avoid detection by online shopping platforms and ensure order authenticity, new users must complete their personal information and provide a real shipping address before group-buying. To enhance order success rates and avoid detection of fake orders, the platform optimizes order matching based on the user's IP and device type. All order matching is performed using intelligent cloud algorithms. Group-buying is available from 10:00 AM to 9:00 PM. (For any issues, please contact customer service!)",
  "About_Center_P14": "Platform Introduction",
  "About_Center_P15": "Dear Users.",
  "About_Center_P16":"This platform is a third-party intelligent cloud-controlled order matching platform, partnering with online shopping merchants such as 1. Souq.com, 2. Ounass.ae, 3. Amazon, 4. Groupon.ae, 5. Basharacare.com, 6. LetsTango.com, 7. Sharafdg.com, among others. The intelligent cloud system matches merchants' orders with platform users, thereby increasing the merchants' sales volume and credibility on major platforms. It improves upon the traditional, cumbersome process of placing online orders by using a remotely controlled computer robot to automatically simulate the manual order submission process, making order matching more efficient and simple! Every day, the platform features products from merchants that need greater exposure, offering them to platform users for group purchasing. To avoid detection by online shopping platforms and enhance the authenticity of orders, new users must complete their personal information and provide a real shipping address before participating in group buying. To effectively help merchants increase their order success rate and avoid the risk of fake orders, the platform optimizes order matching based on your current IP address and device model. All order matching is performed using intelligent cloud algorithms. The platform's recharge and group buying hours are from 10:00 AM to 9:00 PM. (If you have any questions, please click on customer service for assistance!)",

  "Order_TopName": "Orders",
  "Order_Tab_Text1": "All Orders",
  "Order_Tab_Text2": "Pending",
  "Order_Tab_Text3": "Frozen",
  "Order_Tab_Text4": "Completed",
  "Order_Tab_NullMsg": "Loading Complete",
  "Order_Tabs_Time": "Completion Time",
  "Order_Tabs_NotTime": "Not Completed",
  "Order_Tabs_Re": "Pending",
  "Order_Tabs_Com": "Completed",
  "Order_Tabs_Text1": "Unit Price",
  "Order_Tabs_Text2": "Order Quantity",
  "Order_Tabs_Text3": "Total",
  "Order_Tabs_Text4": "Order Commission",
  "Order_Tabs_Btn": "Submit Order",
  "Order_Tabs_Sun": "Submission Successful",

  "My_Top_Name": "My Account",
  "My_Deposit": "Deposit Amount",
  "My_Code": "Invitation Code",
  "My_Score": "Credit Score",
  "My_Text1": "Account Balance",
  "My_Text2": "Frozen Balance",
  "My_Tabs_Text1": "My Account",
  "My_Tabs_Text2": "Billing Details",
  "My_Tabs_Text3": "Member Center",
  "My_Tabs_Text4": "Team Report",
  "My_Tabs_Text5": "Invite Friends",
  "My_Tabs_Text6": "Select Language",

  "MyAccount_Top_Name": "My Account",
  "MyAccount_Text1": "Account Balance",
  "MyAccount_Tx": "Withdraw",
  "MyAccount_Task": "Task Income",
  "MyAccount_Team": "Team Income",
  "MyAccount_Task_Money": "Income from completing tasks",
  "MyAccount_Com": "Earn commissions by inviting friends",
  "MyAccount_Btn1": "Go to Complete",
  "MyAccount_Btn2": "Go to Invite",
  "MyAccount_FAQ": "Frequently Asked Questions",

  "SetTkPW_Top_Name": "Set Withdrawal Password",
  "SetTkPW_Name_Text": "Set Withdrawal Password",
  "SetTkPW_NewPassWord": "New Withdrawal Password*",
  "SetTkPW_OdlPassWord": "Confirm New Withdrawal Password*",
  "SetTkPW_Btn": "Confirm",
  "SetTkPW_New_P": "Please enter withdrawal password",
  "SetTkPW_New_O": "Please enter confirmation withdrawal password",

  "Tx_Top_Name": "Withdrawal",
  "Tx_TxMoney": "Withdrawal Amount",
  "Tx_BalMoney": "Account Balance",
  "Tx_Input_P": "Please enter withdrawal amount",
  "Tx_Money_Text": "Amount Received",
  "Tx_Bank_Card": "Withdrawal Bank Card",
  "Tx_Bank_New": "Add New",
  "Tx_Bank_Btn": "Change",
  "Tx_Bank_PassWord": "Withdrawal Password",
  "Tx_Money_Btn": "Confirm Withdrawal",

  "AddBank_Top_Name": "Bind Bank Card",
  "AddBank_Pop_Cancel": "Cancel",
  "AddBank_Pop_Confirm": "Confirm",
  "AddBank_tab_Name1": "Bank",
  "AddBank_tab_Name2": "Cardholder Name",
  "AddBank_tab_P2": "Please enter cardholder name",
  "AddBank_tab_Name3": "Bank Card Number",
  "AddBank_tab_P3": "Please enter bank card number",
  "AddBank_tab_Name4": "Withdrawal Password",
  "AddBank_tab_P4": "Please enter withdrawal password",
  "AddBank_add_Btn": "Confirm Add",

  "Bill_Top_Name": "Billing Details",
  "Bill_Tab_Text1": "Task Commission",
  "Bill_Tab_Text2": "Principal Refund",
  "Bill_Tab_Text3": "Reserved Orders",
  "Bill_Tab_Text4": "Task Commission",
  "Bill_prev": "Previous Page",
  "Bill_next": "Next Page",

  "Team_Top_Name": "Team Report",
  "Team_Tab_Text1": "Team Income",
  "Team_Tab_text2": "Number of Invitations",
  "Smg_Top_Name": "Messages",
  "Smg_Null_Text": "Loading Complete",
"Set_Top_Name": "Settings",
  "Set_Tab_Text1": "Login Password",
  "Set_Tab_Text2": "Withdrawal Password",
  "Set_Tab_Text3": "Profile Photo",
  "Set_Tab_Text4": "Username",
  "Set_Btn": "Logout",

  "SetLogin_LoginPS_Name": "Change Login Password",
  "SetLogin_Tab_TextName": "Change Login Password",
  "SetLogin_Tab_Text1": "Old Password*",
  "SetLogin_Tab_P1": "Please enter the old password",
  "SetLogin_Tab_Text2": "New Password*",
  "SetLogin_Tab_P2": "Please enter the new password",
  "SetLogin_Tab_Text3": "Confirm Password*",
  "SetLogin_Tab_P3": "Please enter the confirmation password",
  "SetLogin_Btn": "Confirm Change",

  "SetTxPS_Top_Name": "Change Withdrawal Password",
  "SetTx_Tab_TextName": "Change Withdrawal Password",
  "SetTx_Tab_Text1": "Current Withdrawal Password*",
  "SetTx_Tab_P1": "Please enter your current withdrawal password",
  "SetTx_Tab_Text2": "New Withdrawal Password*",
  "SetTx_Tab_P2": "Please enter the new withdrawal password",
  "SetTx_Tab_Text3": "Confirm New Withdrawal Password*",
  "SetTx_Tab_P3": "Please enter the confirmation withdrawal password",
  "SetTx_Btn": "Confirm",

  "SetName_Top": "Enter Username",
  "SetName_Btn": "Confirm Change",
  "SetOut_Text1": "Notice",
  "setOut_Text2": "Confirm logout",
  "setOut_btn1": "Cancel",
  "setOut_btn2": "Confirm",

  "LoginIndex_CutLanuage": "Select Language",
  "LoginIndex_Tab_Top": "Please log in or create your account",
  "LoginIndex_Btn1": "Login",
  "LoginIndex_Btn2": "Create Account",
  "LoginIndex_Tab_Btt": "About E-mart mall",

  "Longin_Top_Name": "Login",
  "Longin_Tab_Name": "Please log in to your account",
  "Longin_Tab_Text1": "Phone Number*",
  "Longin_Tab_P1": "Please enter your phone number",
  "Longin_Tab_Text2": "Password*",
  "Longin_Tab_P2": "Please enter your password",
  "Longin_Tab_Btm": "Forgot password?",
  "Longin_Btn": "Login",

  "SigninPage_Top_Name": "Create Account",
  "SigninPage_Tab_Name": "Create Account",
  "SigninPage_Tab_Text1": "Phone Number*",
  "SigninPage_Tab_P1": "Please enter your phone number",
  "SigninPage_Tab_Text2": "Login Password*",
  "SigninPage_Tab_P2": "Please enter the login password",
  "SigninPage_Tab_Text3": "Confirm Password*",
  "SigninPage_Tab_P3": "Please enter the confirmation password",
  "SigninPage_Tab_Text4": "Invitation Code (Optional)",
  "SigninPage_Tab_P4": "Please enter the invitation code",
  "SigninPage_Tab_Text5": "Registration Agreement",
  "SigninPage_Btn": "Confirm Registration",

  "MyPage_Msg_Copy": "Copied Successfully",
  "TxPage_Msg_Msg1": "Please enter the withdrawal amount",
  "TxPage_Msg_Msg2": "Please select a bank card",
  "TxPage_Msg_Msg3": "Please enter the withdrawal password",
  "TxPage_Msg_Msg4": "Withdrawal amount cannot exceed account balance",

  "SetTx_Msg1": "Password cannot be empty",
  "SetTx_Msg2": "Withdrawal password must contain at least 6 characters",
  "SetTx_Msg3": "New withdrawal password must contain at least 6 characters",
  "SetTx_Msg4": "New withdrawal password and confirmation password do not match",
  "SetTx_Msg5": "Modification Successful",

  "SetTk_Msg1": "Password cannot be empty",
  "SetTk_Msg2": "Confirmation password must match the new password",
  "SetTk_Msg3": "Password must contain 6 characters",
  "SetTk_Msg4": "Password does not match the login password",
  "SetTk_Msg5": "Withdrawal password set successfully",

  "SetPage_Msg1": "Please enter a username",
  "SetPage_Msg2": "Modification Successful",
  "SetPage_Msg3": "Logout Successful",

  "SetLoginPassWord_Msg1": "Password cannot be empty",
  "SetLoginPassWord_Msg2": "Incorrect password",
  "SetLoginPassWord_Msg3": "New password and confirmation password do not match",
  "SetLoginPassWord_Msg4": "Password must contain at least 6 characters",
  "SetLoginPassWord_Msg5": "Modification Successful",

  "MyAccount_Msg1": "Copied Successfully",

  "AddBank_Msg1": "Cardholder's name cannot be empty",
  "AddBank_Msg2": "Bank card number cannot be empty",
  "AddBank_Msg3": "Withdrawal password cannot be empty",
  "AddBank_Msg4": "Incorrect withdrawal password",
  "AddBank_Msg5": "Added Successfully",

  "SigninPage_Msg1": "Login account cannot be empty",
  "SigninPage_Msg2": "Login password cannot be empty",
  "SigninPage_Msg3": "Password and confirmation password do not match",
  "SigninPage_Msg4": "Password must be longer than 6 characters",
  "SigninPage_Msg5": "Please check the registration agreement",
  "SigninPage_Msg6": "Registration Successful",
  "SigninPage_Msg7": "Incorrect phone number, please re-enter",

  "LoginPage_Msg1": "Login account cannot be empty",
  "LoginPage_Msg2": "Login password cannot be empty",
  "LoginPage_Msg3": "Login Successful",

  "Bill_date": "Filter",
  "TxPage_Msg_Msg5": "Withdrawal Successful",

  "Bill_Tab_Msg1": "Completed",
  "Bill_Tab_Msg2": "Under Review",
  "Bill_Tab_Msg3": "Withdrawal Failed",

  "SelTxWay_Top_Name": "Withdrawal Method",
  "SelTxWay_Sel1": "Bank Card",
  "SelTxWay_Sel2": "Cryptocurrency",

  "TxSGX_Top_Name": "Withdrawal",
  "TxSGX_Input1": "Full Name",
  "TxSGX_Input2": "Exchange Address",

  "TxSGX_Smg1": "Please enter your name",
  "TxSGX_Smg2": "Please enter your address",
  "TxSGX_Smg3": "Please enter your name",
  "TxSGX_Smg4": "Please enter your address",

  "CzPage_TopName": "Recharge",
  "CzPage_I1": "Please enter the recharge address",
  "CzPage_Msg1": "Please ask customer service for the recharge address before recharging",
  "CzPage_I2": "Please enter the recharge amount",
  "CzPage_Btn": "Submit Recharge",

   "Terms_center_Text3": "Agency Cooperation",
  "Terms_center_Text4": "Agent Model",
  "Terms_center_Text5": "Platform users can become agents by recommending new users. Agents can receive additional dynamic profit-sharing rewards. Each profit-sharing amount will be transferred to your account and can be viewed in the account details! The first-level user reward is 33% of the commission from each order placed by the first-level user. The second-level user reward is 16% of the commission from each order placed by the second-level user. The third-level user reward is 8% of the commission from each order placed by the third-level user.",
  "Terms_center_Text6": "Kind Reminder: All commissions for agents' downline users and manufacturers' rewards are provided by the platform and do not affect the commissions of all agents and their downline!",
  "Terms_center_Text7": "Rules Explanation",
  "Terms_center_Text8": "The commission distribution for platform members is 0.32%-1.88% of the total order amount.",
  "Terms_center_Text9": "Dear User,",
  "Terms_center_Text10": "To prevent malicious activities such as money laundering or cash withdrawal, members must complete 60 orders to withdraw the full amount if the account balance reaches 200. Welfare orders cannot be canceled or replaced. After a successful withdrawal review, the transfer time to your account is (10:00-21:00), (other times, the withdrawal will be processed by 24:00 the next day). The exact time of arrival depends on the bank! To ensure the authenticity of merchant transaction volumes, all task orders are paid in advance. Orders must be submitted promptly after receiving the task order to avoid delays and submission issues that may affect credit scores.",
  "Terms_center_Text11": "Do not register and grab orders repeatedly using the same bank card information. A maximum of 2 accounts can be used on the same phone. Accounts exceeding 2 will be frozen once discovered. The system randomly matches high-commission welfare orders, which cannot be canceled or replaced. Using multiple personal accounts to grab orders will result in merchant store penalties, affecting merchant credit and causing sales orders to become void. The platform prohibits binding multiple accounts to one bank card. All part-time members should not use multiple personal accounts. Accounts with binding issues will have their funds frozen for 90 days and will be permanently banned."
};
