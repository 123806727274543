<template>
    <div class="AddBank left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0" style="position: fixed;z-index: 9999;">
            <div class="basis-1/6 place-content-center" @click="getGo()">
                <el-icon><ArrowLeftBold /></el-icon>
            </div>
            <div class="basis-4/5 flex items-center justify-center">{{ $t("Set_Top_Name") }}</div>
            <div class="basis-1/6 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[65px] px-5 text-sm">
            <router-link to="/SetLoginPassWrod">
                <div class="py-5 flex items-center justify-between cursor-pointer opacity-90">
                    <div>{{ $t('Set_Tab_Text1') }}</div>
                    <el-icon><ArrowRight /></el-icon>
                </div>
            </router-link>
            <el-divider style="margin: 0 !important;"/>

            <div class="py-5 flex items-center justify-between cursor-pointer opacity-90" @click="GoToPage()">
                <div>{{ $t('Set_Tab_Text2') }}</div>
                <el-icon><ArrowRight /></el-icon>
            </div>

            <el-divider style="margin: 0 !important;"/>

            <el-upload
                class="avatar-uploader"
                action="http://localhost:8080/Img/Uploader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
                <div class="py-5 flex items-center justify-between cursor-pointer opacity-90">
                <div>{{ $t('Set_Tab_Text3') }}</div>
                <el-icon><ArrowRight /></el-icon>
                </div>
            </el-upload>
            
            <el-divider style="margin: 0 !important;"/>
            <div class="py-5 flex items-center justify-between cursor-pointer opacity-90" @click="RowPopFlag = true">
                <div>{{ $t('Set_Tab_Text4') }}</div>
                <el-icon><ArrowRight /></el-icon>
            </div>
            <el-divider style="margin: 0 !important;"/>

            <div class="mt-10 flex place-content-center bg-theme p-3 text-sm text-[white]" @click="PopLogOutFlag = true">
                <div>{{ $t('Set_Btn') }}</div>
            </div>
        </div>

        <Transition name="Pop">
            <div v-show="RowPopFlag" class="rounded-md fixed w-full md:w-[46rem] top-[25%] z-[2024] p-3">
                <div class="bg-white p-4 text-black flex flex-col rounded-[20px]" style="position: relative;">
                    <div class="flex">
                        <div class="basis-1/12"></div>
                        <div class="basis-10/12 text-center">{{ $t('SetName_Top') }}</div>
                        <div class="basis-1/12 text-right"><el-icon @click="RowPopFlag = false"><Close /></el-icon></div>
                    </div>
                    <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 rounded-md" style="background: #f8f9fb;">
                        <input v-model="Name" style="width: 100%;outline:none;background: #f8f9fb;">
                    </div>
                    <div class="bg-theme text-center text-white rounded-md py-4 mt-5" @click="createName">
                        {{ $t('SetName_Btn') }}
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 遮挡窗 -->
        <Transition name="Pop">
            <div v-show="RowPopFlag" class="overlay2">

            </div>
        </Transition>

        <Transition name="Pop">
            <div v-show="PopLogOutFlag" class="rounded-md fixed w-full md:w-[46rem] top-[25%] z-[2024] p-3">
                <div class="bg-white p-4 text-black flex flex-col rounded-[20px]" style="position: relative;">
                    <div class="font-bold mt-4 text-center">{{$t('SetOut_Text1')}}</div>
                    <div class="mt-3 text-center">{{$t('setOut_Text2')}}</div>
                    <div class="flex mt-4 text-center justify-center py-3">
                        <div class="basis-1/2" @click="PopLogOutFlag = false">{{$t('setOut_btn1')}}</div>
                        <div class="basis-1/2 text-[#ed222e]" @click="LogOut">{{$t('setOut_btn2')}}</div>
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 遮挡窗 -->
        <Transition name="Pop">
            <div v-show="PopLogOutFlag" class="overlay2">

            </div>
        </Transition>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { ArrowRight,Close } from '@element-plus/icons-vue';
import { UploadProps } from 'element-plus'
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const Name = ref('');
const RowPopFlag = ref(false);
const PopLogOutFlag = ref(false);
const router = useRouter();
const imageUrl = ref('')
const { t } = useI18n()

function getGo() {
    router.go(-1)
}

const handleAvatarSuccess = (response, uploadFile) => {
  imageUrl.value = URL.createObjectURL(uploadFile.raw);
};

// 修改用户名
async function createName(){
    if(Name.value == '' || Name.value == null){
        ElMessage.error(t('SetPage_Msg1'));
    }else{
        await store.dispatch("UpUserName", { data: { username:Name.value } })

        ElMessage({
            message: t('SetPage_Msg2'),
            type: 'success',
        })
        setTimeout(() => {
            router.push('/MyPage')
        }, 2000); 
    }
}

const store = useStore()

async function LogOut(){
    
    await store.dispatch('OutLogin')
    store.commit("UserStore_SETDATA_IS_NULL");
    store.commit("OrderStore_SETDATA_IS_NULL");
    localStorage.clear()

    ElMessage({
        message: t('SetPage_Msg3'),
        type: 'success',
    })
    setTimeout(() => {
        router.push('/')
    }, 1000); 
}

// 修改提现密码跳转
function GoToPage(){
    if (store.getters.USER_DATA.withdrawal_password_status == 0){
        router.push('/SetTkPassWrod')
    }else{
        router.push('/SetTxPassWrod')
    }
}
</script>