import Server from "@/Axios";

// 获取抢单信息
async function GetOrderData(){
    return await Server.post("/order/generate");
}

// 获取订单信息
async function GetOrderList() {
    return await Server.get("/order");
}

// 提交订单
async function SubmitOrder(data) {
    await Server.put("/order/solve",data);
}
export default {
  GetOrderData,
  GetOrderList,
  SubmitOrder,
};
