<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;background: #f8f9fb">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("TasksIndex_Top_Name") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="van-image h-[100px]" style="position: absolute;">
            <img class="w-full z-50" src="@/assets/Img/vip_bg.svg" lazy="loaded">
        </div>

        <div class="top-[68px] TasksVIP m-6 left-0 z-50" style="padding-top: 70px;">
            <div class="w-full flex h-[185px] md:h-[345px] Card" style="overflow: hidden;">
                <div class="text-white z-50 h-full"
                    style="padding-top: 2rem;padding-left: clamp(0.625rem, 0.625rem + 1.83vw, 3rem);">
                    <div class="leading-5 font-serif font-bold flex items-center tracking-widest">
                        <div class="pr-3 vip-img">
                            <img src="@/assets/Img/VIP.svg" class="w-[23px] h-[23px]">
                        </div>
                        <div class="vip-info">
                            {{ $store.getters.USER_DATA.member_level_name }}
                        </div>
                    </div>

                    <div class="info-order"
                        style="padding-top: clamp(1rem, 2rem + 2.67vw, 4rem);font-size: clamp(0.4rem, 0.4rem + 1.59vw, 2rem);">
                        {{ $t('Tasks_Card_Order') }} <span class="ml-1 text-theme-red">{{
                            $store.getters.USER_DATA.member_level_max_order_quantity }}</span>
                    </div>
                    <div class="infor-ration"
                        style="padding-top: clamp(0.625rem, 0.625rem + 1.83vw, 2rem);font-size: clamp(0.4rem, 0.4rem + 1.59vw, 2rem);">
                        {{ $t('Tasks_Card_Ration') }} <span class="ml-1 text-theme-red">{{
                            $store.getters.USER_DATA.number_direct_referrals }}%</span>
                    </div>
                </div>

                <div class="z-50 pl-16 text-[#aaaaaa]" style="font-size: clamp(0.3rem, 0.3rem + 1.59vw, 1.3rem)">
                    <div class="flex pt-1 text-right vip-tilel5" :style="{color : VIPIndex == 'VIP 5' ? 'white':''}">
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 5).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 5).price }}</div>
                        </div>
                        <div class="pl-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 5'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 5'">
                        </div>
                    </div>

                    <div class="flex text-left pl-5 vip-tilel4" :style="{color : VIPIndex == 'VIP 4' ? 'white':''}">
                        <div class="pr-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 4'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 4'">
                        </div>
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 4).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 4).price }}</div>
                        </div>
                    </div>

                    <div class="flex pl-2 text-right vip-tilel3" :style="{color : VIPIndex == 'VIP 3' ? 'white':''}">
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 3).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 3).price }}</div>
                        </div>
                        <div class="pl-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 3'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 3'">
                        </div>
                    </div>

                    <div class="flex pl-5 text-left vip-tilel2" :style="{color : VIPIndex == 'VIP 2' ? 'white':''}">
                        <div class="pr-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 2'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 2'">
                        </div>
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 2).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 2).price }}</div>
                        </div>
                    </div>

                    <div class="flex pl-10 text-right vip-tilel1" :style="{color : VIPIndex == 'VIP 1' ? 'white':''}">
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 1).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 1).price }}</div>
                        </div>
                        <div class="pl-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 1'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 1'">
                        </div>
                    </div>
                </div>

                <div
                    style="padding-top: clamp(0.625rem, 0.625rem + 1.83vw, 2rem);padding-left: clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                    <el-icon color="white" size="25">
                        <QuestionFilled />
                    </el-icon>
                </div>
            </div>
        </div>

        <div class="bg-white m-6 z-50">
            <div class="p-3">{{ $t('TasksIndex_MB') }}</div>
            <div>
                <div style="flex:1;" class="mt-6 pb-10 text-sm">
                    <div class="flex grid-cols-3">
                        <div class="place-content-center">
                            <div><img src="@/assets/Img/level_1.svg"></div>
                        </div>

                        <div style="flex:1;">
                            <div class="ml-6 grow">
                                <div class="text-sm font-medium text-theme-gray-light">
                                    <span style="color: red" v-if="VIP_ID == 1">{{ $t('TasksIndex_Supassed') }}</span>
                                    <span style="color: red" v-if="VIP_ID > 1">{{ $t('TasksIndex_Supassed_yi') }}</span>
                                    <span v-if="VIP_ID < 1">{{ $t('TasksIndex_Supassed_not') }}</span>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="place-content-center" style="width: 33px; height: 19px;">
                                        <img src="@/assets/Img/level_1_str.svg" width="35px">
                                    </div>
                                    <div class="ml-2 text-sm text-[#0000004D]" style="flex:1;">
                                        {{ $t('TasksIndex_Deposit') }} {{ VIP_DATA.find((item) => item.id == 1).price }}
                                    </div>
                                </div>

                                <div class="flex mt-4">
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 1).commission_rate }}%</div>
                                        <div>{{ $t('TasksIndex_CR') }}</div>
                                    </div>
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 1).max_order_quantity }}</div>
                                        <div>{{ $t('TasksIndex_OQ') }}</div>
                                    </div>
                                </div>
                            </div>

                            <el-divider />
                        </div>
                    </div>

                    <div class="flex">
                        <div class="place-content-center">
                            <div><img src="@/assets/Img/level_2.svg"></div>
                        </div>

                        <div style="flex:1;">
                            <div class="ml-6 grow">
                                <div class="text-sm font-medium text-theme-gray-light">
                                    <span style="color: red" v-if="VIP_ID == 2">{{ $t('TasksIndex_Supassed') }}</span>
                                    <span style="color: red" v-if="VIP_ID > 2">{{ $t('TasksIndex_Supassed_yi') }}</span>
                                    <span v-if="VIP_ID < 2">{{ $t('TasksIndex_Supassed_not') }}</span>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="place-content-center" style="width: 33px; height: 19px;">
                                        <img src="@/assets/Img/level_2_str.svg" width="35px">
                                    </div>
                                    <div class="ml-2 text-sm text-[#0000004D]" style="flex:1;">
                                        {{ $t('TasksIndex_Deposit') }} {{ VIP_DATA.find((item) => item.id == 2).price }}
                                    </div>
                                </div>

                                <div class="flex mt-4">
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 2).commission_rate }}%</div>
                                        <div>{{ $t('TasksIndex_CR') }}</div>
                                    </div>
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 2).max_order_quantity }}</div>
                                        <div>{{ $t('TasksIndex_OQ') }}</div>
                                    </div>
                                </div>
                            </div>

                            <el-divider />
                        </div>
                    </div>

                    <div class="flex">
                        <div class="place-content-center">
                            <div><img src="@/assets/Img/level_3.svg"></div>
                        </div>

                        <div style="flex:1;">
                            <div class="ml-6 grow">
                                <div class="text-sm font-medium text-theme-gray-light">
                                    <span style="color: red" v-if="VIP_ID == 3">{{ $t('TasksIndex_Supassed') }}</span>
                                    <span style="color: red" v-if="VIP_ID > 3">{{ $t('TasksIndex_Supassed_yi') }}</span>
                                    <span v-if="VIP_ID < 3">{{ $t('TasksIndex_Supassed_not') }}</span>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="place-content-center" style="width: 33px; height: 19px;">
                                        <img src="@/assets/Img/level_3_str.svg" width="35px">
                                    </div>
                                    <div class="ml-2 text-sm text-[#0000004D]" style="flex:1;">
                                        {{ $t('TasksIndex_Deposit') }} {{ VIP_DATA.find((item) => item.id == 3).price }}
                                    </div>
                                </div>

                                <div class="flex mt-4">
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 3).commission_rate }}%</div>
                                        <div>{{ $t('TasksIndex_CR') }}</div>
                                    </div>
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 3).max_order_quantity }}</div>
                                        <div>{{ $t('TasksIndex_OQ') }}</div>
                                    </div>
                                </div>
                            </div>

                            <el-divider />
                        </div>
                    </div>

                    <div class="flex">
                        <div class="place-content-center">
                            <div><img src="@/assets/Img/level_4.svg"></div>
                        </div>

                        <div style="flex:1;">
                            <div class="ml-6 grow">
                                <div class="text-sm font-medium text-theme-gray-light">
                                    <span style="color: red" v-if="VIP_ID == 4">{{ $t('TasksIndex_Supassed') }}</span>
                                    <span style="color: red" v-if="VIP_ID > 4">{{ $t('TasksIndex_Supassed_yi') }}</span>
                                    <span v-if="VIP_ID < 4">{{ $t('TasksIndex_Supassed_not') }}</span>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="place-content-center" style="width: 33px; height: 19px;">
                                        <img src="@/assets/Img/level_4_str.svg" width="35px">
                                    </div>
                                    <div class="ml-2 text-sm text-[#0000004D]" style="flex:1;">
                                        {{ $t('TasksIndex_Deposit') }} {{ VIP_DATA.find((item) => item.id == 4).price }}
                                    </div>
                                </div>

                                <div class="flex mt-4">
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 4).commission_rate }}%</div>
                                        <div>{{ $t('TasksIndex_CR') }}</div>
                                    </div>
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 4).max_order_quantity }}</div>
                                        <div>{{ $t('TasksIndex_OQ') }}</div>
                                    </div>
                                </div>
                            </div>

                            <el-divider />
                        </div>
                    </div>

                    <div class="flex">
                        <div class="place-content-center">
                            <div><img src="@/assets/Img/level_5.svg"></div>
                        </div>

                        <div style="flex:1;">
                            <div class="ml-6 grow">
                                <div class="text-sm font-medium text-theme-gray-light">
                                    <span style="color: red" v-if="VIP_ID == 5">{{ $t('TasksIndex_Supassed') }}</span>
                                    <span style="color: red" v-if="VIP_ID > 5">{{ $t('TasksIndex_Supassed_yi') }}</span>
                                    <span v-if="VIP_ID < 5">{{ $t('TasksIndex_Supassed_not') }}</span>
                                </div>
                                <div class="mt-2 flex">
                                    <div class="place-content-center" style="width: 33px; height: 19px;">
                                        <img src="@/assets/Img/level_5_str.svg" width="35px">
                                    </div>
                                    <div class="ml-2 text-sm text-[#0000004D]" style="flex:1;">
                                        {{ $t('TasksIndex_Deposit') }} {{ VIP_DATA.find((item) => item.id == 5).price }}
                                    </div>
                                </div>

                                <div class="flex mt-4">
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 5).commission_rate }}%</div>
                                        <div>{{ $t('TasksIndex_CR') }}</div>
                                    </div>
                                    <div class="basis-1/2">
                                        <div class="font-medium">{{ VIP_DATA.find((item) => item.id == 5).max_order_quantity }}</div>
                                        <div>{{ $t('TasksIndex_OQ') }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <router-link to="/ServerPage">
            <div class="Home-Btn mx-8 z-50">
                <div class="bg-theme bg-theme p-3 flex place-content-center">
                    <span class="text-sm text-[white]">{{ $t('TasksIndex_Btn') }}</span>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const VIP_DATA = computed(() => store.getters.VIP_DATA)
const VIPIndex = computed(() => store.getters.USER_DATA.member_level_name)
const VIP_ID = computed(() => store.getters.USER_DATA.member_level_id)
const store = useStore();

onMounted(async () => {
    await store.dispatch('GetVipData')
})
function getGo() {
    router.go(-1)
}
</script>