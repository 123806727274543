<template>
    <div class="relative left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl OrderPage">
        <div
            class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] top-0">
            <div class="basis-1/3"></div>
            <div class="basis-1/3 flex items-center justify-center">{{ $t("My_Top_Name") }}</div>
            <div class="basis-1/3 flex items-center justify-end p-2">
                <router-link to="/MsgPage">
                    <img src="@/assets/Img/msg.svg" style="width: 20px; height: 20px;margin-right: 10px;">
                </router-link>
                <router-link to="/SetPage">
                    <img src="@/assets/Img/set.svg" style="width: 20px; height: 20px;">
                </router-link>
            </div>
        </div>

        <div class="mt-[65px] py-3 px-[24px]">
            <div class="flex justify-between items-center">
                <div
                    class="bg-theme w-[72px] h-[72px] flex place-content-center items-center rounded-[50%] overflow-hidden">
                    <img src="@/assets/Img/MyPage.png" class="w-[40px] h-[40px]">
                </div>
                <router-link to="/CzPage">
                    <div
                        class="bg-[#fbeadc] text-[#fe7c02] h-[32px] px-[20px] flex place-content-center items-center rounded-[20px]">
                        {{ $t('My_Deposit') }}
                    </div>
                </router-link>
            </div>

            <div class="mt-5 text-xl font-semibold">{{ $store.getters.USER_DATA.username ?
                $store.getters.USER_DATA.username : $store.getters.USER_DATA.phone }}</div>

            <div class="mt-0.5">{{ $t('My_Code') }}: {{ $store.getters.USER_DATA.invitation_code }}</div>

            <div class="mt-0.5">{{ $t('My_Score') }}: {{ $store.getters.USER_DATA.score }}</div>

            <div class="rounded-md overflow-hidden p-4 mx-4 bg-white mt-5 mx-0 flex justify-center">
                <div style="width: 50%;">
                    <div class="text-center">{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.balance }}</div>
                    <div class="text-center">{{ $t('My_Text1') }}</div>
                </div>
                <el-divider direction="vertical" style="height: 45px;" />
                <div style="width: 50%;">
                    <div class="text-center">{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.frozen_amount }}</div>
                    <div class="text-center">{{ $t('My_Text2') }}</div>
                </div>
            </div>
        </div>

        <div class="mt-5 px-5 bg-white text-sm">
            <router-link to="/Account">
                <div class="py-5 flex items-center justify-between cursor-pointer opacity-90">
                    <div>{{ $t('My_Tabs_Text1') }}</div>
                    <el-icon>
                        <ArrowRight />
                    </el-icon>
                </div>
            </router-link>
            <el-divider style="margin: 0 !important;" />
            <router-link to="/Bill">
                <div class="py-5 flex items-center justify-between  cursor-pointer opacity-90">
                    <div>{{ $t('My_Tabs_Text2') }}</div>
                    <el-icon>
                        <ArrowRight />
                    </el-icon>
                </div>
            </router-link>
            <el-divider style="margin: 0 !important;" />
            <router-link to="/TasksIndex">
                <div class="py-5 flex items-center justify-between  cursor-pointer opacity-90">
                    <div>{{ $t('My_Tabs_Text3') }}</div>
                    <el-icon>
                        <ArrowRight />
                    </el-icon>
                </div>
            </router-link>
            <el-divider style="margin: 0 !important;" />
            <router-link to="/TeamPage">
                <div class="py-5 flex items-center justify-between cursor-pointer opacity-90">
                    <div>{{ $t('My_Tabs_Text4') }}</div>
                    <el-icon>
                        <ArrowRight />
                    </el-icon>
                </div>
            </router-link>
            <el-divider style="margin: 0 !important;" />
            <div class="py-5 flex items-center justify-between cursor-pointer opacity-90" @click="RowPopFlag = true">
                <div>{{ $t('My_Tabs_Text5') }}</div>
                <el-icon>
                    <ArrowRight />
                </el-icon>
            </div>
            <el-divider style="margin: 0 !important;" />
            <div class="py-5 flex items-center justify-between cursor-pointer opacity-90" @click="drawer = true">
                <div>{{ $t('My_Tabs_Text6') }}</div>
                <el-icon>
                    <ArrowRight />
                </el-icon>
            </div>
            <el-divider style="margin: 0 !important;padding-bottom: 56px;" />
        </div>

        <div
            class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] bottom-0 justify-around">
            <router-link to="/">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn1') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/OrderPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn2') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/TasksPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn3') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/ServerPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn4') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/MyPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn5') }}</div>
                    <div style="display: flex;justify-content: center;">
                        <img src="@/assets/Img/MyPage.png" style="height: 20px;width: 16px;">
                    </div>
                </div>
            </router-link>

        </div>

        <Transition name="Pop">
            <div v-show="RowPopFlag" class="rounded-md fixed w-full md:w-[46rem] top-[15%] z-[2024] p-3">
                <div class="bg-white p-4 text-black flex flex-col rounded-[20px]" style="position: relative;">

                    <div style="text-align: right;" @click="RowPopFlag = false">
                        <el-icon>
                            <Close color="#c8c9cc" />
                        </el-icon>
                    </div>

                    <div class="text-[#FF5D02] text-xl font-bold tracking-wider">
                        {{ $t('HomePage_Pop_Top1') }}
                    </div>

                    <div class="text-2xl">
                        {{ $t('HomePage_Pop_Top2') }}
                    </div>

                    <div class="my-5 w-2/3 mx-auto">
                        <img src="@/assets/Img/share_hand.png" alt="">
                    </div>

                    <div class="text-sm text-theme-gray-light py-4 text-center text-[#666666]">
                        {{ $t('HomePage_Pop_Top3') }}:{{ Coding }}
                    </div>

                    <div class="bg-theme p-3 rounded-[50px]" @click="Copy">
                        <div class="text-white text-center">{{ $t('HomePage_Pop_Btn') }}</div>
                    </div>

                    <div style="position: absolute;top: 0;left: 0;">
                        <img src="@/assets/Img/bg_top.svg">
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 遮挡窗 -->
        <Transition name="Pop">
            <div v-show="RowPopFlag" class="overlay2">

            </div>
        </Transition>

        <div>
            <el-drawer v-model="drawer" :with-header="false" direction="btt"
                class="custom-drawer left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl">
                <div class="flex justify-between mb-4" @click="drawer = false">
                    <div>{{ $t("HomePage_Pop_Name") }}</div>
                    <el-icon>
                        <Close color="#c8c9cc" />
                    </el-icon>
                </div>
                <div>
                    <div v-for="(data,index) in lanArr" :key="index" class="py-3">
                        <div class="flex items-center justify-between" @click="setLanguage(data.v)">
                            <div>
                                {{ data.name }}
                            </div>
                            <el-icon>
                                <ArrowRight />
                            </el-icon>
                        </div>
                        <el-divider style="margin:10px 0 !important" />
                    </div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script setup>
import { ArrowRight,Close } from '@element-plus/icons-vue'
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { locale } = useI18n();
const { t } = useI18n();
const RowPopFlag = ref(false);
const drawer = ref(false);
const Coding = ref('4890830');
const lanArr = ref([
    { "name": "اللغة العربية", "v": "ar-eg" },
    { "name": "English", "v": "en-us" },
    { "name": "中文简体", "v": "zh-cn" },
    { "name": "中文繁体", "v": "zh-tw" },
])

onMounted(async() => {
    await store.dispatch("GetUserData");
})

function Copy(){
    navigator.clipboard.writeText(Coding.value)
    ElMessage({
        message: t('MyPage_Msg_Copy'),
        type: 'success',
        plain: true,
    })
}

function setLanguage(v){
    store.commit('SetLanguage', v);
    locale.value = v;
    drawer.value = false
}
</script>