<template>
    <div class="relative left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl OrderPage" style="padding-bottom: 80px;">
        <div
            class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] top-0">
            <div class="basis-1/3"></div>
            <div class="basis-1/3 flex items-center justify-center">{{ $t("Order_TopName") }}</div>
            <div class="basis-1/3 flex items-center justify-end p-2">
                <router-link to="/MsgPage">
                    <img src="@/assets/Img/msg.svg" style="width: 20px; height: 20px;">
                </router-link>
            </div>
        </div>

        <div class="mt-[58px] px-3 pb-3">
            <div class="flex">
                <div class="mr-5" :style="{color: Index == 'All' ? '#ed222e' : '#999999'}" style="cursor: pointer"
                    @click="getOrderArr('All')"><span>{{ $t('Order_Tab_Text1') }}</span></div>
                <div class="mr-5" :style="{color: Index == 'Wait' ? '#ed222e' : '#999999'}" style="cursor: pointer"
                    @click="getOrderArr('Wait')"><span>{{ $t('Order_Tab_Text2') }}</span></div>
                <div class="mr-5" :style="{color: Index == 'Frozen' ? '#ed222e' : '#999999'}" style="cursor: pointer"
                    @click="getOrderArr('Frozen')"><span>{{ $t('Order_Tab_Text3') }}</span></div>
                <div class="mr-5" :style="{color: Index == 'Completed' ? '#ed222e' : '#999999'}" style="cursor: pointer"
                    @click="getOrderArr('Completed')"><span>{{ $t('Order_Tab_Text4') }}</span></div>
            </div>
        </div>

        <div class="grow">
            <el-divider class="pb-3 " style="margin: 0 !important;border-top:1px solid #f6d0d4 !important;" />

            <div v-if="OrderDataArr.length == 0" class="text-center text-sm text-[#999999]">
                {{ $t('Order_Tab_NullMsg') }}
            </div>

            <div v-if="OrderDataArr.length != 0">
                <div v-for="(Data,Index) in OrderDataArr" :key="Index" class="p-3">
                    <div class="bg-white rounded-[10px] text-xs p-3">
                        <div class="flex justify-between">
                            <div class="text-[#999999]">
                                <span class="text-black">{{ Data.complete_time == null ? $t('Order_Tabs_NotTime') :
                                    $t('Order_Tabs_Time') + ':' }}</span>
                                {{ Data.complete_time }}
                            </div>

                            <div class="text-black">
                                <!-- 完成 5 -->
                                <div v-if="Data.status_id == 5" class="flex" style="display: flex;align-items: center;">
                                    <el-icon color="#02b96c" size="18">
                                        <SuccessFilled />
                                    </el-icon> {{ $t('Order_Tab_Text4') }}
                                </div>
                                <!-- 冻结 10 -->
                                <div v-if="Data.status_id == 10" class="flex">
                                    <el-icon color="#37a3f0" size="18">
                                        <CircleCloseFilled />
                                    </el-icon> {{ $t('Order_Tab_Text3') }}
                                </div>
                                <!-- 待办 2 -->
                                <div v-if="Data.status_id == 2" class="flex">
                                    <el-icon color="#ff5d02" size="18">
                                        <WarningFilled />
                                    </el-icon> {{ $t('Order_Tab_Text2') }}
                                </div>
                            </div>
                        </div>

                        <el-divider style="border-top:1px solid#f6f7f7 !important;margin: 16px 0 16px 0 !important;" />

                        <div class="w-[60px] h-[60px] overflow-hidden flex w-full">
                            <img :src="Data.product_logo" class="mr-3"><span class="text-black">
                                {{ Data.product_title }}
                            </span>
                        </div>

                        <div class="mt-3 flex items-center justify-between">
                            <div>{{ $t('Order_Tabs_Text1') }}</div>
                            <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ Data.product_price }}</div>
                        </div>
                        <div class="mt-3 flex items-center justify-between">
                            <div>{{ $t('Order_Tabs_Text2') }}</div>
                            <div>{{ Data.quantity }}</div>
                        </div>
                        <div class="mt-3 flex items-center justify-between">
                            <div>{{ $t('Order_Tabs_Text3') }}</div>
                            <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ Data.amount }}</div>
                        </div>
                        <div class="mt-3 flex items-center justify-between text-[#ffa002]">
                            <div>{{ $t('Order_Tabs_Text4') }}</div>
                            <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ Data.total_commission }}</div>
                        </div>

                        <div v-if="Data.status_id == 2"
                            class="bg-[#ed222e] text-[white] text-center p-3 mt-6 rounded-[20px]" @click="submit(Data)">
                            {{ $t('Order_Tabs_Btn') }}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div
            class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] bottom-0 justify-around">
            <router-link to="/">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn1') }}</div>
                    <div class="flex justify-center"></div>
                </div>
            </router-link>

            <router-link to="/OrderPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn2') }}</div>
                    <div class="flex justify-center">
                        <img src="@/assets/Img/order.png" style="height: 20px;width: 16px;">
                    </div>
                </div>
            </router-link>

            <router-link to="/TasksPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn3') }}</div>
                    <div class="flex justify-center"></div>
                </div>
            </router-link>

            <router-link to="/ServerPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn4') }}</div>
                    <div class="flex justify-center"></div>
                </div>
            </router-link>

            <router-link to="/MyPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn5') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import { data } from 'autoprefixer';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { SuccessFilled, CircleCloseFilled, WarningFilled } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n';
import { ElMessage } from 'element-plus';

const Index = ref('All');
const store = useStore();
const OrderDataArr = ref([]);

onMounted(async () => {
    await getOrderArr('All')
})

async function getOrderArr(v){
    await store.dispatch('GetOrderList')
    if (v == 'All'){
        OrderDataArr.value = store.getters.GET_ORDER_ALL;
    } else if (v == 'Wait'){
        OrderDataArr.value = store.getters.GET_ORDER_DB;
    } else if (v == 'Frozen'){
        OrderDataArr.value = store.getters.GET_ORDER_DJ;
    } else if (v == 'Completed'){
        OrderDataArr.value = store.getters.GET_ORDER_WC;
    }
    Index.value = v
}

const { t } = useI18n();

// 提交订单
async function submit(data){
    store.dispatch('SubmitOrder', { data: { "order_id": data.id, "rating": data.rating, "review": data.review } })
    ElMessage({
        message: t('Order_Tabs_Sun'),
        type: 'success',
        plain: true,
    })
    window.location.reload();
}
</script>
