<template>
    <div class="SetTkPassWord left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0" style="position: fixed;z-index: 9999;">
            <div class="basis-1/6 place-content-center" @click="getGo()">
                <el-icon><ArrowLeftBold /></el-icon>
            </div>
            <div class="basis-4/5 flex items-center justify-center">{{ $t("Tx_Top_Name") }}</div>
            <div class="basis-1/6 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[65px] text-sm">
            <div class="mt-4 text-xs flex items-center px-5 pt-5">
                {{ $t('Tx_TxMoney') }} <div class="text-[#666666] ml-3">{{ $t('Tx_BalMoney') }}:{{ $store.getters.USER_DATA.currency_symbol }} {{ $setMath(Money) }}</div>
            </div>

            <div class="px-5 mt-4">
                <div class="px-4 py-3 p-4 border-[1px] border-slate-200 bg-white rounded-md">
                    <input  v-model="TxMoney" :placeholder="$t('Tx_Input_P')" style="width: 100%;outline:none" @input="setMath">
                </div>
                <div class="mt-2 text-xs text-[#666]">
                    {{ $t('Tx_Money_Text') }}: {{ $store.getters.USER_DATA.currency_symbol }} {{ TxMoney == '' ? 0 : TxMoney }}
                </div>
            </div>

            <div class="mt-5 text-xs px-5">
                <div>
                    {{ $t('Tx_Bank_Card') }} <router-link to="/AddBank"><span class="p-2 cursor-pointer text-[#1B91FF] text-sm">{{ $t('Tx_Bank_New') }}</span></router-link>
                </div>
                <div class="mt-4 px-2 py-3 border-[1px] border-slate-200 bg-white rounded-md flex" @click="drawer = true">
                    <div class="basis-10/12 content-center text-[14px] font-bold">{{ Bank_Card }}</div>
                    <div class="basis-2/12 text-[#1B91FF] text-sm leading-6">{{ $t('Tx_Bank_Btn') }}</div>
                </div>
            </div>

            <div class="mt-5 text-xs px-5">
                <div>
                    {{ $t('Tx_Bank_PassWord') }}
                </div>
                <div class="mt-4 px-3 py-4 border-[1px] border-slate-200 bg-white rounded-md flex">
                    <input type="password" v-model="Card_PassWord" :placeholder="$t('SetTkPW_New_P')" style="width: 100%;outline:none">
                </div>
            </div>

            <div class="text-white p-5">
                <div class="bg-theme p-3 text-center rounded-[50px]" @click="submit">{{ $t('Tx_Money_Btn') }}</div>
            </div>
        </div>

        <el-drawer v-model="drawer" :with-header="false" direction="btt" class="custom-drawer left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl">
            <div>
                <van-picker
                    title=""
                    :confirm-button-text="$t('AddBank_Pop_Confirm')"
                    :cancel-button-text="$t('AddBank_Pop_Cancel')"
                    :columns="columns"
                    @confirm="onConfirm"
                    />
            </div>
        </el-drawer>
    </div>

</template>

<script setup>
import { useRouter } from 'vue-router';
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { computed, onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import  { useI18n }  from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const  { t } = useI18n();
const router = useRouter();
const drawer = ref(false)

const TxMoney = ref('');
const Card_PassWord = ref('');
const Money = computed(() => store.getters.USER_DATA.balance);

const columns = ref()
const Bank_Card = ref();
const Bank_ID = ref()

onMounted(async() => {
    const ArrCord = await store.dispatch('GetUserBank_Card');
    
    if(ArrCord.length > 0){
        columns.value = ArrCord;
        Bank_Card.value = columns.value[0].text;
        Bank_ID.value = columns.value[0].value
    }
})

function getGo() {
    router.go('-1')
}

// 转换千分制
function setMath(v){
    let formattedValue = TxMoney.value.replace(/[^\d]/g, '');
    
    // 添加千分位逗号
    let parts = [];
    for (let i = formattedValue.length - 1, j = 1; i >= 0; i--, j++) {
        parts.unshift(formattedValue[i]);
        if (j % 3 === 0 && i !== 0) parts.unshift(',');
    }
    TxMoney.value = parts.join('')
}

async function submit(){
    if(TxMoney.value == '' || TxMoney.value == null){
        ElMessage.error(t('TxPage_Msg_Msg1'))
    }else if(Bank_Card.value == ''){
        ElMessage.error(t('TxPage_Msg_Msg2'))
    }else if(Card_PassWord.value == '' || Card_PassWord.value == null){
        ElMessage.error(t('TxPage_Msg_Msg3'))
    }else if(Card_PassWord.value.length < 6){
        ElMessage.error(t('SetTx_Msg2'))
    }else if(parseFloat(TxMoney.value.replace(/,/g, '')) > parseFloat(Money.value.replace(/,/g, ''))){
        ElMessage.error(t('TxPage_Msg_Msg4'))
    }else{
        const data = {
            "amount": parseFloat(TxMoney.value.replace(/,/g, '')),
            "bank_card_id": Bank_ID.value,
            "withdraw_password": Card_PassWord.value
        }
        
        await store.dispatch('UserTx',{data: data})
        TxMoney.value = '';
        Card_PassWord.value = '';
        ElMessage({
            message: t('TxPage_Msg_Msg5'),
            type: 'success',
        })



        // setTimeout(() => {
        //     router.go(-1)
        // }, 1000)
    }
}

function onConfirm(v){
    Bank_Card.value = v.selectedOptions[0].text;
    Bank_ID.value = v.selectedOptions[0].value;
    drawer.value = false;
}
</script>