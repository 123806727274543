import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from '@/assets/Js/router.js';
import '@/assets/Css/Index.css';
import '@/assets/Css/tailwind.css'
import i18n from '@/assets/Js/Language.js'
import Data from './assets/Js/Data.js';
import 'swiper/swiper-bundle.css';
import { Picker, Pagination, DatePicker, Overlay } from "vant";
import 'vant/lib/index.css';
import store from './store/store';
import config from '../config';

const app = createApp(App);
// 设置全局数据
app.config.globalProperties.$ImgUrlArr = Data.ImgUrls.HomePage_Swiper_ImgUrls;

app.use(i18n);
app.use(router);
app.use(ElementPlus);
app.use(Picker);
app.use(Pagination);
app.use(DatePicker);
app.use(Overlay);
app.use(store)

// 设置首页消息提现每次只获取8条数据
function GetHomeHtintData(){
    let Arr = [];
    for(var i = 0;i < 8;i ++){
        Arr.push({'UserName':"****213"+i,'VIP':'VIP2'})
    }
    return Arr;
}
await store.dispatch("GetSwiperImg");
// 客服跳转
function GoServe(){
    // window.location.href = config.SERVE_URL;
    console.log(1)
    return
}

app.config.globalProperties.$GetHomeHtintData = GetHomeHtintData();
app.config.globalProperties.$GoServe = GoServe;

// 转换千分制
function setMath(num){
    let numStr = num.toString();

    // 分割整数部分和小数部分
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? ',' + parts[1] : ',000';

    // 使用正则表达式匹配整数部分，每三位数字加一个逗号
    let regExp = /\B(?=(\d{3})+(?!\d))/g;
    integerPart = integerPart.replace(regExp, ',');
    // 返回格式化后的结果
    return integerPart + decimalPart;
}
app.config.globalProperties.$setMath = setMath;

app.mount('#app');