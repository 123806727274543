<template>
    <div class="AddBank left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0" style="position: fixed;z-index: 9999;">
            <div class="basis-1/12 place-content-center" @click="getGo()">
                <el-icon><ArrowLeftBold /></el-icon>
            </div>
            <div class="basis-11/12 flex items-center justify-center">{{ $t("SetTxPS_Top_Name") }}</div>
        </div>

        <div class="mt-[65px] px-3 text-lg ">
            <div class="text-2xl px-1 pb-5 border-b border-[#00000014]">
                {{ $t('SetTx_Tab_TextName') }}
            </div>
            <div class="rounded-md overflow-hidden p-4 mx-4 bg-white !mx-0 mt-5 py-5">
                <div class="mt-4 text-xs">{{ $t('SetTx_Tab_Text1') }}</div>

                <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                    <input type="password" v-model="OdlPassWord" :placeholder="$t('SetTx_Tab_P1')" style="width: 100%;outline:none">
                </div>

                <div class="mt-4 text-xs">{{ $t('SetTx_Tab_Text2') }}</div>

                <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                    <input type="password" v-model="NewPassWord1" :placeholder="$t('SetTx_Tab_P2')" style="width: 100%;outline:none">
                </div>

                <div class="mt-4 text-xs">{{ $t('SetTx_Tab_Text3') }}</div>

                <div class="p-3 mt-3 flex items-center justify-between cursor-pointer opacity-90 bg-white border-[1px] border-slate-200 bg-white rounded-md">
                    <input type="password" v-model="NewPassWord2" :placeholder="$t('SetTx_Tab_P3')" style="width: 100%;outline:none">
                </div>

                <div class="text-white py-3">
                    <div class="bg-theme p-3 text-center " @click="submit">{{ $t('SetTx_Btn') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();
const router = useRouter();

const OdlPassWord = ref('');
const NewPassWord1 = ref('');
const NewPassWord2 = ref('');
const store = useStore();

function getGo() {
    router.go(-1)
}

async function submit(){
    if(OdlPassWord.value == '' || OdlPassWord.value == null && NewPassWord1.value == '' || NewPassWord1.value == null && NewPassWord2.value == '' || NewPassWord2.value == null){
        ElMessage.error(t('SetTx_Msg1'))
    }else if(OdlPassWord.value.length < 6){
        ElMessage.error(t('SetTx_Msg2'))
    } else if (NewPassWord1.value.length < 6) {
        ElMessage.error(t('SetTx_Msg3'))
    } else if (NewPassWord2.value.length != NewPassWord1.value.length) {
        ElMessage.error(t('SetTx_Msg4'))
    }else{
        try{
            await store.dispatch('UpTxPassWord', { data: { old_withdrawal_password: OdlPassWord.value, new_withdrawal_password: NewPassWord1.value } })
      
            ElMessage({
                message: t('SetTx_Msg5'),
                type: 'success',
                duration: 2000,
            })

            router.push('/MyPage')
        } catch (e) {
            console.log(e)
        }

    }

}
</script>