<template>
    <div class="LoginIndex">
        <div class="text-[white] text-sm grow left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl flex flex-col relative justify-center w-full h-full" >
            <div class="absolute left-5 right-5 top-8 flex items-center justify-between">
                <div class="flex items-center" @click="drawer = true">
                    <el-icon><Switch /></el-icon>
                    <div class="underline underline-offset-2 cursor-pointer text-white ml-1">{{ $t('LoginIndex_CutLanuage') }}</div>
                </div>

                <div>
                    <img class="w-[30px] h-[30px]" src="@/assets/Img/service.png">
                </div>
            </div>

            <div style="display: flex;justify-content: center;position: relative;">
                <div style="width: 100%; height: 100px;position: absolute;top: 0;z-index: 9999;"></div>
                <img class="w-auto h-24 self-center" src="@/assets/Img/logo.png">
            </div>
           
            <div class="rounded-md overflow-hidden p-4 mx-4 bg-white pt-6 pb-12 mb-10 shadow-lg shadow-theme-red mt-4">
                <div class="text-lg text-[#666666] text-center">{{ $t('LoginIndex_Tab_Top') }}</div>

                <router-link to="/LoginPage">
                    <div class="bg-theme rounded-md mt-8 text-center py-4">{{ $t('LoginIndex_Btn1') }}</div>
                </router-link>
                
                <router-link to="/SigninPage">
                    <div class="text-black rounded-md mt-8 text-center py-4 border-[1px] border-[#dde3e9]">{{ $t('LoginIndex_Btn2') }}</div>
                </router-link>
                
            </div>

            <router-link to="/About">
                <div class="absolute bottom-10 w-full underline underline-offset-2 cursor-pointer text-center text-black">
                    {{ $t('LoginIndex_Tab_Btt') }}
                </div>
            </router-link>
            
            
        </div>
        <div>
            <el-drawer v-model="drawer" :with-header="false" direction="btt" class="text-black custom-drawer left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl">
                <div class="flex justify-between mb-4" @click="drawer = false">
                    <div class="basis-1/12"></div>
                    <div class="basis-10/12 text-center">{{ $t("LoginIndex_CutLanuage") }}</div>
                    <div class="basis-1/12 text-right"><el-icon><Close color="#c8c9cc"/></el-icon></div>
                </div>
                <div>
                    <div v-for="(data,index) in lanArr" :key="index" class="py-3">
                        <div class="flex items-center justify-between" @click="setLanguage(data.v)">
                            <div>
                                {{ data.name }}
                            </div>
                            <el-icon><ArrowRight /></el-icon>
                        </div>
                        <el-divider style="margin:10px 0 !important"/>
                    </div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script setup>
import { Switch,Close,ArrowRight } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { locale } = useI18n();

const drawer = ref(false);
const lanArr = ref([
    { "name": "اللغة العربية", "v":"ar-eg"},
    { "name": "English", "v":"en-us"},
    { "name": "中文简体", "v":"zh-cn"},
    { "name": "中文繁体", "v":"zh-tw"},
])

function setLanguage(v){
    store.commit('SetLanguage', v);
    locale.value = v;
    drawer.value = false
}
</script>