<template>
    <div style="position: relative;" class="grow left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/3"></div>
            <div class="basis-1/3 flex items-center justify-center">{{ $t("Tasks_Top_Name") }}</div>
            <div class="basis-1/3 flex items-center justify-end p-2">
                <router-link to="/MsgPage">
                    <img src="@/assets/Img/msg.svg" style="width: 20px; height: 20px;">
                </router-link>
            </div>
        </div>

        <div class="TasksVIP mx-4 left-0 bg-[white] truncate" style="margin-top: 70px;">
            <div class="Card" style="overflow: hidden;">
                <div class="text-white z-50 h-full"
                    style="padding-top: 2rem;padding-left: clamp(0.625rem, 0.625rem + 1.83vw, 3rem);">
                    <div class="leading-5 font-serif font-bold flex items-center tracking-widest">
                        <div class="pr-3 vip-img">
                            <img src="@/assets/Img/VIP.svg" class="w-[23px] h-[23px]">
                        </div>
                        <div class="vip-info">
                            {{ $store.getters.USER_DATA.member_level_name }}
                        </div>
                    </div>

                    <div class="info-order"
                        style="padding-top: clamp(1rem, 2rem + 2.67vw, 4rem);font-size: clamp(0.4rem, 0.4rem + 1.59vw, 2rem);">
                        {{ $t('Tasks_Card_Order') }} <span class="ml-1 text-theme-red">{{
                            $store.getters.USER_DATA.member_level_max_order_quantity }}</span>
                    </div>
                    <div class="infor-ration"
                        style="padding-top: clamp(0.625rem, 0.625rem + 1.83vw, 2rem);font-size: clamp(0.4rem, 0.4rem + 1.59vw, 2rem);">
                        {{ $t('Tasks_Card_Ration') }} <span class="ml-1 text-theme-red">{{
                            $store.getters.USER_DATA.number_direct_referrals }}%</span>
                    </div>
                </div>

                <div class="z-50 pl-16 text-[#aaaaaa]" style="font-size: clamp(0.3rem, 0.3rem + 1.59vw, 1.3rem)"
                    v-if="VIP_DATA">
                    <div class="flex pt-2 text-right vip-tilel5" :style="{color : VIPIndex == 'VIP 5' ? 'white':''}">
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 5).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 5).price }}</div>
                        </div>
                        <div class="pl-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 5'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 5'">
                        </div>
                    </div>

                    <div class="flex text-left pl-5 vip-tilel4" :style="{color : VIPIndex == 'VIP 4' ? 'white':''}">
                        <div class="pr-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 4'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 4'">
                        </div>
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 4).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 4).price }}</div>
                        </div>
                    </div>

                    <div class="flex pl-2 text-right vip-tilel3" :style="{color : VIPIndex == 'VIP 3' ? 'white':''}">
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 3).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 3).price }}</div>
                        </div>
                        <div class="pl-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 3'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 3'">
                        </div>
                    </div>

                    <div class="flex pl-5 text-left vip-tilel2" :style="{color : VIPIndex == 'VIP 2' ? 'white':''}">
                        <div class="pr-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 2'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 2'">
                        </div>
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 2).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 2).price }}</div>
                        </div>
                    </div>

                    <div class="flex pl-10 text-right vip-tilel1" :style="{color : VIPIndex == 'VIP 1' ? 'white':''}">
                        <div>
                            <div>{{ VIP_DATA.find((item) => item.id == 1).name }}</div>
                            <div>{{ VIP_DATA.find((item) => item.id == 1).price }}</div>
                        </div>
                        <div class="pl-2" style="padding-top:clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                            <img src="@/assets/Img/dot.svg" v-if="VIPIndex != 'VIP 1'">
                            <img src="@/assets/Img/dot2.svg" v-if="VIPIndex == 'VIP 1'">
                        </div>
                    </div>
                </div>

                <router-link to="/TasksIndex" style="height: 25px;">
                    <div style="padding-top: clamp(0.625rem, 0.625rem + 1.83vw, 2rem);">
                        <el-icon color="white" size="25">
                            <QuestionFilled />
                        </el-icon>
                    </div>
                </router-link>
            </div>

            <div class="px-6 pb-4 pt-8">
                <div class="flex justify-between">
                    <div>
                        <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.commission }}</div>
                        <div>{{ $t('Tasks_Gain_Money') }}</div>
                    </div>

                    <div class="text-right">
                        <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.frozen_amount }}</div>
                        <div>{{ $t('Tasks_Freeze_Moneny') }}</div>
                    </div>
                </div>

                <el-divider />

                <div class="flex justify-between">
                    <div>
                        <div>{{ $store.getters.USER_DATA.today_order_count }}/{{
                            $store.getters.USER_DATA.member_level_max_order_quantity }}</div>
                        <div>{{ $t('Tasks_Order') }}</div>
                    </div>

                    <div class="text-right">
                        <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.balance }}</div>
                        <div>{{ $t('Tasks_Money') }}</div>
                    </div>
                </div>

            </div>


            <div class="pb-8">
                <div class="bg-theme mx-auto p-3 flex place-content-center w-[350px] Home-Btn" @click="QianDan()">
                    <img src="@/assets/Img/Finger.svg" class="">
                    <span class="text-sm text-[white]">{{ $t('HonePage_Btn') }}</span>
                </div>
            </div>

        </div>

        <div class="rounded-md overflow-hidden p-4 mx-4 bg-white mt-4 !p-5 mb-20">
            <div class="text-base font-medium">{{ $t('Tasks_Top_Text1') }}</div>
            <div class="mt-4 text-xs flex flex-col gap-y-5">
                <p>1.{{ $t('Tasks_Text1') }}</p>
                <p>2.{{ $t('Tasks_Text2') }}</p>
                <p>3.{{ $t('Tasks_Text3') }}</p>
                <p>4.{{ $t('Tasks_Text4') }}</p>
                <p>5.{{ $t('Tasks_Text5') }}</p>
            </div>
            <div class="mt-5 text-base font-medium">{{ $t('Tasks_Top_Text2') }}</div>
            <p class="mt-4 text-xs leading-5">{{ $t('Tasks_Text6') }}</p>
        </div>

        <div
            class="flex fixed bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl z-[9999] bottom-0 justify-around">
            <router-link to="/">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn1') }}</div>
                    <div style="display: flex;justify-content: center;">
                    </div>
                </div>
            </router-link>

            <router-link to="/OrderPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn2') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/TasksPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn3') }}</div>
                    <div style="display: flex;justify-content: center;">
                        <img src="@/assets/Img/Finger.svg" style="height: 20px;width: 16px;">
                    </div>
                </div>
            </router-link>

            <router-link to="/ServerPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn4') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>

            <router-link to="/MyPage">
                <div>
                    <div>{{ $t('HomePage_Hao_Btn5') }}</div>
                    <div style="display: flex;justify-content: center;">

                    </div>
                </div>
            </router-link>
        </div>

        <!-- 抢单弹窗 -->
        <Transition name="Pop">
            <div v-show="PopFlag" class="fixed w-full md:max-w-3xl top-[20%] p-4 z-[2001]">
                <div class="bg-[#ffffff] rounded-md p-4">
                    <div class="flex justify-between">
                        <div>{{ $t('Tasks_Pop_TopName') }}</div>
                        <div @click="PopCloseFlag = true"><el-icon>
                                <Close />
                            </el-icon></div>
                    </div>
                    <div class="mt-3 flex">
                        <div class="w-[60px] h-[60px] pr-[10px]">
                            <img :src="OrderData.product_logo" class="w-full h-full">
                        </div>
                        <div>
                            {{ OrderData.product_title }}
                        </div>
                    </div>

                    <div class="mt-3 flex items-center justify-between">
                        <div>{{ $t('Tasks_Pop_title1') }}:</div>
                        <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ OrderData.price }}</div>
                    </div>

                    <div class="mt-3 flex items-center justify-between">
                        <div>{{ $t('Tasks_Pop_title2') }}:</div>
                        <div>{{ OrderData.quantity }}</div>
                    </div>

                    <div class="mt-3 flex items-center justify-between">
                        <div>{{ $t('Tasks_Pop_title3') }}:</div>
                        <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ OrderData.amount }}</div>
                    </div>

                    <div class="mt-3 flex items-center justify-between text-theme-red">
                        <div>{{ $t('Tasks_Pop_title4') }}:</div>
                        <div>{{ $store.getters.USER_DATA.currency_symbol }} {{ OrderData.total_commission }}</div>
                    </div>

                    <div class="mt-5 px-4 py-2 grow bg-[#f8f9fb] rounded-md" id="task-comment">
                        <div class="flex items-center">
                            <div class="text-[#0000004D] grow">{{ $t('Tasks_Pop_title5') }}</div>
                            <div role="radiogroup" class="van-rate" tabindex="0" aria-disabled="false"
                                aria-readonly="false">
                                <el-rate v-model="Score" :show-score="true" :allow-half="true"></el-rate>
                            </div>
                        </div>
                        <div class="text-[#0000004D] grow mb-2">{{ $t('Tasks_Pop_title6') }}</div>
                        <el-select v-model="SelectValue" size="large" class="w-11/12 md:max-w-3xl"
                            :fit-input-width="true">
                            <el-option :label="$t('Tasks_Pop_Op1')" :value="$t('Tasks_Pop_Op1')">{{ $t('Tasks_Pop_Op1')
                                }}</el-option>
                            <el-option :label="$t('Tasks_Pop_Op1')" :value="$t('Tasks_Pop_Op2')">{{ $t('Tasks_Pop_Op2')
                                }}</el-option>
                            <el-option :label="$t('Tasks_Pop_Op1')" :value="$t('Tasks_Pop_Op3')">{{ $t('Tasks_Pop_Op3')
                                }}</el-option>
                            <el-option :label="$t('Tasks_Pop_Op1')" :value="$t('Tasks_Pop_Op4')">{{ $t('Tasks_Pop_Op4')
                                }}</el-option>
                            <el-option :label="$t('Tasks_Pop_Op1')" :value="$t('Tasks_Pop_Op5')">{{ $t('Tasks_Pop_Op5')
                                }}</el-option>
                            <el-option :label="$t('Tasks_Pop_Op1')" :value="$t('Tasks_Pop_Op6')">{{ $t('Tasks_Pop_Op6')
                                }}</el-option>
                            <el-option :label="$t('Tasks_Pop_Op1')" :value="$t('Tasks_Pop_Op7')">{{ $t('Tasks_Pop_Op7')
                                }}</el-option>
                        </el-select>
                    </div>

                    <div class="rounded-[40px] mt-5 p-3 bg-theme" @click="submit()">
                        <div class="text-center text-white">{{ $t('Tasks_Pop_Btn') }}</div>
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 抢单遮挡窗 -->
        <Transition name="Pop">
            <div v-show="PopFlag" class="overlay">

            </div>
        </Transition>

        <!-- 抢单取消弹窗 -->
        <Transition name="Pop">
            <div v-show="PopCloseFlag" class="fixed w-full md:max-w-3xl z-[2003] top-[30%] p-6 text-xs text-center">
                <div class="bg-[#ffffff] rounded-md overflow-hidden p-4 mx-4 bg-white py-8">
                    <div class="text-base font-medium">{{ $t('Tasks_Pop_Colse_Text1') }}</div>
                    <div class="my-6">{{ $t('Tasks_Pop_Colse_Text2') }}</div>
                    <div class="flex justify-center gap-x-4">
                        <div class="border-[1px] rounded-[20px] px-6 py-1 border-[#ff5d02] text-[#ff5d02]"
                            @click="getPopClose(1)">{{ $t('Tasks_Pop_Colse_Btn1') }}</div>
                        <div class="border-[1px] rounded-[20px] px-6 py-1 bg-[#ff5d02] text-[#ffffff]"
                            @click="getPopClose(2)">{{ $t('Tasks_Pop_Colse_Btn2') }}</div>
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 抢单取消遮挡窗 -->
        <Transition name="Pop">
            <div v-show="PopCloseFlag" class="overlay2">

            </div>
        </Transition>

    </div>

</template>

<script setup>
import { computed, handleError, onMounted, ref } from 'vue';
import { QuestionFilled,Close } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const PopFlag = ref(false)  //弹窗状态
let isProcessing = false;
const Score = ref();
const SelectValue = ref('');
const PopCloseFlag = ref(false);//弹窗取消状态
const store = useStore();
const OrderData = ref('');
const VIP_DATA = computed(() => store.getters.VIP_DATA)
const VIPIndex = computed(() => store.getters.USER_DATA.member_level_name)

onMounted(async() => {
    await store.dispatch('GetVipData')
})

// 关闭弹窗的
function getPopClose(i){
    if(i == 1){
        PopCloseFlag.value = false;
    }else{
        PopCloseFlag.value = false;
        PopFlag.value = false;
    }
}

const { t } = useI18n();

// 抢单
async function QianDan(){
    OrderData.value = await store.dispatch('GetOrderData')
    ElMessage({
        message: t('TasksMsg_Pop'),
        type: 'success',
        plain: true,
    })
    PopFlag.value = true;
}

// 提交订单
async function submit() {

    store.dispatch('SubmitOrder', { data: { "order_id": OrderData.value.id, "rating": Score.value, "review": SelectValue.value } })

    ElMessage({
        message: t('TasksMsg_Sub'),
        type: 'success',
        plain: true,
    })

    PopFlag.value = false
}
</script>