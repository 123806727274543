<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("SelTxWay_Top_Name") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
            </div>
        </div>

        <div
            style="background: white;margin: 15px;margin-top: 70px;padding: 15px;box-sizing: border-box;border-radius: 15px;min-height: 30vh;">
            <div style="background: #f8f9fb;padding: 15px;border-radius: 15px;font-size: 14px;font-weight: bold;margin-bottom: 15px;
                    display: flex;justify-content: space-between;align-items: center;
                " @click="GoTx(1)" v-if="WitTypeArr.bank_status_id == 1">
                <div style="display: flex;align-items: center;">
                    <span><img src="@/assets/Img/钱袋.png"></span>
                    <span style="padding-left: 8px;">{{ $t('SelTxWay_Sel1') }}</span>
                </div>
                <el-icon>
                    <ArrowRightBold />
                </el-icon>
            </div>

            <div style="background: #f8f9fb;padding: 15px;border-radius: 15px;font-size: 14px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;"
                @click="GoTx(2)" v-if="WitTypeArr.crypto_status_id == 1">
                <div style="display: flex;align-items: center;">
                    <span><img src="@/assets/Img/数字货币.png"></span>
                    <span style="padding-left: 8px;">{{ $t('SelTxWay_Sel2') }}</span>
                </div>
                <el-icon>
                    <ArrowRightBold />
                </el-icon>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const ArrData = ref({});
const store = useStore();
const WitTypeArr = ref({})

onMounted(async() => {
    store.commit("SetLoadingFlag",true)
    WitTypeArr.value = await store.dispatch("WitType")
})

function getGo(){
    router.push('/Account')
}

function GoTx(i) {
    if (store.getters.USER_DATA.withdrawal_password_status == 0) {
        router.push('/SetTkPassWrod')
        return
    }

    if(i == 1){ 
        router.push('/TxPage')
    }else{
        router.push('/TxSGX')
    }
}
</script>
