<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("About_Top_Name") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="mt-[65px] bg-white m-3 p-5" v-for="(item, index) in IntroData" :key="index">
            <div class="text-base font-semibold">{{ item.title }}</div>

            <el-divider />

            <div class="text-sm text-[#00000099] break-all" v-html="decodeHtml(item.content)">
                
            </div>
        </div>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();
const IntroData = computed(() => {return store.getters.GetIntroData })

function getGo() {
    router.go(-1)
}

onMounted(async() => {
    await store.dispatch('GetFirmData',{ data:'公司' })
})

function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
</script>