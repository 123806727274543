<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div class="basis-3/5 flex items-center justify-center">{{ $t("MyAccount_Top_Name") }}</div>
            <div class="basis-1/5 flex items-center justify-end p-2">
            </div>
        </div>

        <div class="p-4 mt-[65px] text-[#666666] text-sm">
            <div class="rounded-md overflow-hidden bg-white p-5">
                <div class="flex justify-between items-center">
                    <div>
                        <div class="text-2xl font-medium">{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.balance }}</div>
                        <div class="text-theme-gray-light">{{ $t('MyAccount_Text1') }}</div>
                    </div>

                    <div class="rounded-[10px] bg-[#fbeadc] text-[#ff5d02] px-3 py-1" @click="GoTx">{{
                        $t('MyAccount_Tx') }}</div>

                </div>
                <el-divider style="margin: 16px 0 16px 0 !important;" />

                <div class="flex justify-between">
                    <div>
                        <div class="text-black">{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.commission }}</div>
                        <div>{{ $t('MyAccount_Task') }}</div>
                    </div>

                    <div>
                        <div class="text-black">{{ $store.getters.USER_DATA.currency_symbol }} {{ $store.getters.USER_DATA.member_group_earnings }}</div>
                        <div>{{ $t('MyAccount_Team') }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="px-4 text-[14px]">
            <div class="bg-white px-5 py-6 mb-4 rounded-md overflow-hidden flex justify-between">
                <div class="flex items-center grow">
                    <div><img src="@/assets/Img/file.svg"></div>
                    <div class="ml-2">{{ $t('MyAccount_Task_Money') }}</div>
                </div>
                <router-link to="/TasksPage">
                    <div class="w-16 h-10 text-center text-[12px] border-[1px] rounded-[20px]"
                        style="display: flex;align-items: center;justify-content: center;">
                        {{ $t('MyAccount_Btn1') }}
                    </div>
                </router-link>

            </div>

            <div class="bg-white px-5 py-6 mb-4 rounded-md overflow-hidden flex justify-between">
                <div class="flex items-center grow">
                    <div><img src="@/assets/Img/gift.svg"></div>
                    <div class="ml-2">{{ $t('MyAccount_Com') }}</div>
                </div>
                <div class="w-16 h-10 text-center text-[12px] border-[1px] rounded-[20px]" @click="RowPopFlag = true"
                    style="display: flex;align-items: center;justify-content: center;">
                    {{ $t('MyAccount_Btn2') }}
                </div>
            </div>

            <div class="bg-white px-5 py-6 mb-4 rounded-md overflow-hidden">
                <div class="text-base font-semibold">
                    {{ $t('MyAccount_FAQ') }}
                </div>
            </div>
        </div>

        <Transition name="Pop">
            <div v-show="RowPopFlag" class="rounded-md fixed w-full md:w-[46rem] top-[15%] z-[2024] p-3">
                <div class="bg-white p-4 text-black flex flex-col rounded-[20px]" style="position: relative;">

                    <div style="text-align: right;" @click="RowPopFlag = false">
                        <el-icon>
                            <Close color="#c8c9cc" />
                        </el-icon>
                    </div>

                    <div class="text-[#FF5D02] text-xl font-bold tracking-wider">
                        {{ $t('HomePage_Pop_Top1') }}
                    </div>

                    <div class="text-2xl">
                        {{ $t('HomePage_Pop_Top2') }}
                    </div>

                    <div class="my-5 w-2/3 mx-auto">
                        <img src="@/assets/Img/share_hand.png" alt="">
                    </div>

                    <div class="text-sm text-theme-gray-light py-4 text-center text-[#666666]">
                        {{ $t('HomePage_Pop_Top3') }}:{{ Coding }}
                    </div>

                    <div class="bg-theme p-3 rounded-[50px]" @click="Copy">
                        <div class="text-white text-center">{{ $t('HomePage_Pop_Btn') }}</div>
                    </div>

                    <div style="position: absolute;top: 0;left: 0;">
                        <img src="@/assets/Img/bg_top.svg">
                    </div>
                </div>
            </div>
        </Transition>

        <!-- 遮挡窗 -->
        <Transition name="Pop">
            <div v-show="RowPopFlag" class="overlay2">

            </div>
        </Transition>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { Close } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const router = useRouter();
const RowPopFlag = ref(false);
const { t } = useI18n();
const store = useStore();
const Coding = computed(() => store.getters.USER_DATA.invitation_code);

function getGo() {
    router.push('/MyPage')
}

function Copy(){
    navigator.clipboard.writeText(Coding.value)
    // console.log(t)
    ElMessage({
        message: t('MyAccount_Msg1'),
        type: 'success',
        plain: true,
    })
}

function GoTx(){
    router.push("/SelTxWay")
}
</script>