import Server from "@/Axios";

// 登录验证
async function LoginFalg(AreaCode, UserName, PassWord) {
    return await Server.post("/login", {
        phone_code: AreaCode,
        phone: UserName,
        password: PassWord,
    })
  
}

// 注册
async function RegUser(data){
    return await Server.post("/register", data);
}

// 获取轮播图图片
async function GetSwiperImg(){
  return await Server.get("/product");
}

// 退出登录
async function OutLogin(){
    await Server.post("/logout");
}

// 获取用户数据
async function GetUserData(){
    return await Server.get("/my-account/profile");
}

// 修改登录密码
async function SetPassWord(data) {
    await Server.put("/my-account/password", data).catch((err) => {
    });
}

// 设置提现密码
async function SetTxPassWord(data) {
    await Server.put("/my-account/profile", data);
}

// 修改提现密码
async function UpTxPassWord(data) {
    await Server.put("/my-account/withdrawal-password", data);
}

// 获取VIP等级
async function GetVipData() {
    return await Server.get("/my-account/member-level");
}

// // 获取用户公告
// async function GetUserMsgData() {
//     return await Server.get("/my-account/notification");
// }

// 更改用户名
async function UpUserName(data) {
  await Server.put("/my-account/profile", data);
}

// 获取用户账单
async function GetiUserBill(data) {
    if(data.ScreenDate == 1){
        return await Server.get("/my-account/ledger?page="+data.CurvePage+'&limit=20');
    }else if(data.ScreenDate != 1){
        return await Server.get(
          "/my-account/ledger?page=" + data.CurvePage + "&limit=20&ScreenDate="+data.ScreenDate
        );
    }
}

// 获取用户银行卡
async function GetUserBank_Card(){
    return await Server.get("/my-account/bank-card");
}

// 用户银行卡提现
async function UserTx(data){
    await Server.post("/my-account/withdraw",data);
}

// 用户数字货币提现
async function UserTxNumber(data) {
    await Server.post("/my-account/withdraw-crypto",data);
}

// 获取所有银行
async function GetAllUser(){
    return await Server.get("/my-account/bank");
}

// 添加银行卡
async function AddBank_Card(data) {
    await Server.post("/my-account/bank-card", data);
}

// 充值
// async function Recharge(data){
//     await Server.
// }

// 提现方式
async function WitType() {
    return await Server.get("/my-account/withdraw-type");
}

// 充值
async function CzMoney(data) {
    await Server.post("/my-account/topup",data);
}

// 获取 公司 简介
async function GetFirmData(data) {
    try{
        return (await Server.get("/text/"+data)).data.data
    }catch(e){
        console.log(e)
    }
}

export default {
  LoginFalg,
  RegUser,
  GetSwiperImg,
  OutLogin,
  GetUserData,
  SetPassWord,
  SetTxPassWord,
  UpTxPassWord,
  GetVipData,
  //   GetUserMsgData,
  UpUserName,
  GetiUserBill,
  GetUserBank_Card,
  UserTx,
  GetAllUser,
  AddBank_Card,
  WitType,
  UserTxNumber,
  CzMoney,
  GetFirmData,
};