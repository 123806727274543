import { createRouter,createWebHashHistory } from "vue-router";
import HomePage from '@/components/HomePage.vue';
import TasksPage from '@/components/TasksPage.vue';
import TasksIndex from "@/components/Tasks/indexPage.vue";
import Service from "@/components/ServicePage.vue";
import FAQ from "@/components/Home/FAQ.vue";
import Terms from "@/components/Home/TermsPage.vue";
import About from "@/components/Home/AboutPage.vue";
import Order from "@/components/OrderPage.vue";
import MyPage from "@/components/MyPage.vue";
import MyAccount from '@/components/My/MyAccount.vue';
import SetTkPassWrod from '@/components/My/SetTkPassWord.vue';
import TxPage from '@/components/My/TxPage.vue';
import AddBank from '@/components/My/AddBank.vue';
import Bill from '@/components/My/BillPage.vue';
import TeamPage from '@/components/My/TeamPage';
import MsgPage from '@/components/MsgPage.vue';
import SetPage from '@/components/My/SetPage.vue';
import SetLoginPassWrod from "@/components/My/SetLoginPassWrod.vue";
import SetTxPassWrod from '@/components/My/SetTxPassWrod.vue';
import Loginindex from '@/components/Login/indexPage.vue';
import LoginPage from '@/components/Login/LoginPage.vue';
import SigninPage from '@/components/Login/SigninPage.vue';
import store from "@/store/store.js";
import SelTxWay from "@/components/My/SelTxWay.vue";
import TxSGX from "@/components/My/TxSGX.vue";
import CzPage from "@/components/My/CzPage.vue";

const router = createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path:'/',
            component:HomePage,
            meta: { keepAlive: true } // 设置需要缓存
        },
        {
            path:'/TasksPage',
            component:TasksPage
        },
        {
            path:'/TasksIndex',
            component:TasksIndex
        },
        {
            path:'/ServerPage',
            component:Service
        },
        {
            path:'/FAQ',
            component:FAQ
        },
        {
            path:'/Terms',
            component:Terms
        },
        {
            path:'/About',
            component:About
        },
        {   
            path:"/OrderPage",
            component:Order
        },
        {
            path:"/MyPage",
            component:MyPage
        },
        {
            path:"/Account",
            component:MyAccount
        },
        {
            path:"/SetTkPassWrod",
            component:SetTkPassWrod
        },
        {
            path:"/TxPage",
            component:TxPage
        },
        {
            path:"/AddBank",
            component:AddBank
        },
        {
            path:"/Bill",
            component:Bill
        },
        {
            path:"/TeamPage",
            component:TeamPage
        },
        {
            path:"/MsgPage",
            component:MsgPage
        },
        {
            path:"/SetPage",
            component:SetPage
        },
        {
            path:"/SetLoginPassWrod",
            component:SetLoginPassWrod
        },
        {
            path:"/SetTxPassWrod",
            component:SetTxPassWrod
        },
        {
            path:"/Loginindex",
            component:Loginindex
        },
        {
            path:"/LoginPage",
            component:LoginPage
        },
        {
            path:"/SigninPage",
            component:SigninPage
        },
        {
            path:"/SelTxWay",
            component: SelTxWay
        },
        {
            path:"/TxSGX",
            component:TxSGX
        },
        {
            path:"/CzPage",
            component: CzPage
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return { left: 0, top: 0 };
    }
})

router.beforeEach(async (to, from, next) => {
    
    try{
        const token = JSON.parse(localStorage.getItem("vuex")).UserStore.USER_TOKEN;
        if (token) {
            if (to.fullPath == '/LoginPage' || to.fullPath == '/Loginindex' || to.fullPath == '/SigninPage') {
                next('/');
            } else {
                localStorage.setItem('GetPage', to.fullPath);
                next();
            }
        } else {
            if (to.fullPath == '/LoginPage' || to.fullPath == '/Loginindex' || to.fullPath == '/SigninPage' || to.fullPath == '/About') {
                next();
            } else {
                localStorage.setItem('GetPage', to.fullPath);
                next('/Loginindex')
            }
        }
    }catch(e){
        if (to.fullPath == '/LoginPage' || to.fullPath == '/Loginindex' || to.fullPath == '/SigninPage' || to.fullPath == '/About') {
            next();
        } else {
            localStorage.setItem('GetPage', to.fullPath);
            next('/Loginindex')
        }
    }


})

export default router;