<template>
    <div class="left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl" style="position: relative;">
        <div class="flex bg-theme px-3 h-12 text-white text-lg left-0 md:left-[calc(50vw-24rem)] w-full md:max-w-3xl top-0"
            style="position: fixed;z-index: 9999;">
            <div class="basis-1/5 place-content-center" @click="getGo()" style="z-index: 1;">
                <el-icon>
                    <ArrowLeftBold />
                </el-icon>
            </div>
            <div
                style="position: absolute;width: 100%;height: 3rem;top: 0;left: 0;display: flex;align-items: center;justify-content: center;">
                {{ $t("FAQ_Top_Name") }}</div>
        </div>
        <div class="mt-[68px]"></div>
        <div class="mx-4 p-5 bg-white rounded-md" v-for="(item, index) in FAQData" :key="index">
            <div class="text-base font-semibold">{{ item.title }}</div>
            <div class="text-theme-gray-light" style="margin-top: 15px;" v-html="decodeHtml(item.content)"></div>
        </div>

        <div class="mt-4 mx-4 p-5 bg-white rounded-md">
            <div class="text-base font-semibold">{{ $t('FAQ_Bm_Text1') }}</div>
            <div class="mt-4 text-sm">{{ $t('FAQ_Bm_Text2') }}</div>
            <div class="mt-8 border-[1px] border-[#dcdee0] p-3 rounded-[5px]" @click="$GoServe">
                <div class="text-center">{{ $t('FAQ_Bm_Text3') }}</div>
            </div>
            <div class="mt-4 text-theme-gray-light text-center text-[#666697]">{{ $t('FAQ_Bm_Text4') }}</div>
            <div class="mt-4 text-theme-gray-light text-center text-[#666697]">{{ $t('FAQ_Bm_Text5') }}</div>
            <div class="mt-4 text-theme-gray-light text-center text-[#666697]">{{ $t('FAQ_Bm_Text6') }}</div>
        </div>
    </div>
</template>

<script setup>
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();
const FAQData = computed(() => { return store.getters.GetFAQData })

function getGo() {
    router.go(-1)
}

onMounted(async() => {
    await store.dispatch('GetFirmData', { data: '常见问题' })
})

function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
</script>