import axios from "axios";
import config from "../../config.js"; // 确保你正确导入了配置文件
import { ElMessage } from "element-plus";
import store from "@/store/store.js";
import router from "@/assets/Js/router.js";

const Server = axios.create({
  baseURL: config.BASE_URL + "/" + config.API_PATH + "/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
Server.interceptors.request.use(
  (config) => {

    const token = localStorage.getItem("token"); // 每次请求时动态获取 token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    config.headers["http-accept-language"] = localStorage.getItem("Language") || 'en-us';

    // 在请求中添加固定的参数
    if (config.method === "get") {
      // 对于 GET 请求，将参数添加到 URL
      config.params = {
        ...config.params,
      };
    } else if (config.method === "post") {
      // 对于 POST 请求，将参数添加到 data
      config.data = {
        ...config.data,
      };
    }
    
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

Server.interceptors.response.use(
  (res) => {
    store.commit("SetLoadingFlag", false);
    return res; // 返回成功响应
  },
  (error) => {
    if (!window.isHandlingTokenError) {
      window.isHandlingTokenError = true;

      if (error.response) {
        const { msg, code } = error.response.data;
        console.log(code);
        if (code == 403) {
          store.commit("UserStore_SETDATA_IS_NULL");
          store.commit("OrderStore_SETDATA_IS_NULL");
          localStorage.clear();
          ElMessage.error(msg);
          router.push("/Loginindex");
        } else {
          ElMessage.error(msg);
        }
      } else {
        const customMessage = "网络错误，请检查您的网络连接";
        ElMessage.error(customMessage);
      }

      setTimeout(() => {
        window.isHandlingTokenError = false;
      }, 3000); // 3秒后恢复状态
    }
    console.log("Axios error:", error); // 打印错误以便调试
    store.commit("SetLoadingFlag", false); // 无论成功或失败都清除加载状态

    // 返回一个解析的 Promise，这样调用方就不会收到错误
    return new Promise(() => {}); 
  }
);



export default Server;
