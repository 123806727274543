<template>
    <div v-loading="$store.getters.GetLoadingFlag" style="min-height: 100vh;">
        <router-view></router-view>
    </div>
</template>

<script>

</script>

<style>
body{
    margin: 0 !important;
    background: #f8f9fb;
}
</style>
